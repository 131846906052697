.step_opensea_credits_first_use {
  .layout--redirect,
  .layout--modal,
  .layout--embed {
    .modal-content {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3000;
      width: 100%;
      height: 100%;
      background: transparent;

      .backdrop {
        background-color: rgba($cow-black, 0.75);
        position: absolute;
        inset: 0;
      }

      .modal-wrapper {
        position: relative;
        width: em(328);
        height: em(516);
        margin: em(64) auto;
        background-color: $cow-white;
        box-shadow: 0 0 em(1) em(1) rgb(0 0 0 / 7%), 0 em(20) em(25) 0 rgb(0 0 0 / 15%);
        border-radius: em(4);
        color: $cow-translucent-dark-gray;

        .inner-wrapper {
          margin: 0;
          padding: 0;
        }

        .header {
          display: none;
        }

        .cross {
          position: absolute;
          width: em(16);
          height: em(16);
          top: em(-32);
          right: em(8);

          svg {
            path {
              fill: rgba($cow-white, 0.75);
            }
          }
        }
      }

      .group-generic {
        .icon-wrapper {
          margin: em(24) auto em(32) auto;
          padding: em(13);
          width: em(56);
          height: em(56);
          border-radius: em(9);
          box-shadow: 0 em(3) em(4) rgb(0 0 0 / 10%);

          &.icon-mercado-credito-full {
            width: em(200);
            margin: em(48) auto em(32) auto;
            padding: em(16) em(24);
          }
        }

        h1 {
          font-size: $cow-font-18;
          margin: auto;
          color: $cow-translucent-dark-gray;
          text-align: center;
          max-width: em(300, 18);
          font-weight: $cow-font-weight-semibold;
        }

        h2 {
          font-size: $cow-font-14;
          margin: em(24, 14) auto;
          color: $cow-translucent-medium-gray;
          text-align: center;
          max-width: em(250, 14);
          font-weight: $cow-font-weight-light;
        }

        .paypal-payment-conditions {
          background-color: $cow-white;
          text-align: center;
          padding: 0;
          margin: 0 auto;
          width: em(270);

          p {
            font-size: $cow-font-14;
            font-weight: $cow-font-weight-light;
            color: $cow-translucent-medium-gray;
          }

          .info-logo {
            display: none;
          }
        }

        .form {
          &.group_form {
            padding: 0 em(25);

            .andes-button {
              font-size: $cow-font-16;
              width: 100%;

              &--loud {
                margin-top: em(32);
              }
            }
          }
        }
      }
    }
  }
}

.step_opensea_credits_first_use {
  .layout--redirect .modal-content .modal-wrapper {
    height: auto;
    margin: em(64) auto;
    padding-bottom: em(24);
    max-width: calc(100% - 16px);

    #opensea_credits_first_use {
      h1 {
        margin-top: 32px;
      }
    }
  }
}

.layout--redirect,
.layout--modal,
.layout--embed {
  .backdrop {
    background-color: rgba($andes-black, 0.75);
    position: absolute;
    inset: 0;
  }
}

// This will be removed when the ENDGAME MLC experiment ends.
.step_card_form,
.step_review {
  .shield-endgame {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-wrapper {
      max-width: em(500);
      padding: em(32) em(48);
      z-index: 300;

      .inner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .header {
          background: url("../assets/images/mlc-debit-modal.svg") no-repeat;
          background-size: 75% auto;
          background-position-x: center;
          width: em(300);
          height: em(200);
          margin: 0;
          padding: 0;

          .cross {
            display: none;
          }
        }

        .group-flex {
          align-items: center;
          flex-direction: column;
          text-align: center;
          padding: 0;

          .title-h3 {
            font-weight: $cow-font-weight-semibold;
            font-size: $cow-font-24;
            color: $cow-translucent-dark-gray;
          }

          .title-h2 {
            font-weight: $font-weight-regular;
            margin-top: em(8);
          }

          .kyc-why-link {
            font-size: $cow-font-24;
            font-weight: $cow-font-weight-semibold;
            color: $cow-lighter-blue;
          }

          .andes-button.andes-button--loud {
            margin-top: em(24);
            margin-bottom: em(8);
          }
        }
      }
    }
  }
}

.step_card_form {
  .shield-endgame {
    .andes-button--link {
      margin-top: em(24);
      margin-bottom: em(8);
      background: $andes-accent-color;
      border-color: $andes-accent-color;
      color: $andes-bg-white;
      padding: 0 em(28);
    }
  }
}

.step_review {
  .shield-endgame {
    .andes-button--quiet {
      margin-top: em(24);
      margin-bottom: em(8);
      background: $andes-accent-color;
      border-color: $andes-accent-color;
      color: $cow-white;
      padding: 0 em(28);
    }
  }
}
  
.layout--modal {
  .modal-content > .modal-wrapper {
    padding: 64px;

    .header > .arrow {
      margin-right: 8px;
    }
  }
}