@at-root body.step_auth_callback {
  background: $cow-white;
  font-family: $cow-font-family-primary;
  font-weight: 400;
  text-align: center;
  color: $cow-translucent-medium-gray;

  .andes-progress-indicator-circular__label {
    text-align: center;
  }

  .layout {
    .layout-main {
      padding: 0;
    }
  }
}

.error-msg {
  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  width: em(480);
  height: em(288);
  margin-top: em(-144);

  /* set to a negative number 1/2 of your height */
  margin-left: em(-240);

  h2 {
    margin: em(14) 0;
    font-weight: bolder;
  }

  /* set to a negative number 1/2 of your width */
}

.navbar {
  background: $cow-white;
  height: em(60);
  position: relative;
  z-index: 9999;

  &::after {
    box-shadow: 0 em(2) em(4) 0 rgb(155 169 187 / 30%);
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: em(2);
    bottom: 0;
  }

  &__logo {
    background-image: url("https://http2.mlstatic.com/ui/navigation/3.4.2/mercadopago/logo__small.png");
    background-repeat: no-repeat;
    display: inline-block;
    height: em(35);
    width: em(50);
    overflow: hidden;
    text-indent: em(-999);
    top: em(12);
    left: em(20);
    z-index: 3;
    position: absolute;
  }
}

@media (min-device-pixel-ratio: 2),
(resolution >=192dpi),
(resolution >=2dppx) {
  .navbar {
    &__logo {
      background-image: url("https://http2.mlstatic.com/ui/navigation/3.4.2/mercadopago/logo__small@2x.png");
      background-size: em(50) em(35);
    }
  }
}