
 #group_payment_method > ul > li > .options-list__label {
  div > div:nth-child(2) > div {
    div.text_title {
      line-height: 20px;
    }

    div.text {
      line-height: 20px;
    }
  }

  div > div:nth-child(3) > .text {
    color: $andes-accent-color;
    font-weight: $cow-font-weight-semibold;
  }
}

#group_installments {
  flex-grow: 1;
}

.group-row {
  margin-bottom: em(1);
  border-radius: em(4) em(4) 0 0;

  & + .group-row {
    border-radius: 0 0 em(4) em(4);
  }
}

.group-summary__container {
  > .text {
    font-size: $cow-font-12;
    font-weight: $cow-font-weight-regular;
    font-style: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: center;
    color: $cow-translucent-medium-gray;
    margin-top: em(32);
  }

  .andes-button--loud {
    margin-top: em(24);
  }
}

.installment-detail {
  margin-bottom: em(8);

  &__content {
    color: $cow-text-color-secondary;
    font-size: em(14);

    &.large {
      font-weight: 300;
      color: $cow-translucent-medium-gray;
      font-size: em(24);
    }
  }
}

.text {
  &.gray {
    color: $cow-text-color-secondary;
    font-size: em(12);
    margin-bottom: em(12, 12);
  }
}

.icon-mercado-credito {
  svg {
    max-width: 40px;
    max-height: 40px;
  }

  &:has(.icon-credits-yellow) {
    border: none;
  }
}

.group-generic-block {
  box-shadow: none;

  form:last-child .ui-card-wrapper .ui-card {
    box-shadow: 0 0.07em 0.125em 0 rgb(0 0 0 / 15%);
  }
}

.andes-message {
  margin-bottom: em(16);
}

#installments_row {
  margin-bottom: em(16);
}

#credits_aft_details > p > span > a {
  color: $andes-accent-color;
}

.text_promo {
  color: $cow-color-success;
  display: inline-block;
  font-size: 14px;
}

#group_pay {
  .ui-card.split-amount.disabled {
    + #installments_row.ui-card-wrapper {
      .ui-card {
        border-radius: 0 0 em(6) em(6);
      }
    }

    ~ p.text.gray {
      margin-bottom: em(16);
    }
  }
}

/** Styles for consumer credits combination */

#title_combination {
  font-weight: $cow-font-weight-regular;
  color: $andes-gray-550;
}

#title_2_combination {
  color: $cow-translucent-dark-gray;
  margin-bottom: 16px;
}

.payment-method-info {
  border-top-left-radius: em(6);
  border-top-right-radius: em(6);

  .description {
    margin-bottom: 0;
    padding: em(32) em(24) 0 em(24);
  }
}

.payment-method-info + #installments_row {
  .ui-card {
    padding: em(24);
    border-top-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    #amount_text_credits {
      margin-left: 0;
      .text {
        margin: em(22) 0 0;
      }
    }
  }
  & + .text.gray {
    margin-bottom: em(22);
  }
}
