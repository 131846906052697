@import "shared";

.ui-card-wrapper {
  padding: em(45) 0 em(34) 0;
}

.ui-card-wrapper .ui-document {
  margin: 0 auto;
}

.c-title {
  margin-bottom: em(16);
}

.ui-card {
  overflow: visible;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-top: 0;
  margin-bottom: 1.5em;

  .identification-card {
    &__list {
      li {
        &:first-child {
          span {
            color: gray;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.andes-dropdown--form {
  margin-right: 16px;
  width: em(96);
}