@import "shared";

.payment-option-desktop-screen {
  &__content-card-list {
    #pay-with-cash{
      margin-bottom: 16px;
    }
  }
  &__content {
    padding-right: 32px;
    padding-bottom: 0;

    &:has(.scroller) {
      padding-left: 32px;
    }

    .scroller {
      height: 365px;
    }
  }

  .layout__col-sidebar {
    margin-top: em(60);
  }

  .login-section__title {
    display: flex;
    align-items: center;
    gap: em(8);

    .badge-list-item {
      display: flex;
      height: fit-content;

      .andes-badge__content {
        display: flex;
        align-items: center;
        padding: em(2, 11) em(6, 11);
        gap: em(4, 11);

        svg {
          width: em(12, 11);
          height: em(12, 11);
        }
      }
    }
  }
  .layout__col-sidebar {
    .group-total-summary {
      .title-h2 {
        font-size: $cow-font-16;
        font-weight: $cow-font-weight-semibold;
      }
    }
  }
}

.payment-option-desktop-screen--guest,
.payment-option-desktop-screen--not-navbar {

  .payment-option-desktop-screen__content,
  .layout__col-sidebar {
    margin-top: 0;
  }

  .scroller {
    height: 450px;
  }
}

.layout--modal {
  .scroller {
    height: 370px;
  }

  #group_sidebar {
    height: 100%;
  }
}
