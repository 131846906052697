.congrats--approved {
  .group-congrats-summary  {
    display: flex;
    flex-direction: row;

    .group-generic {
      margin: 0 0 0 em(24);
      flex-direction: column;

      .row-summary {
        &__discount_price {
          color: rgb(91 165 77);
        }
      }

      .row-summary:last-of-type {
        font-weight: normal;
      }
    }
  }
}
