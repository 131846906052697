.installments-list {
  height: 100%;
  display: flex;
  align-items: center;

  &__installment {
    width: em(50, 20);
    flex-grow: 0;
    flex-shrink: 0;
    font-size: em(20);
    font-weight: 600;
    color: $cow-gray-800;
  }

  &__amount {
    flex-shrink: 0;
    font-size: em(20);
  }

  &__total-amount {
    flex-grow: 1;
    text-align: right;
    padding-left: em(6);

    p {
      font-size: em(14);
    }

    .promo {
      color: $cow-color-success;
    }
  }
}
