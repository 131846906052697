.instruction {
  &-steps {
    &__item {
      display: flex;
      margin: em(8) 0;

      &-counter {
        min-width: em(22);
        min-height: em(22);
        width: em(22);
        height: em(22);
        margin-right: em(16);
        border: solid em(1) $andes-accent-color;
        border-radius: 50%;
        color: $andes-accent-color;
        text-align: center;
      }

      &-label {
        text-align: left;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $cow-translucent-dark-gray;
      }
    }
  }
}

.instruction-modal {
  max-width: 100%;
  height: auto;
  padding: em(24) em(24);
  margin: em(8);

  .instruction-action {
    position: unset;
  }
}
