.processing-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__container {
    max-width: em(510);
  }

  &__logos {
    display: flex;
    justify-content: center;
    margin: 0 auto em(30);
  }

  .icon__container {
    width: em(64);
    height: em(64);
    border-radius: 50%;
    border: solid em(1) $cow-translucent-lighter-gray;
    display: flex;
    justify-content: center;
  }

  .line {
    margin: 0 em(2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner__container {
    width: em(64);
    height: em(64);
    position: relative;
  }

  .title {
    font-weight: $cow-font-weight-bold;
    width: 80%;
    text-align: center;
    font-size: $cow-font-28;
    line-height: $cow-spacing-32;
  }

  &__content {
    display: flex;
    justify-content: center;
  }
}

.legacy-mp-theme {
  .processing-payment {
    .icon__bank {
      .with-custom-color {
        path {
          fill: $cow-light-blue;
        }
      }
    }
  }
}

.processing-payment {
  .icon__bank {
    .with-custom-color {
      path {
        fill: $cow-translucent-dark-gray;
      }
    }
  }
}
