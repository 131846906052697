#change_payment_form > ul > li > .options-list__label {
  div > div:nth-child(2) > div {
    div.text_title {
      line-height: 20px;
    }

    div.text {
      line-height: 20px;
    }
  }

  div > div:nth-child(3) > .text {
    color: $andes-accent-color;
    font-weight: $cow-font-weight-semibold;
  }
}

#installments_group_generic {
  div.icon-wrapper > div.ui-avatar > div.ui-avatar__content > span {
    color: $andes-accent-color;
  }
}

#group_installments {
  flex-grow: 1;
}

.layout--modal {
  #shield_footer {
    bottom: em(-6);
    position: absolute;
    width: 100%;

    .text {
      span {
        font-size: em(12);
      }
    }
  }

  .andes-button.andes-button--loud {
    bottom: em(10, 14);
  }

  .text.centered {
    bottom: 0;
  }
}

@include modal-with-button-back;

.group-total-summary .group-summary > .group-summary__container .andes-button.andes-button--loud span::before {
  display: none;
}

.group-row {
  margin-bottom: em(1);
  border-radius: em(4) em(4) 0 0;

  & + .group-row {
    border-radius: 0 0 em(4) em(4);
  }
}

.group-total-summary + .c-title {
  margin: em(24) 0 em(16) 0;
  font-size: em(18);
  font-weight: bold;
}

.andes-button + .text.gray {
  text-align: center;
}

// cvv
.row_cvv {
  align-items: center;
  display: inline-flex;
  flex-direction: row-reverse;
  width: 100%;

  .icon-wrapper {
    margin-right: em(16);

    &.icon-card-cvv--virtual {
      .icon-wrapper {
        margin-right: 0;
      }
    }
  }
}

.group-row {
  & + p.text {
    font-size: em(16);
    font-weight: 600;
    margin: em(24) 0 em(8) 0;
    color: $cow-translucent-dark-gray;

    & + .group-row {
      margin-bottom: 0;
      border-radius: em(4) em(4) 0 0;
      border-bottom: em(1) solid $cow-translucent-lighter-gray;

      & + .group-row {
        border-radius: 0 0 em(4) em(4);

        .text_title {
          display: inline-block;

          & + .text_promo {
            margin-left: em(8);
          }
        }
      }
    }
  }
}

.group-row {
  border-radius: em(4);

  .icon-mercado-credito svg {
    max-width: em(40);
    max-height: em(40);
  }
}

.group-generic-block + .group-row {
  margin-top: em(16);
  background-color: $andes-bg-secondary;
  border-radius: em(6);
  min-height: em(95);

  .title {
    font-size: em(14);
    font-weight: $cow-font-weight-semibold;
    line-height: 1.5;
  }

  .text {
    color: $cow-translucent-dark-gray;
    line-height: 1.25;
  }

  .row-details {
    margin-bottom: em(8);
  }

  .andes-button {
    &--link {
      height: 100%;
    }

    &__content {
      line-height: 18px;
      font-size: $cow-font-14;
    }
  }

  #dismiss_consumer_credits {
    align-self: flex-start;

    .andes-button::before {
      content: "";
      display: block;
      width: em(20);
      height: em(20);
      background: url("../assets/images/close.svg") no-repeat;
    }
  }

  > .group-media-object {
    border: none;
  }
}
// related to the ui-card rows in express
// introduced in https://github.com/melisource/fury_px-checkout-frontend/pull/1713
// removing border-radius due to visual error on current iteration (5.4.0)
div.optimus .ui-card-wrapper div.ui-card {
  margin-top: 0;
  border-top: solid em(0.1) $cow-lighter-gray;
}

div.group-payment-method-row-with-cvv .group-row {
  margin-bottom: 0;
  border-radius: em(6) em(6) 0 0;
}

div.ui-card,
div.optimus div.group-payment-method-row-with-cvv .group-row {
  padding: em(16);
}

#group_pay {
  .ui-card.split-amount.disabled {
    border-radius: em(6) em(6) 0 0;

    ~ .ui-card-wrapper {
      .ui-card {
        margin-bottom: 0;
        border-radius: 0;

        &.row_cvv {
          .icon-wrapper {
            margin-right: em(16);
          }

          .cow-secure-fields,
          .input-cvv {
            flex: 1;
            min-width: unset;
          }
        }

        #installments_group_generic {
          .select-installments {
            .andes-dropdown {
              margin-bottom: em(8);
            }
          }
        }
      }

      &:last-of-type {
        margin-bottom: em(16);

        .ui-card {
          border-radius: 0 0 em(6) em(6);
        }
      }
    }
  }
}

.step_express .row_cvv .icon-wrapper,
.row_cvv .icon-wrapper {
  margin-right: em(16);
  width: em(40);
  height: em(40);
  border-radius: 50%;
  border: solid em(0.1) $cow-gray-070;

  .payment-card-icon {
    zoom: 64%;
  }

  &.icon-wrapper {
    svg {
      max-width: em(32);
      max-height: em(24);
    }
  }
}

#group_switch_alternative_payment_method .ui-card {
  display: flex;
  align-items: center;
  padding: em(16) em(48) em(16) em(32);

  p.text {
    margin-left: em(24);
    font-weight: $cow-font-weight-regular;
    color: $cow-black;
  }

  .andes-checkbox {
    display: flex;
    align-items: center;

    input.andes-checkbox__input {
      position: relative;
      width: em(10);
      margin: em(4) 0 0;
    }

    span.andes-checkbox__mimic {
      position: relative;
    }
  }
}

.free-interest {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  padding: em(16) em(16);

  .andes-list__item-first-column {
    flex-grow: unset;
    padding: 0;
    margin-right: em(5);
    width: 100%;
  }

  .option-additional-info {
    padding: 0;

    .installment__interest {
      font-size: em(14);
    }
  }
}

#notice-processed-mp.text {
  margin-top: $cow-spacing-32;
  font-weight: $cow-font-weight-semibold;
  color: $cow-dark-gray;
}

#notice-processed-mp + p.text.gray {
  margin-top: $cow-spacing-32;
}

/** Styles for express combination */
#title_combination {
  font-weight: $cow-font-weight-regular;
  font-size: $cow-font-14;
  color: $andes-gray-550;
}

#title_2_combination {
  font-size: $cow-font-18;
  color: $cow-translucent-dark-gray;
}

#group_form_combination {
  .payment-method-info {
    border-top-left-radius: em(6);
    border-top-right-radius: em(6);

    .description {
      margin-bottom: 0;
    }
  }

  #installments_row {
    .ui-card {
      border-radius: 0;
      font-size: $cow-font-16;
      box-shadow: unset;
      border-color: transparent;
      overflow: visible;

      .icon-wrapper {
        display: none;
      }
    }
  }

  #cvv_row {
    .ui-card {
      border-radius: 0;
      box-shadow: unset;
      border-color: transparent;

      .row_cvv {
        flex-direction: row;

        .icon-wrapper.icon-card-cvv {
          display: none;
        }

        .andes-form-control__label {
          margin-bottom: em(6, 14);
        }
      }
    }
  }

  .payment-method-info + #cvv_row {
    flex-basis: 100%;

    .ui-card {
      border-bottom-left-radius: em(6);
    }
  }
}

.select-additional-info {
  .financial-cost-info {
    color: $cow-dark-gray;
    font-size: $cow-font-14;
    margin-left: em(6);
  }
}

#group_form_combination {
  &:not(:has(#cvv_row)) {
    &:not(:has(#installments_row)) {
      .payment-method-info {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .description {
          padding-bottom: 30px;
        }
      }
    }
  }
}

#credits_incentive_row {
  #group_payment {
    .row-details {
      margin-bottom: $cow-spacing-4;
    }

    .andes-button--link {
      font-weight: 400;
    }
  }
}

#use_consumer_credits button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: 400;
  height: 18px;

  &:hover {
    background-color: transparent;
  }
}

#dismiss_consumer_credits button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.group-back-url {
  margin-top: 24px;
}
