.smart-tokenization {
  &__wrapper {
    position: relative;
    top: -3px;

    .andes-typography--color-negative {
      color: rgba($andes-black, 0.9);
    }

    .andes-form-control__bottom {
      display: flex;
      align-items: center;
    }

    .secure-fields--field-input {
      margin: $cow-spacing-4 0;

      .andes-tooltip__trigger {
        margin-right: 0;
      }
    }

    .secure-fields--wrapper {
      min-height: auto;
    }

    .secure-input-container,
    .secure-input-container.has-error,
    .secure-input-container.is-focused {
      box-shadow: 0 0 0 1px rgba($andes-black, 0.25);
    }

    .secure-fields--field {
      > div[aria-live="assertive"] {
        display: none;
      }

      .andes-typography {
        margin: 0;
      }
    }
  }
}

.smart-tokenization__wrapper.andes-form-control--error {
  label {
    color: $cow-color-error;
  }
  .secure-input-container {
    box-shadow: 0 0 0 10px $cow-color-error;
  }
}
