.group-row {
  display: flex;
  min-height: em(84);
  align-items: center;
  margin-bottom: em(12);
  line-height: 1.2;
  background: $cow-lighter-gray;
  border-radius: em(4);

  .group-media-object {
    padding-right: em(12);
    flex-grow: 1;
  }

  .row-content-details {
    padding-right: em(8);
  }

  .button-step,
  .andes-button--link {
    flex-shrink: 0;
    font-size: em(14);
  }

  .icon-wrapper {
    width: em(40);
    height: em(40);
    margin-right: em(16);
    border-radius: 50%;
    border: solid 1px $cow-gray-070;

    svg {
      max-width: em(20);
      max-height: em(20);
    }

    &.icon-mercado-pago-black, &.icon-account-money-yellow {
      border: none;

      svg {
        max-width: unset;
        max-height: unset;
      }
    }

    img {
      max-width: 64%;
    }
  }

  .payment-card-icon {
    zoom: 64%;
  }
}

.group_row_selectable {
  border-radius: em(4);
  background: $cow-lighter-gray;

  .options-list {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    background: transparent;

    li.options-list__item {
      height: 100%;
      background: transparent;

      label {
        padding: 0;

        .group-row {
          background: transparent;
          border-radius: 0;
        }
      }
    }
  }
}
