.form_personal_info_pse {
  padding: $cow-spacing-16;

  .tooltip-demo-container svg path {
    fill: $andes-blue-500;
  }

  .form_personal_field {
    margin-bottom: $cow-spacing-32;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form_personal_double_field {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $cow-spacing-32;
  }

  .andes-form-control__label {
    display: flex;
    gap: 8px;
  }
}

.form-actions {
  margin-top: $cow-spacing-24;
  padding-bottom: $cow-spacing-48;
}
