.message_ao-po {
  margin-bottom: 32px;
}

.payment-option-desktop-screen {
  display: flex;

  .row-summary--discount {
    & :last-child {
      flex-grow: 0;
    }
  }

  &__content {
    margin-top: em(60);
    width: 100%;

    .payment-option-desktop-screen__content-title {
      margin: em(40, 20) 0 em(32, 20) 0;
    }
  }
}

.payment-option-desktop-screen,
.payment-options-mobile-screen {
  &__content {
    padding-bottom: em(40);
  }

  &__content-card-list {
    .andes-card {
      margin-bottom: em(16);
    }

    .andes-card:last-child {
      margin-bottom: 0px;
    }

    &:has(.cow-button-back-to-site) {
      .andes-card:last-of-type {
        margin-bottom: 24px;
      }
    }

    .login-section__title {
      margin-bottom: em(16);
    }

    .login-section__title:has(.andes-badge) {
      margin-bottom: em(0);
    }

    #combination-row {
      margin-bottom: em(32, 18);
    }

    .list-item {
      .andes-thumbnail {
        &:has(.icon_account_money_yellow, .icon_mercado_credito_yellow, .icon_mercado_pago_yellow) {
          border: none;
        }

        svg {
          color: $andes-accent-color;
        }
      }
    }

    .payment-method-logo__image--external_image {
      height: auto;
      width: 24px;
    }
  }

  &:has(.rebranding) {
    .andes-thumbnail {
      svg {
        color: $andes-text-color-primary;
      }
    }

    .andes-thumbnail.ticket {
      svg {
        path {
          fill: $andes-text-color-primary;
        }
      }
    }
  }
}

.payment-option-desktop-screen--guest,
.payment-options-mobile-screen--guest {
  .login-section__description {
    margin-top: em(4);
  }

  .guest-section__description {
    margin: em(4, 12) 0 em(16, 12);
    display: flex;
    align-items: center;
    gap: em(4, 12);
  }

  .guest-section__title {
    margin-top: em(32, 18);
    margin-bottom: em(16, 18);
  }

  &:has(.guest-section__description) {
    .andes-card {
      margin-top: em(16);
    }

    .guest-section__title {
      margin-bottom: 0;
    }
  }
}

.list-item {
  img {
    position: relative;
  }
}

.icon_mercado_pago_black {
  img {
    height: 100%;
    width: 100%;
  }
}

.icon_mercado_pago,
.icon_mercado_libre {
  img,
  svg {
    height: auto;
    width: 24px;
  }
}

.payment-method-logo {
  &.andes-thumbnail.ticket {
    svg {
      path {
        fill: $andes-accent-color;
      }
    }
  }
}

.cow-navbar-v2 {
  &__content {
    &__user_info_and_options {
      .cow-group-menu {
        .cow-group-menu__button {
          max-width: none !important;
        }
      }
    }
  }
}
