.icons-list {
  display: flex;
  flex-direction: column;
  margin: em(24) 0;
  gap: em(16);
}

.icons-list .item {
  display: flex;
}
