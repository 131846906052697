.icon-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  width: em(36);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    display: block;
    max-width: 100%;
    flex: 1; // IE Fix
  }

  > div {
    flex-shrink: 0;
  }
}
