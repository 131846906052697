@import "shared";

.card-number-with-issuer__wrapper {
  display: flex;

  .card-number-with-issuer__group {
    flex: 1;
    width: 0;
    display: flex;

    & > :nth-child(n) {
      flex: 1;
    }

    .icon-wrapper {
      padding-bottom: 1em;
      flex: none;
    }
  }

  .andes-dropdown,
  label {
    margin-right: em(12);
  }

  & > :nth-child(2) {
    margin-left: em(11);
  }

  & > :last-child {
    margin-right: em(-11);
  }
}
