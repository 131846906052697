.cow-card-number-icon-logo {
  margin-right: em(5);

  &.icon-card--pm {
    border: em(1) solid $cow-translucent-light-gray;
    border-radius: em(4);
    padding: em(4);
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: white;
    width: em(30);
    height: em(25);

    img {
      display: block;
      max-width: em(25);
      max-height: em(15);
      width: auto;
      height: auto;
    }
  }
}

@supports (-moz-appearance:meterbar) {
    .step_card_form .ui-card .card-form-group #card_number::placeholder {
        color: rgba(0, 0, 0, 0.3)
    }
}

