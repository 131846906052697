.layout-main .optimus {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.layout--redirect .optimus,
.layout--embed .optimus {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.group-media-object {
  max-width: em(300);
  display: block;
  height: auto;
  text-align: center;
  margin: auto;

  & > a {
    font-size: em(18);
  }

  .icon-wrapper {
    width: em(102);
    margin-bottom: em(24);
    margin: auto;
  }

  .c-title {
    margin-bottom: em(16, 20);
    font-size: em(20);
    font-weight: 600;
    line-height: 1.2;
  }

  .text {
    margin-bottom: em(16);
    font-size: em(16);
    color: $cow-gray-800;
  }
}
