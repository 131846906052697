@import "shared";

.modal-view {
  .navbar:not(nav) {
    .navbar__container {
      padding: 0 em(32);
    }
  }
}

.navbar {
  &:not(nav) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: em($navbar-height);
  }

  &__container {
    margin: 0 auto;
    padding: 0;
    max-width: em($layout-max-width); // same max-width as layout
  }
}

.navbar--ml {
  .navbar__logo {
    height: em(34);
    top: em(12);
    width: em(134);
    background-image: url("../assets/images/logo-ml-large@2x.png");
    background-size: em(134) em(34);
  }

  &.navbar--mlb {
    .navbar__logo {
      background-image: url("../assets/images/logo-ml-large-pt@2x.png");
    }
  }
}

.navbar--mp {
  .navbar__logo {
    height: em(34);
    top: em(12);
    width: em(134);
    background-image: url("../assets/images/logo-mp-large@2x.png");
    background-size: em(134) em(34);
  }
}
