@import "shared";

.layout:not(.layout--modal) .layout-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: em(350); // when screen is smaller than this it adds a scrollbar so the icon isn't lost outside the screen
  text-align: center;
}

.pref-expired,
.pref-unavailable {
  .icon-timeout {
    width: em(90);
    height: em(92);
  }

  .oops__message {
    font-size: em(20);
  }
}

.page-mask {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
