.group-generic-block {
  margin-bottom: 24px;
  box-shadow: 0 0.07em 0.125em 0 $cow-translucent-light-gray;
  border-radius: em(6);

  .andes-dropdown__arrow::after {
    border-right-color: $cow-lighter-blue;
    border-bottom-color: $cow-lighter-blue;
  }

  & > :first-child:not(:only-child) {
    &.group-row {
      border-radius: em(6) em(6) 0 0;
    }

    &.form .ui-card-wrapper .ui-card {
      border-radius: em(6) em(6) 0 0;
    }
  }

  & > :last-child:not(:only-child) {
    &.group-row {
      border-radius: 0 0 em(6) em(6);
    }

    &.form .ui-card-wrapper:last-child .ui-card {
      border-radius: 0 0 em(6) em(6);
    }
  }

  & > :only-child {
    &.group-row,
    ul.options-list {
      border-radius: em(6);
    }

    &.form .ui-card-wrapper {
      &:first-child:not(:only-child) .ui-card {
        border-radius: em(6) em(6) 0 0;
      }

      &:last-child:not(:only-child) .ui-card {
        border-radius: 0 0 em(6) em(6);
      }

      :only-child {
        border-radius: em(6);
      }
    }
  }

  .group-row {
    box-shadow: $cow-medium-gray 0 0 em(2) 0;
    padding: inherit;
    background-color: $cow-white;
    margin-bottom: em(1);
    border-radius: em(4) em(4) 0 0;

    & + .group-row {
      border-radius: 0 0 em(4) em(4);
    }
  }

  .form .ui-card-wrapper .ui-card {
    margin-top: 0;
    border-radius: 0;
  }

  .form .ui-card {
    border-radius: 0;
  }

  .row_cvv {
    display: inline-flex;
    flex-direction: row-reverse;

    .icon-wrapper {
      width: em(48);
      margin-right: em(16);
    }
  }

  .input-cvv {
    width: 100%;
  }

  .card-hint {
    display: none;
  }

  .andes-form-control {
    margin-bottom: em(8);
  }

  & ~ p {
    margin-bottom: em(16);
    text-align: center;
  }

  form:first-child:not(:only-child) ul.options-list {
    border-radius: em(6) em(6) 0 0;
    margin-bottom: 0;
  }

  form:last-child:not(:only-child) {
    ul.options-list {
      border-radius: 0 0 em(6) em(6);
      margin-top: 0;
    }

    .ui-card-wrapper .ui-card {
      border-top: none;
    }
  }

  form:not(:last-child, :first-child, :only-child) {
    ul.options-list {
      margin-bottom: 0;
      margin-top: 0;
      box-shadow: none;
    }

    .ui-card-wrapper .ui-card {
      border-top: none;
      box-shadow: none;
    }
  }

  form:not(:last-child) ul.options-list {
    border-bottom: 1px solid $cow-gray-070;
    box-shadow: none;
  }

  > form#group_pay {
    &:not(:last-child) {
      .ui-card.installments_row {
        border-bottom: 1px solid $cow-gray-070;
      }
    }

    &:last-child {
      > .options-list.ui-card + .ui-card-wrapper:not(#cvv_row) {
        border-top: 1px solid $cow-gray-070;
      }
    }

    .ui-card.installments_row {
      overflow: auto;
      min-height: unset;
      padding: 0;
    }

    .ui-card-wrapper:first-child:not(:only-child) {
      .ui-card {
        margin-bottom: 0;
        border-bottom: 1px solid $cow-gray-070;
      }
    }
  }

  form:first-child .options-list.ui-card {
    margin-top: 0;
  }

  #select_shipping_row,
  #select_local_pickup_row {
    .options-list .icon-wrapper svg {
      max-width: em(24);
      max-height: em(24);
    }
  }
}
