@import "shared";

.ui-card {
  padding: em(28) em(114);
}

.layout--modal .ui-card {
  height: em(385);
}

.group-media-object {
  height: auto;
}
