.select-installments {
  flex: 1;

  .select-additional-info {
    flex: 1 1 100%;
    font-size: em(14);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: $cow-translucent-dark-gray;
  }
}
