div.optimus div.group-total-summary {
  .row-summary,
  .row-summary--discount {
    position: relative;
    white-space: pre-line;
  }

  .row-summary__text-total-description {
    font-weight: 600;
    font-size: em(16);
    align-self: center;
  }

  .row-summary__text-total-amount {
    font-weight: 600;
    font-size: 16px;
  }

  .row-summary--discount {
    font-weight: 400;
    font-size: em(16);

    .row-summary__discount:first-of-type {
      flex: 1;
    }
  }

  .row-summary__text-total-amount,
  span.row-summary__text + span.row-summary__text,
  span.row-summary__discount + .question-mark + span.row-summary__discount,
  span.row-summary__discount + span.row-summary__discount {
    font-size: 16px;
    padding-left: em(10);
    white-space: nowrap;
    margin-right: 0;
  }

  span.row-summary__text + span.row-summary__text + span.row-summary__text,
  span.row-summary__discount + span.row-summary__discount + span.row-summary__discount,
  span.row-summary__discount + .question-mark + span.row-summary__discount + span.row-summary__discount,
  .row-summary__text-total-amount + .row-summary__text-total-amount {
    vertical-align: super;
    font-size: em(10);
    position: relative;
    right: 0;
    text-align: right;
    margin-right: 0;
    top: 0;
    padding-left: 0px;
    margin-left: 3px;
  }
}
