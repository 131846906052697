.mp-brand {
  display: block;

  &__text {
    display: inline-block;
    vertical-align: middle;
    margin-right: em(8, 10);
    font-size: em(10);
    font-weight: 600;
    line-height: 2.4;
    color: $cow-text-color-secondary
  }

  &__logo {
    display: inline-block;
    vertical-align: middle;
    width: em(72);
    height: em(20);
    background: url("../assets/images/logo-mp.png") no-repeat;

    @media (min-device-pixel-ratio: 2), (resolution >= 192dpi), (resolution >= 2dppx) {
      background: url("../assets/images/logo-mp@2x.png") no-repeat;
      background-size: 100% auto;
    }
  }
}
