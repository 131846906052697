#login-section {
  .ui-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: em(32);
  }

  #protected-purchase {
    display: flex;
    position: absolute;
    gap: em(4);
    right: 0;
    top: 0;
    align-items: center;
    flex-direction: row;
    background: $andes-green-100;
    padding: em(1.5) em(3);
    border-bottom-left-radius: em(6);

    .text {
      color: $cow-color-success;
      font-size: $cow-font-12;
      font-weight: 600;
    }
  }

  #logo-container .icon-wrapper {
    width: em(40);
  }

  .c-title {
    font-size: $cow-font-18;
    margin: em(8) 0 em(16);
    padding: 0 em(16);
    line-height: $cow-line-height-s;
  }

  #login-list {
    margin: 0;
    gap: em(10);
    font-size: $cow-font-14;
  }

  #login_button {
    font-size: $cow-font-14;
    margin: em(24) 0 0;
    height: em(40);
  }
}

.title-h2 {
  margin-bottom: em(4);
  margin-left: em(6);
}

#unprotected-purchase {
  align-items: center;
  gap: em(5);
  margin-left: em(8);
  margin-bottom: em(16);
  font-size: $cow-font-12;
}
