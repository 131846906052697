// TODO: css: borrar este reset cuando llegue a ui-library
fieldset {
  border: 0;
  padding: 0;
}

// Global
body {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: 400;
  line-height: $cow-line-height-base;
  background: $cow-gray-070;
}

input,
select {
  font-family: $font-family-primary;
}

p {
  font-size: 0.875em;
  color: $cow-dark-gray;
}

main {
  display: block; // For correct display cross-browser
}

iframe {
  display: none;
}

// Show Recaptcha Challenge (V2 & V3) in iframe
iframe[src*="recaptcha"] {
  display: block;
}

// Controls
// ----------------------------------------------
.andes-form-control {
  // FIX display block spacing problem at error messages
  display: flex;
  flex-direction: column;
}

// Cards
// ----------------------------------------------
.ui-card {
  padding: 16px;
  margin: 16px 0;
  background: $cow-white;
  overflow: hidden;
  border-radius: 0.25em;
  box-shadow: 0 0.07em 0.125em 0 rgba(0, 0, 0, 0.15);
}

.optimus .ui-card {
  background-color: $cow-white;
  border-radius: $cow-border-radius-6;
  box-shadow: $shadow-flat;

  > :first-child {
    margin-top: 0;
  }
}

.legacy-mp-theme {

  // Buttons
  // ----------------------------------------------
  .andes-button {
    border: none;
  }

  .andes-button {
    margin-bottom: em(8, 18);
  }

  // Override to andes@7.28.5 UI breaking changes
  // @TODO check possible padding error on buttons
  .andes-button.andes-button--link {
    padding: 0;
  }

  a.andes-button--loud:focus,
  a.andes-button--loud:focus {
    color: $cow-white;
  }

  // Switch
  // ----------------------------------------------
  .andes-switch .andes-checkbox__label {
    margin-left: 0;
  }

  a {
    color: $andes-accent-color;
    text-decoration: none;
  }

  a:hover,
  a:focus {
    color: $cow-blue-300;
  }

  a:focus {
    outline: 0;
  }

  a:active {
    color: $cow-blue-darker;
  }
}

// Captcha
// ----------------------------------------------
.grecaptcha-badge {
  visibility: hidden;
}

// fixme: TODO Used by screen readers, remove when update @andes/common package
.andes-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: em(1);
  margin: 0 em(-1) em(-1) 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: em(1);
  white-space: nowrap;
}

// FIX old andes library not displaying any image
[aria-hidden='false'] {
  position: static !important;
  clip: unset !important;
}

// Message
// ----------------------------------------------
.andes-message {
  .andes-message__close-button {
    top: 0;
  }
}

[data-testid="generic_question_mark"] {
  path {
    fill: $andes-accent-color;
  }
}

:not(.legacy-mp-theme) {
  .text {
     a {
      color: $andes-accent-color;
      text-decoration: none;
    }
  }
}