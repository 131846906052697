@import "shared";

.payment-method-info {
  .description {
    padding: 16px 32px;
  }

  .amount-details {
    padding: 14px 32px;
  }
}
