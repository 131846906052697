@import "shared";

.intruction {
  &-steps__item {
    margin: em(8) 0 em(16) 0;
  }

  &.andes-modal-dialog {
    max-width: em(422);
    max-height: em(348);

    .andes-modal-dialog__container {
      .andes-modal-dialog__header {
        padding-bottom: em(16);
      }
    }
  }

  &-action {
    margin-top: em(32);
  }
}

.intruction-steps__item + .group-generic#disclaimer_component {
  padding-top: em(8);
}
