.andes-form-control {
  flex-grow: 1;
  max-width: 100%;
}

.group-split-card-review {
  .group-row {
    margin-bottom: em(1);
    border-radius: em(4) em(4) 0 0;

    & + .group-row {
      border-radius: 0 0 em(4) em(4);
    }
  }
}

#group_list_bank_id .ui-card {
  overflow: visible;

  .andes-floating-menu > .andes-dropdown__trigger + [data-tippy-root] {
    transform: translate3d(0, em(54), 0) !important;
  }
}

#g-recaptcha {
  transform: scale(0.73);
  display: flex;
  justify-content: center;
}

#gctkn {
  display: none;
}

iframe {
  display: unset;
}

.group-total-summary + .c-title {
  margin: em(24) 0 em(16) 0;
  font-size: em(18);
  font-weight: bold;
}

.andes-button + .text.gray {
  text-align: center;
}

.group-total-summary .group-summary > .group-summary__container,
.group-total-summary ~ .form.group_form .sticky-container {
  .andes-button.andes-button--loud {
    span {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }
}

.group-row {
  background: $cow-white;

  & + p.text {
    font-size: em(16);
    font-weight: 600;
    margin: em(24) 0 em(8) 0;
    color: $cow-translucent-dark-gray;

    & + .group-row {
      margin-bottom: 0;
      border-radius: em(4) em(4) 0 0;
      border-bottom: em(1) solid $cow-translucent-lighter-gray;

      & + .group-row {
        border-radius: 0 0 em(4) em(4);

        .text_title {
          display: inline-block;

          & + .text_promo {
            margin-left: em(8);
          }
        }
      }
    }
  }
}

.group-row-banner-container {
  .row-banner {
    margin-bottom: em(12);
  }
}

.group-row {
  border-radius: em(4);

  .icon-mercado-credito {
    svg {
      max-width: em(40);
      max-height: em(40);
    }
  }
}

.group-generic-block + .group-row {
  margin-top: em(16);
  background-color: $andes-bg-secondary;
  border-radius: em(6);
  min-height: em(95);

  &:has(#credits_incentive_row) {
    margin-bottom: 24px;
  }

  .title {
    font-size: em(14);
    font-weight: $cow-font-weight-semibold;
    line-height: 1.5;
  }

  .text {
    color: $cow-translucent-dark-gray;
    line-height: 1.25;
  }

  .row-details {
    margin-bottom: $cow-spacing-4;
  }

  .andes-button {
    &--link {
      height: 100%;
    }

    &__content {
      line-height: 18px;
      font-size: $cow-font-14;
    }
  }

  #dismiss_consumer_credits {
    align-self: flex-start;

    .andes-button::before {
      content: "";
      display: block;
      width: em(20);
      height: em(20);
      background: url("../assets/images/close.svg") no-repeat;
    }
  }

  > .group-media-object {
    border: none;
  }
}
#group_generic > .andes-message {
  margin-top: 24px;
  margin-bottom: 24px;
}

#group_switch_alternative_payment_method .ui-card {
  display: flex;
  align-items: center;
  padding: em(16) em(48) em(16) em(32);

  p.text {
    margin-left: em(5);
    font-weight: $cow-font-weight-regular;
    color: $cow-black;
  }

  .andes-checkbox {
    display: flex;
    align-items: center;

    input.andes-checkbox__input {
      position: relative;
      width: em(10);
      margin: em(4) 0 0;
    }

    span.andes-checkbox__mimic {
      position: relative;
    }
  }
}

#group_content_combination {
  .title-h2 {
    font-size: em(14);
  }
}

.free-interest {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;

  .andes-list__item-first-column {
    flex-grow: unset;
  }

  .option-additional-info {
    margin-left: em(4);

    .installment__interest {
      font-size: em(14);
    }
  }
}

#group_pay {
  :first-child {
    .ui-card {
      border-radius: 0;
    }
  }

  :only-child {
    .ui-card {
      border-radius: 0 0 em(6) em(6);
    }
  }
}

#notice-processed-mp.text {
  font-weight: $cow-font-weight-semibold;
  color: $cow-dark-gray;
  font-size: $cow-font-14;
}

#use_consumer_credits button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: 400;
  height: 18px;

  &:hover {
    background-color: transparent;
  }
}

#dismiss_consumer_credits button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}
