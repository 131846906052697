.cvv-icon {
  background: url("../assets/images/credit-card/back-generic@1x.png") no-repeat;
  width: em(43, 14);
  height: em(27, 14);
  margin-top: 0;
}

@media (min-device-pixel-ratio: 2), (resolution >= 192dpi), (resolution >= 2dppx) {
  .cvv-icon {
    background-size: 100% auto;
    background: url("../assets/images/credit-card/back-generic@2x.png") no-repeat;
  }
}
