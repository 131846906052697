.payment-methods-list {
  height: em(41);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: em(16);
}

.payment-methods-list .icon-wrapper {
  margin: em(4) 0 em(4) 0;
  width: em(30);
  height: em(24);
  border: em(0.5) solid #e5e5e5;
  border-radius: em(5);
}

#account-money-b {
  height: em(17);
  width: em(17);

  path {
    fill: $andes-blue-500;
  }
}

.payment-methods-list .text {
  font-weight: 400;
  font-size: em(12);
  line-height: em(15);
  color: rgb(0 0 0 / 55%);
}
