@import "shared";
@import "../congrats/styles.desktop";

.redirect-view .layout-main {
  max-width: 100%;
}

.group_form {
  .cow-secure-fields {
    margin-bottom: em(20);
  }
}
