.modal-header {
  &__content {
    display: flex;
    justify-content: space-between;
    padding: em(12) em(16);

    .icon-wrapper {
      width: em(120);
    }

    .payer-name {
      color: $cow-lighter-blue;
      margin-left: em(4);
    }

    .modal-header__info {
      display: flex;
      font-size: $cow-font-14;
      line-height: em(28, 14);

      &-text {
        display: flex;
        margin-top: em(2);
      }

      .user-avatar {
        margin-left: em(10, 14);
      }

      .ui-avatar__content {
        width: em(28, 14);
        height: em(28, 14);
      }

      .icon-wrapper {
        width: em(28, 14);
        height: em(28, 14);
        margin-left: em(8, 14);
      }
    }
  }

  .divider {
    border-color: $cow-translucent-lighter-gray;
    border-width: em(0.5);
    opacity: 0.6;
    margin-block-start: 0;
    margin: 0 em(16);
  }

  .cross {
    top: em(-32);
    width: em(16);
    right: em(8);
    height: em(16);
    border: none;
    position: absolute;
    background: none;

    svg {
      path {
        fill: rgba($cow-translucent-dark-gray, 0.75);
      }
    }
  }

  &__mask {
    top: em(-0.2);
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    position: fixed;
    opacity: 0.7;
    background-color: $cow-black;
  }
}
