@import "~@andes/common/index";
// Andes components
:not(.legacy-mp-theme) {
  // ==== Andes Base Styles ====
  // Base
  @import '~@andes/button/base';

  // By hierarchy
  @import '~@andes/button/lib/styles/modifier/loud';
  @import '~@andes/button/lib/styles/modifier/quiet';
  @import '~@andes/button/lib/styles/modifier/transparent';
  @import '~@andes/button/lib/styles/modifier/white';

  // ==== By size ====
  // Small
  @import '~@andes/button/lib/styles/size/small';
  // Medium
  @import '~@andes/button/lib/styles/size/medium';
}

@import "~@andes/checkbox/index";
@import "~@andes/common/variables";
@import "~@andes/form/index";
@import "~@andes/form/base";
@import "~@andes/message/index";
@import "~@andes/textfield/index";
@import "~@andes/autocomplete/index";
@import "~@andes/floating-menu/index";
@import "~@andes/button/index";
@import "~@andes/progress-indicator-circular/index";
@import "~@andes/search-box/index";
@import "~@andes/dropdown/index";
@import "~@andes/list/index";
@import "~@andes/badge/index";
@import "~@andes/badge/base";
@import "~@andes/badge/lib/styles/modifier/notification";
@import "~@andes/badge/lib/styles/size/small";
@import "~@andes/radio-button/index";
@import "~@andes/tooltip/index";
@import "~@andes/radio-list/index";
@import "~@andes/modal/index";
@import "~@andes/switch/index";
@import "~@andes/tag/index";
@import "~@andes/bottom-sheet/index";
@import "~@andes/feedback-screen/index";
@import "~@andes/card/index";
@import "~@andes/common/lib/styles/visually-hidden";
@import '~@andes/progress-indicator-linear/index';
@import '~@andes/coach-marks/index';
@import '~@andes/accordion/index';
@import '~@andes/typography/index';

@import "~@andes/badge/lib/styles/color/red";
@import "~@andes/message/lib/styles/color/red";
@import "~@andes/badge/lib/styles/color/accent";
@import "~@andes/message/lib/styles/color/accent";
@import "~@andes/badge/lib/styles/color/green";
@import "~@andes/message/lib/styles/color/green";
@import "~@andes/badge/lib/styles/color/orange";
@import "~@andes/message/lib/styles/color/orange";

// Andes technical error styles
@import '~@andes/technical-error/index';
@import '~@andes/button/base';
@import '~@andes/button/lib/styles/modifier/transparent';
@import '~@andes/snackbar/index';
@import '~@andes/thumbnail/base';
@import '~@andes/thumbnail/lib/styles/modifier/circle';
@import '~@andes/typography/index';

// UI Library commons: Variables / Helpers / Utilities / Base / Reset styles
@import "utilities/variables";
@import "utilities/utilities";
@import "utilities/animations";
@import "utilities/reset";
@import "common-components/shieldFooter";

// Card Form Bricks
@import 'internal-checkout-bricks/lib/secureFields/client/index';
@import 'internal-checkout-bricks/lib/cardForm/client/index';

.cookie-consent-banner-opt-out__modal-content {
  iframe {
    display: block;
  }
}
