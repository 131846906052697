.disabled-text {
  position: relative;
  height: em(48);
  border-radius: em(6);
  border: solid em(1) $cow-translucent-light-gray;
  background-color: $cow-white;
  margin-bottom: em(4);
  padding: em(12);
  overflow: hidden;
  white-space: nowrap;

  .final-gradient {
    position: absolute;
    right: 0;
    top: 0;
    width: em(48);
    height: em(46);
    background-image: linear-gradient(to right, $cow-translucent-black 0%, $cow-white 100%);
  }
}
