.autoreturn {
  text-align: center;

  &-label {
    width: em(300);
    display: inline-block;
    text-align: left;
    font-size: em(14);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: $cow-translucent-dark-gray;
    text-emphasis: none;
  }
}

.progress {
  margin-right: em(16);
  display: inline-block;
  vertical-align: middle;
  background: none;
  box-shadow: none;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__large {
    width: em(30);
    height: em(30);
    line-height: em(30);

    &::after {
      border: em(10) solid $cow-medium-gray;
    }
  }

  &__small {
    width: em(18);
    height: em(18);
    line-height: em(18);

    &::after {
      border: em(2) solid $cow-medium-gray;
    }
  }

  .progress-bar {
    border-color: $andes-accent-color;
  }
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress__large .progress-bar {
  border-width: em(5);
}

.progress__small .progress-bar {
  border-width: em(2);
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: em(80);
  border-bottom-right-radius: em(80);
  border-left: 0;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: em(80);
  border-bottom-left-radius: em(80);
  border-right: 0;
  transform-origin: center right;
}

.progress .progress-left .progress-bar {
  transform: rotate(180deg);
}

.progress .progress-right .progress-bar {
  transform: rotate(180deg);
}

@keyframes loading {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
