.collapsible-selector-container {
  font-weight: 400;
  line-height: 1;
  background-color: $cow-white;

  .andes-list__item {
    padding: em(32);
  }

  .andes-list__item:hover {
    background-color: $cow-white;
  }

  .andes-list__item.title {
    font-size: em(16);
    font-weight: 600;
    padding: em(14) 0 em(14) em(16);
  }

  .andes-list__item-primary {
    font-size: em(18);
    color: $cow-translucent-dark-gray;

    span.quantity {
      font-weight: 600;
    }

    span.rate {
      color: $cow-color-success;
    }
  }

  .andes-list__item-secondary {
    font-size: em(14);
    margin-top: em(2);
  }

  .account-money {
    .andes-list__item-secondary {
      color: $cow-color-success;
    }
  }

  .andes-list__item-tertiary {
    align-self: center;

    .icon-wrapper {
      width: em(24);
    }
  }

  .andes-list {
    .andes-list__item {
      border-top: em(1) solid $cow-medium-gray;
    }
  }

  .andes-list__item-image-container {
    .icon-wrapper {
      width: em(28);
      margin: auto;
      margin-top: em(10);
    }

    .icon-circle-wrapper {
      border: solid em(1) $cow-translucent-lighter-gray;
      border-radius: 50%;
      width: em(48);
      height: em(48);
      margin-right: em(16);
    }
  }

  &.folded {
    .andes-list__item:not(.selected),
    .andes-list__item.title {
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      border: none;
      margin: 0;
    }

    .andes-list__item.selected {
      height: 100%;
    }

    .andes-list__item-tertiary .icon-wrapper {
      transform: rotate(90deg);
    }
  }

  &.unfolded {
    border-radius: em(4);
    position: absolute;
    top: em(115);
    width: 92%;
    box-shadow: 0 em(8) em(12) 0 rgb(52 131 250 / 12%), 0 0 em(4) 0 $cow-translucent-lighter-gray;
    z-index: 100;

    .andes-list__item {
      height: 100%;
    }
  }

  .andes-list__item {
    transition: height 0.2s ease-in, padding 0.2s ease-in;
  }
}

.payment-options-list {
  font-size: em(16);

  .group-row {
    display: flex;
    min-height: em(84);
    align-items: center;
    margin-bottom: em(12);
    line-height: 1.2;
    background: $cow-white;
    border-radius: em(4);

    .group-media-object {
      padding-right: em(12);
      flex-grow: 1;
    }

    .row-content-details {
      padding-right: em(8);
    }

    .button-step,
    .andes-button--link {
      flex-shrink: 0;
      font-size: em(14);
    }
  }
}

@media (width <= 320px) {
  .collapsible-selector-container.unfolded {
    width: 95%;
  }
}
