.row-dropdown {
  line-height: $cow-line-height-base;
  padding: em(16) em(16) em(16) em(32);
  background: $cow-white;
  border-bottom-left-radius: em(4);
  border-bottom-right-radius: em(4);

  &__toggle {
    .icon-wrapper {
      width: em(16);
      transform: rotate(90deg);
      padding-left: em(0.5);
    }

    &.up {
      .icon-wrapper {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .title {
    color: $cow-translucent-dark-gray;
    font-weight: $font-weight-regular;
    font-size: em(14);
    line-height: $cow-line-height-base;
  }

  .text {
    color: $cow-translucent-medium-gray;
    font-weight: $font-weight-regular;
    font-size: em(14);
    line-height: $cow-line-height-base;
    margin-bottom: em(14);
  }

  .label {
    color: $andes-accent-color;
    display: flex;
    font-size: em(14);
    cursor: pointer;
    padding-right: em(0.8);
  }
}
