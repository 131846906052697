.disclaimer-row {
  background-color: $cow-lighter-gray;
  padding: em(10) em(24);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: em(24) em(-24) em(-24);

  svg {
    display: inline-block;
    width: auto;
    height: auto;
  }

  .icon-with-tooltip {
    svg {
      path {
        fill: $andes-accent-color;
      }
    }
  }

  .text {
    font-size: $cow-font-12;
    margin-bottom: 0;
    margin-left: em(5);
    width: 100%;
  }

  .icon-with-tooltip {
    >div {
      margin: 0;

      .icon-info {
        border: none;
        height: auto;

        svg {
          path {
            fill: $andes-accent-color;
            fill-opacity: 1;
          }
        }
      }
    }
  }
}