.info-row {
  display: flex;
  flex-direction: column;

  .info-row__title {
    color: $cow-translucent-medium-gray;
  }

  .info-row__detail {
    color: $cow-translucent-medium-gray;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: em(228);
  }
}
