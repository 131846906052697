.fullscreen-message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-70;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade-in 260ms ease-in;

  &__content {
    text-align: center;
    max-width: em(270);
  }

  &__title {
    margin-bottom: em(16, 20);
    font-size: em(20);
    font-weight: 600;
    line-height: 1.2;
  }

  &__message {
    margin-bottom: em(32);
    font-size: em(16);
    color: $cow-dark-gray;
  }

  .andes-button {
    float: none;
    margin: 0;
    margin-bottom: em(8);
    width: 100%;
  }

  &__button-secondary {
    font-size: em(16);
  }
}
