div.optimus div.group-payment-method-row-with-cvv {
  .group-row {
    @extend .ui-card;

    border-radius: em(6) em(6) 0 0;
    background-color: $cow-white;
    padding: em(32);
  }

  .ui-card-wrapper .ui-card {
    margin-top: em(1);
    padding: em(16);
    border-radius: 0 0 em(6) em(6);
  }
}
