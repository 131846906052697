.progress-container {
  margin-top: em(24);
}

.input-amount-card {
  padding: 0;
  &__content {
    .payment-method-info {
      .description {
        margin-bottom: em(22);
        padding: 0;
      }
    }
    .split-amount {
      margin: 0;
      padding: em(2);
      box-shadow: unset;
    }
  }
}


// TODO - TECH DEBT: This code will be obsolete when rebranding is enabled and can be removed
.legacy-mp-theme {
  .input-amount-card {
    &__content {
      .split-amount {
        margin: 0;
        padding: em(2);
        box-shadow: unset;
      }
    }
  }
}
