@import "shared";

.group-summary--pcj.group-summary--pcj {
  padding: 32px 44px 32px 24px;

  hr, .cow-accordion, .row-summary--with-interest, .animated-group button, #shield_footer {
    width: calc(100% + em(20));
  }

  .row-summary__text, .row-summary__price {
    font-weight: $cow-font-weight-regular;
  }

  .row-summary--with-interest {
    margin-bottom: 0;
  }

  .animated-group button {
    margin-top: em(24) !important;
  }

  hr:first-of-type {
    margin: 16px 0 16px 0;
  }

  hr:last-of-type {
    margin: em(16) 0 em(24) 0;
  }
}

.row-collapsible {

  &__title,
  &__content {
    line-height: em(20);
  }

  &__title {
    &__label {
      &--large {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: em(16);
      }
    }
  }

  &__content {
    padding: em(11) em(20) 0 em(11);

    .andes-tooltip__trigger {
      margin: 0;
    }
  }
}
