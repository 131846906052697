.row-summary--discount {
  display: flex;
  font-size: em(16);
  line-height: 1.5;
  font-weight: 300;

  &.small {
    font-size: em(14);
  }

  & :last-child {
    flex-grow: 1;
    text-align: right;
  }

  .row-summary__text {
    display: block;
    color: $andes-success-green;
  }

  .row-summary__discount {
    color: $andes-success-green;
  }

  .question-mark {
    display: flex;

    .icon-wrapper {
      align-self: center;
    }
  }

  // Styles for special case when the discount has decimals for separate cents
  &:has(> *:nth-child(4)) {
    >.row-summary__discount:nth-of-type(1) {
      width: auto;
      flex: unset !important;

      &+.question-mark {
        width: 24px;
        justify-content: center;
      }
    }

    >.row-summary__discount:nth-of-type(2) {
      flex: 1;
      justify-content: flex-end;
      display: flex
    }

    >.row-summary__discount:nth-of-type(3) {
      width: auto;
      flex: unset;
    }
  }
}
