#logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: em(32) em(16) em(16) em(16);

  .icon-wrapper {
    width: em(40);
  }
}

.c-title {
  font-size: $cow-font-18;
  margin: em(8) 0 em(16);
  padding: 0 em(16);
  line-height: $cow-line-height-s;
}

#protected-purchase {
  justify-content: center;
  align-items: center;
  margin: 0;
  gap: em(5);

  .icon-shield-bpp {
    width: $cow-font-12;
    height: $cow-font-14;
  }

  .text {
    color: $cow-color-success;
    font-size: $cow-font-14;
  }
}

#unprotected-purchase {
  align-items: center;
  gap: em(5);
  margin-left: em(8);
  margin-bottom: em(16);
  font-size: $cow-font-12;
}

.select_login_options {
  margin-top: em(24);

  .with-track-event-wrapper {
    float: none;
    margin: 0;
  }

  .options-list__item {
    border-bottom: 1px solid $cow-gray-070;
    border-left: 6px solid $andes-accent-color;
  }

  .item-generic_wallet_row {
    border-bottom: 0;
  }
}

.title-h2 {
  padding-top: em(16);
  margin-bottom: em(4);
  margin-left: em(6);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: em(-500) em(-16) 0 em(-26);
    height: em(500);
    background: $andes-bg-secondary;
    border-bottom: 1px solid $andes-gray-100;
    z-index: -1;
    margin-bottom: 100px;
  }
}
