@import "shared";

.layout.layout--modal {
  .layout--new-interface {
    .layout__col-sidebar {
      height: 100%;
      background-color: $andes-white;

      .brand {
        position: static;
      }

      .sidebar--default {
        height: 100%;

        .cow-payment_summary {
          height: 100%;

          .andes-card__content {
            background-color: $andes-white;
          }
        }
      }
    }
  }
}

.content-split-amount {
    & > .andes-card {
      padding: 32px
    }
}

.actions-split-amount {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
