@import 'shared';

.group-summary {
  background: white;
}

.layout__col-content {
  .title-h2 {
    margin: em(32) 0 em(12) 0;
  }

  .group-flex:first-child {
    flex-direction: row;
    margin: em(26) 0 em(24) 0;
    padding-top: em(6);

    .c-title {
      margin: 0;
    }
  }

  .group-split-card-review .title-h2 {
    margin-top: em(24);
  }

  .ui-card {
    padding: em(32);

    &.options-list {
      padding: 0;
    }

    form {
      display: flex;
      align-items: center;
    }

    .icon-wrapper {
      margin-right: em(24);
    }
  }

  .content {
    .title-h3 {
      font-size: $cow-font-12;
      max-width: em(414, 12);
      color: $cow-translucent-medium-gray;
      margin: em(16, 12) auto 0 auto;
    }
  }
}

.group-total-summary ~ .form.group_form .icon-wrapper.icon-card-cvv {
  margin-right: 0;
}

.group-captcha {
  .andes-button {
    margin-top: em(12);
  }

  .text {
    margin: em(12) auto;
    margin-top: 0;
    font-size: em(14);
    color: $andes-error-red;
    text-align: center;
  }
}

&_split_payment.modal-view {
  .scroller {
    margin-bottom: em(24);
  }
}

.layout--modal {
  .toggle-button-wrapper {
    right: em(48);
  }

  #shield_footer {
    bottom: 20px;
    position: absolute;
    width: 100%;

    .text {
      span {
        font-size: em(12);
      }
    }
  }

  .andes-button.andes-button--loud {
    bottom: 40px;
  }

  .layout__col-sidebar {
    .andes-button.andes-button--loud {
      margin-bottom: em(12);
    }
  }

  .group-captcha {
    #g-recaptcha {
      position: absolute;
      width: 100%;
      bottom: em(100);
    }

    .text {
      margin: em(12) auto;
      margin-top: 0;
      position: absolute;
      bottom: em(78, 14);
      font-size: em(14);
      color: $andes-error-red;
      height: em(24, 14);
      text-align: center;
    }
  }

  .group-generic#group_shield {
    width: em(812);
    position: absolute;
    left: 0;
    background: $cow-white;
    z-index: 201;
    top: em(-60);
    border-radius: em(6);
    padding-top: em(60);

    .group-flex {
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: em(114) em(224);
      background: url('../assets/images/kycshield.svg') no-repeat;
      background-size: em(327) auto;
      background-position: top;

      .title-h3 {
        font-weight: $cow-font-weight-semibold;
        margin-top: em(8);
      }

      .title-h2 {
        font-weight: $cow-font-weight-regular;
        margin-top: em(8);
      }

      .kyc-why-link {
        font-size: $cow-font-14;
        font-weight: $cow-font-weight-semibold;
        color: $cow-lighter-blue;
      }

      .andes-button.andes-button--loud {
        margin-top: em(40);
        margin-bottom: em(8);
      }
    }

    .group-flex.hasRebranding {
      background: url('../assets/images/rebrandingkyc.svg') no-repeat;
      background-size: em(327) auto;
      background-position: top;

      .title-h3 {
        margin-top: em(32);
      }
    }
  }

  .group-summary {
    .group-legals {
      position: absolute;
      bottom: 110px;
    }
  }

  // Legacy styles
  .optimus {
    .layout__col-sidebar {
      background-color: $cow-white;
      .group-total-summary {
        button[type='submit'],
        #pay {
          position: static;
          margin-top: 20px;
        }
        .group-captcha.logged {
          & + p.text.gray {
            margin-top: 0px;
          }
        }
      }
    }

    .layout__col-content {
      &:has(.with-legals) {
        margin-bottom: 32px;
      }

      .group-total-summary {
        .aspirational.andes-message {
          margin-right: 10px;
        }
      }
    }

    .scroller {
      height: auto;

      .content {
        margin-bottom: 32px;

        .group-legals {
          bottom: 0;
        }
      }
    }
  }
}

.animated-content .group_form .ui-card {
  margin-top: 0;
}

&.site-mco {
  .captcha-terms {
    margin-bottom: em(16);

    &.logged {
      margin-top: 0;
    }
  }

  .group-back-url {
    margin-top: em(8);
  }

  .layout--modal .captcha-terms {
    margin-bottom: 0;
  }
}

#group_pay {
  .ui-card {
    height: em(112);
    padding: em(20) em(32);
  }

  .row_cvv {
    display: flex;

    .icon-card-cvv {
      width: em(127);
      margin-left: em(12);
    }
  }
}

.group-row {
  margin-bottom: em(1);
  border-radius: em(4) em(4) 0 0;

  & + .group-row {
    border-radius: 0 0 em(4) em(4);
  }
}

.group-total-summary + .c-title {
  margin: em(24) 0 em(16) 0;
  font-size: em(18);
  font-weight: bold;

  & + .group-row {
    min-height: em(74);
  }
}

.andes-button + .text.gray {
  text-align: center;
}

.group-row + .group-generic .aspirational.andes-message--neutral {
  margin-top: em(32);
}

.layout--redirect,
.layout--embed {
  .modal-content .header + .content {
    padding: 0 em(40);
  }
}

#group_pay {
  .icon-wrapper {
    padding-top: em(6);
  }
}

#email_row {
  #group_pay {
    .icon-wrapper {
      padding-top: 0;
      margin-bottom: em(8.8);
    }
  }
}

#group_content_combination {
  .c-title {
    margin: em(20) 0 em(10);
  }

  .title-h2 {
    margin-top: 0;
  }
}

form#group_installment .ui-card-wrapper {
  .group-generic {
    display: flex;
    flex-wrap: wrap;

    .icon-wrapper {
      vertical-align: middle;
      margin-right: em(18);
    }
  }

  .ui-card {
    overflow: visible;
    min-height: em(112);
    padding: em(20) em(32);

    .andes-message--quiet {
      margin-top: em(38);
    }
  }
}

#installments_group_generic {
  .option-additional-info {
    padding: em(14) 0;
  }
}

#notice-processed-mp.text {
  margin: $cow-spacing-16 0 $cow-spacing-32 0;
}

#group_sidebar {
  .row-summary__text {
    font-weight: $cow-font-weight-regular;
  }
}

//Exclusive styles for legacy architecture

.layout--redirect,
.layout--embed {
  #shield_footer {
    margin-top: 12px;
  }

  .row-summary {
    font-size: $cow-font-16;
    font-weight: $cow-font-weight-semibold;
    color: $cow-translucent-dark-gray;

    &--pay {
      margin-bottom: 0px;
    }
  }

  #pay {
    margin-top: 32px;
  }
}

.optimus {
  .group-legals {
    margin-top: 24px;
    padding: 0;

    .terms {
      p {
        text-align: center;
      }
    }

    & + .group-captcha.guest {
      #pay {
        margin-top: 12px;
      }
    }
  }

  .mp-brand {
    float: unset;
    margin: 0 auto;
  }

  .captcha-terms.guest {
    a:link,
    a:visited,
    a:active,
    a:hover {
      text-decoration: none;
      color: $andes-accent-color;
    }
  }

  .layout__col-sidebar {
    .group-total-summary {
      .group-summary {
        background-color: $cow-white;

        .group-captcha.guest,
        .group-captcha.logged {
          + .text.gray {
            color: $cow-translucent-medium-gray;
            font-weight: $cow-font-weight-semibold;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
