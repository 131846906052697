:root {
  --andes-gray-900-solid: #{$andes-gray-900-solid};
  --andes-gray-250-solid: #{$andes-gray-250-solid};
  --andes-gray-070-solid: #{$andes-gray-070-solid};
}

// New layout
$layout-desktop-max-width: 1024px;

// Measurements for pages that do not apply to the new layout
$layout-max-width: 812;
$navbar-height: 56;
$floating-pay-section-height: 110px;
$navbar-mobile-height: 56;
$modal-border-radius: 6;
$mobile-email-text-max-width: 68vw;

// Base colors
$cow-white: $andes-bg-white;
$cow-black: $andes-black;
$cow-bg-secondary: $andes-bg-secondary;
$cow-color-success: $andes-green-500;
$cow-color-error: $andes-red-500;
$cow-color-warning: $andes-orange-500;
$cow-light-blue: $andes-blue-500;
$cow-lighter-blue: $andes-blue-400;
$cow-yellow: $andes-yellow-500;
$cow-transparent-blue: $andes-blue-150;

// Grays
$cow-lighter-gray: $andes-gray-040-solid;
$cow-light-gray: $andes-gray-250-solid;
$cow-medium-gray: $andes-gray-100-solid;
$cow-dark-gray: $andes-gray-550-solid;
$cow-gray-800: $andes-gray-800-solid;
$cow-gray-070: $andes-gray-070-solid;

// Translucent Grays
$cow-translucent-black: rgb(255 255 255 / 0%);
$cow-translucent-dark-gray: $andes-gray-900;
$cow-translucent-medium-gray: $andes-gray-550;
$cow-translucent-light-gray: $andes-gray-250;
$cow-translucent-lighter-gray: $andes-gray-100;
$cow-translucent-lightest-gray: $andes-gray-040;
$cow-translucent-transparent-gray: $andes-gray-070;
$cow-common-background: $andes-bg-primary;

// Spacing
$cow-spacing-4: $andes-spacing-4;
$cow-spacing-8: $andes-spacing-8;
$cow-spacing-12: $andes-spacing-12;
$cow-spacing-16: $andes-spacing-16;
$cow-spacing-20: $andes-spacing-20;
$cow-spacing-24: $andes-spacing-24;
$cow-spacing-28: $andes-spacing-28;
$cow-spacing-32: $andes-spacing-32;
$cow-spacing-40: $andes-spacing-40;
$cow-spacing-48: $andes-spacing-48;
$cow-spacing-56: $andes-spacing-56;
$cow-spacing-64: $andes-spacing-64;

// Borders
$cow-border-radius-6: $border-radius-6;
$cow-border-radius-5: $border-radius-5;
$cow-border-radius-4: $border-radius-4;
$cow-border-radius-3: $border-radius-3;
$cow-border-radius-2: $border-radius-2;

// Typography
// Font weights
$cow-font-weight-light: $font-weight-light;
$cow-font-weight-regular: $font-weight-regular;
$cow-font-weight-semibold: $font-weight-semibold;
$cow-font-weight-bold: $font-weight-bold;

// Font families
$cow-font-family-primary: $font-family-primary;

// Font colors
$cow-text-color-primary: $andes-text-color-primary;
$cow-text-color-secondary: $andes-text-color-secondary;
$cow-text-color-disabled: $andes-text-color-disabled;

// Font sizes
$cow-font-size-base-value: $font-size-base-value;
$cow-font-size-base: $font-size-base;
$cow-font-12: $font-size-12;
$cow-font-14: $font-size-14;
$cow-font-16: $font-size-16;
$cow-font-18: $font-size-18;
$cow-font-20: $font-size-20;
$cow-font-24: $font-size-24;
$cow-font-28: $font-size-28;
$cow-font-32: $font-size-32;
$cow-font-44: $font-size-44;
$cow-line-height-xs: $line-height-xs;
$cow-line-height-s: $line-height-s;
$cow-line-height-base: $line-height-base;
$cow-line-height-m: $line-height-m;

//Overlay
$cow-overlay-background-color-default: $modal-overlay-background-color;
$cow-overlay-background-color-dark: rgba(0, 0, 0, 0.9);

// Z-index
$cow-z-index-loader-wrapper: 1021;
$z-index-70: 70 !default; // e.g. Modals, Mobile fullscreen overlays

// Gutters 
$gutter-base: 1em !default;
$gutter-24: 1.5em !default;
$gutter-32: 2em !default;

// Legacy color variables (Deprecated)
// These colors have no equivalent in Andes
$cow-blue-300: #32b3ff;
$cow-blue-darker: #1572ab;
