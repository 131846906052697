.status-bar {
  background: $andes-accent-color;
  width: 100%;
}

.container {
  &-buyer {
    padding: em(14);
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;

    .icon-page-not-available {
      display: inline-block;
      width: em(160);
      height: em(120);
      background: url("../assets/images/page-not-available.png") no-repeat;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      background-size: 100%;
    }

    h1 {
      font-size: em(24, 16);
      font-weight: 600;
      line-height: em(25, 24);
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: em(16, 24);
    }

    .description {
      font-size: em(16, 16);
      font-weight: 400;
      line-height: em(20);
      letter-spacing: 0;
      text-align: center;
      display: inline-block;
    }
  }
  &-developer {
    background: $cow-white;
    margin: auto;
    margin-top: em(40);
    padding: em(24);

    .icon-warning-badge {
      left: calc(50% - em(32));
      width: em(64);
      height: em(64);
      top: em(8);
      position: absolute;
    }

    h1 {
      margin-top: em(8, 24);
      font-size: em(24);
      font-weight: 600;
      line-height: em(30);
      letter-spacing: 0;
      text-align: center;
    }
    .description {
      font-size: em(16);
      font-weight: 400;
      line-height: em(20);
      letter-spacing: 0;
      text-align: center;
      display: block;
    }
    .button-group {
      display: flex;
      justify-content: center;
      margin-top: em(36);
    }
  }
}
