&_template {
  &_default {

    // TODO: MOMENTOUSLY SOLUTION FOR USE THE ANDES MoneyAmount COMPONENT WITH THE CURRENT DATA STRUCTURE FROM FLOWS
    .andes-money-amount__currency-symbol {
      display: none;
    }


    .top-row {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 0;
      margin-bottom: 32px;
      justify-content: space-between;
    }

    .card-form-wrapper {
      padding-bottom: 24px;
    }

    .multiple-errors-message {
      margin-top: 32px;
      margin-bottom: 16px;
    }

    .notification-message {
      margin-bottom: 16px;
    }

    .button-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      margin-bottom: 40px;
    }

    .back_button {
      margin-right: 8px;
    }
  }

}