.navbar {
  &:not(nav) {
    height: em($navbar-height);
    color: $cow-white;
  }

  &__brand-name {
    line-height: em($navbar-height);
  }

  .user-menu {
    float: right;
    margin-left: auto;
    margin-top: em(18);
  }

  .user-menu__button {
    color: inherit;
    font-family: $cow-font-family-primary;

    span {
      border-color: inherit;
    }
  }

  .user-menu__popup {
    top: em(34);
    right: em(-10);
  }
}

.navbar--mp {
  background: $andes-accent-color !important;
  box-shadow: 0 em(1) 0 0 rgb(0 0 0 / 10%);

  .navbar__logo {
    float: left;
    position: relative;
  }

  .user-menu__button span {
    opacity: 0.4;
  }
}

.navbar--ml {
  background: $cow-yellow  !important;
  color: $cow-translucent-dark-gray;
  box-shadow: 0 em(1, 16) 0 0 rgb(0 0 0 / 10%);

  .navbar__logo {
    float: left;
    position: relative;
  }

  .user-menu__button span {
    opacity: 0.4;
  }
  .user-menu__button {
    color: $andes-gray-900;
  }
}
