@import "shared";

.layout-main .optimus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: em(320);
  margin: 0 auto;
  padding: 0 em(16);
  text-align: center;
}

.layout--redirect .optimus,
.layout--embed .optimus {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  margin-left: em(-160); // half optimus 320width to center align
}

.layout--modal .optimus .navbar, .layout--modal .cow-navbar-v2 {
  position: absolute;
}

.pref-expired,
.pref-unavailable {
  .oops__message {
    font-weight: 600;
    margin-top: em(18);
    margin-bottom: em(8);
  }
}

.navbar {
  position: fixed;
}

.pref-expired,
.pref-unavailable {
  .icon-timeout {
    width: em(90);
    height: em(92);
  }

  .oops__message {
    font-size: em(20);
  }
}

.group-generic-message.expired {
  .c-title {
    text-align: center;
  }
}
