.row-summary {
  display: flex;
  font-size: em(16);
  line-height: 1.5;

  & :first-child {
    flex-grow: 1;
  }

  & :nth-child(2) {
    flex-shrink: 0;
  }

  &:not(.row-summary--pay) {
    .row-summary__text_promo {
      width: unset;
      text-align: end;
    }
  }

  &__text_promo {
    color: $cow-color-success;
  }

  &.row-summary--installment-combined {
    flex-wrap: wrap;

    .row-summary__text {
      flex-basis: min-content;
    }

    .row-summary__text_promo {
      flex-basis: 100%;
      font-size: em(14, 18);
    }
  }
}

// Rows with larger font-size and font-weight
.row-summary--pay {
  margin-bottom: em(12, 18);
  font-size: em(18);

  .row-summary__text {
    margin-right: 8px;

    @media (width <=767px) {
      line-height: 1.3;
      padding: em(2) 0;
    }
  }

  .row-summary__text_promo {
    width: 100%;
    text-align: right;
    font-size: em(14, 18);
  }

  @media (width <=767px) {
    font-size: em(16);
  }
}

// Rows with dynamic content between text and price(Tooltips)
.row-summary--dynamic,
.row-summary--tax {
  & :first-child {
    flex-grow: unset;
  }

  & :nth-child(2) {
    flex-shrink: unset;
  }

  & :last-child {
    flex-grow: 1;
    text-align: right;
  }
}

.row-summary--tax .row-summary__text {
  width: 57%;

  +.icon-with-tooltip {
    align-self: flex-start;
    margin-top: em(3);

    +.row-summary__price {
      white-space: nowrap;
    }
  }
}

/**
* @TODO review changes in this PR
* This change it may be no longer be necesary, but we can't test the new addr step ATM
* https://github.com/melisource/fury_px-checkout-frontend/pull/2415
.layout--modal {
  .row-summary {
    .row-summary__text_promo {
      width: 100%;
    }
  }
}
**/

.row-summary--with-interest {
  font-size: $cow-font-16;

  .row-summary__text,
  .row-summary__price {
    font-size: $cow-font-16;
    font-weight: $cow-font-weight-semibold !important;
  }

  .row-summary__price {
    &__group {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-end;

      &__interest {
        font-weight: $font-weight-regular;
      }
    }
  }
}

.row-summary--hide {
  display: none;

  &+hr {
    display: none;
  }
}
