.andes-typography.andes-typography--size-xl {
  margin: 32px 0;
  font-size: $cow-font-20;
  font-weight: $cow-font-weight-semibold;
}


.card-form-group {
  .group-generic:empty {
    display: none;
  }
}

.card-number-with-issuer__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .icon-wrapper {
    height: $cow-spacing-40;
    width: $cow-spacing-40;
    border-radius: 50%;
    border: solid 1px #BFBFBF;
  }

  .andes-message{
    flex-basis: 100%;
    margin-bottom: em(16);
  }
}

.split-amount {
  margin-top: 18px;
  margin-bottom: 32px;
  &__input {
    &.andes-form-control--error {
      .andes-form-control__message {
        margin-top: 8px !important;
        min-height: em(14);
      }
    }
    input::placeholder {
      color: $cow-text-color-secondary;
    }
  }
}

.group-card {
  margin-top: 18px;
}

.andes-form-control--disabled .andes-form-control__border {
  background-size: auto;
  background-color: rgba($cow-black, 0.25);
  background-image: none;
}

div.optimus div.ui-card {
  margin-top: 0;
}

// Overrides Andes Styles for Secure Fields and Dropdowns
.andes-form-control .andes-form-control__label {
  margin: 0 !important;
  display: inline-block !important;
}

.andes-dropdown.andes-dropdown--form {
  padding-top: em(18) !important;
}

.andes-form-control, .expiration-date-wrapper {
  .andes-form-control__bottom {
    margin-top: 0;
    .andes-form-control__error-icon {
      margin-top: 6px;
    }
  }

  .andes-form-control__message {
    margin-top: 6px;
  }
}

.input-cvv {
  label[for="cvv"] {
    span::after {
      content: unset;
    }
  }
  input::placeholder {
    color: $cow-text-color-secondary;
  }
}

.group-identification {
  #cow-select {
    #cow-select-label,
    .andes-floating-menu {
      padding-top: 4px !important;
    }
  }
}