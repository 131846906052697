@import "shared";

.row-details {
  padding-right: em(12);
}

.row-payment {
  padding: em(24);
  border-radius: em(4);
}
