@import "shared";

.ui-card-wrapper {
  padding: em(45) 0 em(34) 0;
}

.ui-card-wrapper .ui-document {
  margin: 0 auto;
}

.ui-card {
  height: em(385);
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-top: 0;
  overflow: visible;
  margin-bottom: 1.5em;

  .group-row {
    padding: 0;
    background-color: transparent;
    margin-bottom: 0;
    height: 5.25em;
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;

    > * {
      width: 50%;
      margin-right: em(16);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
