.u-no-user-select {
  user-select: none;
  cursor: default;
}

.u-hide {
  display: none !important;
}

.u-show {
  display: block !important;
}

.u-show-ib {
  display: inline-block !important;
}

.u-display-b {
  display: block;
}

.u-display-ib {
  display: inline-block;
}

.u-display-i {
  display: inline;
}

.u-clearfix {

  &:before,
  &:after {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.u-accessibility-hidden,
[aria-hidden='false'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.u-fw-300 {
  font-weight: 300;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-600 {
  font-weight: 600;
}

.u-text-success,
.u-text-green {
  color: $andes-green-500;
}

.u-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin modal-with-button-back {
  .layout--modal {
    .scroller {
      height: em(390);
      margin-bottom: em(16);
    }

    .user-header, .navbar + .layout__col-content, .cow-navbar, .cow-navbar-v2 {
      // When user header is present there is less viewport in scroller
      .scroller {
        height: em(340);
      }
    }
  }
}
