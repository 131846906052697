@import "shared";

.open-finance-terms {
  display: none;
}

.group-summary__container {
  .open-finance-terms {
    display: flex;
    margin-top: em(15);

    & ~ div > button {
      margin-top: em(15);
      position: relative;
    }
  }
}
