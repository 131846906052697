@import "shared";

.layout--modal {
  .skeleton-full {
    width: 994px !important;
    margin: 0 auto;
    &__sidebar {
      position: relative;
      height: em(566);
      width: 360px;
      left: em(21);
      border-top-right-radius: em(6);
      border-bottom-right-radius: em(6);
      background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
      animation: gradient 1500ms ease infinite;

      &-header {
        display: none;
      }

      &-item {
        display: none;
      }
    }

    &__content {
      padding-left: em(40);

      &-header {
        width: em(258);
      }

      &-item {
        width: 534px;
      }
    }
  }
}

.skeleton-full {
  width: 994px;
  display: flex;
  margin-left: auto;
  margin-right: auto;

  &__content {
    padding: 8px 0;

    &-header {
      height: em(24);
      width: em(336);
      background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
      background-size: 400% 400%;
      margin-top: em(52);
      margin-bottom: em(32);
      border-radius: em(4);
      animation: gradient 1500ms ease infinite;
    }

    &-item {
      height: em(72);
      width: 544px;
      background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
      background-size: 400% 400%;
      margin-bottom: em(12);
      border-radius: em(4);
      animation: gradient 1500ms ease infinite;
    }
  }

  &__sidebar {
    width: 360px;
    padding: em(8) em(0) em(8) em(36);

    &-header {
      height: em(24);
      width: em(102);
      background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
      background-size: 400% 400%;
      margin-top: em(52);
      margin-bottom: em(32);
      border-radius: em(4);
      animation: gradient 1500ms ease infinite;
    }

    &-item {
      height: em(322);
      width: 100%;
      background: linear-gradient(-45deg, $cow-translucent-lighter-gray, $cow-translucent-lightest-gray);
      background-size: 400% 400%;
      border-radius: em(4);
      animation: gradient 1500ms ease infinite;
    }
  }
}
