.row-card-list {
  .card-option-cta {
    margin: 0;
    color: $andes-accent-color;
    font-weight: $font-weight-semibold;
    font-size: $cow-font-14;
    line-height: 18px;
  }

  .card-option--active:before {
    box-shadow: inset em(4) 0 0 0 $andes-accent-color;
  }
}