.row-details {
  max-width: em(150);
}

.group-media-object {
  margin-bottom: em(24);
}

.group-button {
  margin-top: em(-4);
}

.group-button a {
  font-size: em(14);
  margin-right: em(12, 14);
}

.group-button a::after {
  content: "";
  display: inline-block;
  width: em(1);
  height: em(10);
  background: $cow-light-gray;
  margin-left: em(12);
}

.group-button a:last-child::after {
  display: none;
}
