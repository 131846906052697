.layout-main {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .page-mask {
    height: inherit;
  }
}

.layout-main .expired-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: em(320);
  margin: 0 auto;
  padding: 0 em(16);
  text-align: center;
}

.oops {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & ~ .with-track-event-wrapper {
    margin-bottom: em(40);
  }

  .oops__code-error {
    margin-top: em(8);
    background-color: $cow-translucent-lightest-gray;
    border-radius: em(36);
    padding: em(5) em(8);
    align-self: center;
    height: em(28);
    display: flex;

    .andes-badge__content {
      color: $cow-text-color-secondary;
      display: flex;
      align-items: center;
      gap: em(6);
      font-size: em(14);
      font-weight: $cow-font-weight-regular;
    }

    span {
      color: $andes-accent-color;
      font-weight: $cow-font-weight-semibold;
    }

    .button_icon_copy {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;

      svg {
        width: em(16);
      }

      .button_copy_textarea {
        border: none;
        resize: none;
        background: transparent;
        font-size: 0;

        &:focus-visible {
          box-shadow: none;
          color: transparent;
        }
      }
    }
  }
}

.pref-expired,
.pref-unavailable {
  .oops__message {
    font-weight: $cow-font-weight-semibold;
    margin-top: em(18);
    margin-bottom: em(8);
  }
}

.navbar {
  position: fixed;
}

.text {
  font-size: em(16);
  color: $cow-translucent-dark-gray;
  margin-bottom: em(24);
}
