
// TODO - TECH DEBT: remove this override when the migration to new architecture is complete (full andes)
.andes-thumbnail.icon-installments {
  border: solid 1px $cow-gray-070;

  p {
    color: $andes-accent-color;
    font-weight: $font-weight-semibold;
    font-size: $cow-font-16;
  }
}
