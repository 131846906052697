.error-hint {
  display: flex;
  align-items: center;
  font-size: em(13);

  .error-text {
    color: $cow-color-error;
    margin-left: em(8);
  }
}
