.cow-accordion {
  --cow-light-blue: #{$andes-accent-color};

  &:not(.cow-accordion--blocked) {
    cursor: pointer;
  }

  summary {
    list-style: none;
    align-items: flex-start;

    &::marker {
      display: none;
    }

    &::-webkit-details-marker {
      display: none;
    }

    svg {
      margin-top: em(3.5);
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
  }

  &[open] {

    summary {

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
