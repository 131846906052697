.spinner-wrapper {
  height: em(32);
  width: 100%;

  .andes-progress-indicator-circular {
    display: flex;
    justify-content: center;
  }

  .andes-progress-indicator-circular__container {
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .andes-progress-indicator-circular__label {
    margin-top: 0;
    margin-left: em(48);
  }
}