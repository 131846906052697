@import "shared";

// Link de promos
.bank-deals-link {
  position: absolute;
  right: em(44);
  top: em(52);
  margin: 0;
}

.form.group_form {
  > .ui-card {
    padding: em(24);
  }
}

.card-form-group {
  display: flex;

  .group-generic {
    flex: 1;
    width: 0;
    display: flex;
    flex-wrap: wrap;

    & > :nth-child(n) {
      flex: 1;
    }

    .icon-wrapper {
      padding-bottom: 1em;
      flex: none;
    }
  }

  .andes-dropdown,
  .icon-wrapper,
  label {
    margin-right: em(12);
  }

  & > :nth-child(2) {
    margin-left: em(11);
  }

  & > :last-child {
    margin-right: em(-11);
  }

  // CVV
  .input-cvv {
    margin-right: 0;
    width: em(155);

    .andes-form-control__label {
      top: em(8);
      font-size: em(16.2);
      letter-spacing: em(0.22);
      width: 110%; // Andes's placeholder width issue, this is required to avoid unexpected ellipsis
    }

    ::placeholder {
      font-size: em(15);
    }
  }

  .icon-card-cvv {
    margin-left: 0;
    padding-bottom: em(20);
  }

  // Installment select
  #installments_select_id {
    max-width: 100%;
    flex: 1 1 100%;
    overflow: hidden;

    &.andes-dropdown--open {
      overflow: visible;
    }

    .andes-dropdown__trigger {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .select-additional-info {
    font-size: em(24);
    font-weight: 300;
    margin-top: em(-21);
    color: $cow-translucent-medium-gray;
  }
}

// document
.group-identification {
  .group-identification__children {
    .andes-dropdown {
      min-width: 25%;
    }
  }
}

// Expiration date max length
.card-form-group:nth-child(3) {
  .group-generic:nth-child(2) {
    flex: 0.5;
  }
}

// Fix installments dropdown random popover width due to andes dropdown
.card-form-group:nth-child(4) {
  .group-generic:first-child {
    .andes-dropdown {
      margin-right: 0;
    }
  }
}

// Modal
.layout--modal {
  .card-form-group .group-generic .input-cvv {
    .andes-form-control__label {
      top: em(10);
      font-size: em(15.2);
      letter-spacing: em(0.22);
    }

    ::placeholder {
      font-size: em(14);
    }
  }
}

.card-number-with-issuer__group.multiple-issuers #card_number {
  font-size: em(17);
}

.single-bin {
  > :first-child {
    .icon-wrapper {
      margin-right: 0;
    }
  }

  > :nth-child(2) {
    flex: 0;
    visibility: hidden;
  }
}

.icon-card-cvv {
  width: em(51);
  margin-right: em(-6);

  svg {
    width: em(51);
    flex: none;
    margin-left: em(20);
  }
}

.andes-button--link {
  margin-left: em(42);
}

.ui-card {
  overflow: visible;
}

#installments_generic_group {
  margin-right: 0;

  #installments_select_id {
    .andes-list__item {
      display: flex;
    }
  }
}

.scroller {
  // forms buttons
  > .group-generic {
    display: flex;

    form {
      margin-left: auto;
      margin-right: em(50);
    }
  }
}

.layout--modal {
  .layout__col-content .scroller {
    top: em(80);
    margin-bottom: em(32);
  }
}

// Popover custom height & position
#installments_select_id > .andes-dropdown__popover {
  bottom: -20%;
  top: auto !important;
  max-height: em(250);
  overflow-y: scroll;
  overflow-x: hidden;
}

div[name*="identification_group_id"] > .andes-dropdown__popover {
  bottom: -20%;
  top: auto !important;
  max-height: em(250);
  overflow-y: scroll;
}
