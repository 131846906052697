.group-media-object {
  display: flex;
  align-items: center;
  height: 100%;

  // The .icon-credits-yellow class comes from the cow components library
  &:has(.icon-credits-yellow) {
    .icon-wrapper.icon-mercado-credito {
      border: none;
    }
  }
}
