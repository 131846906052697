.toggle-hide + .animated-group {
  .group-coupon {
    visibility: hidden;
  }
}

form.group_form {
  .group-coupon {
    display: flex;
    flex-direction: column;
    margin-bottom: em(32);

    .ui-card {
      padding: em(16) em(32) em(16) em(32);
      margin-bottom: em(32);

      .group-row {
        padding: 0;
        background: $cow-white;
        margin: em(8) 0;

        .ui-input-text {
          width: 100%;
        }
      }

      .group-row:last-of-type {
        label.ui-input-text {
          margin-left: em(60);
        }
      }

      .group-row:first-of-type {
        label.ui-input-text {
          margin-left: em(0);
        }
      }
    }

    .icon-wrapper {
      margin-right: em(24);
    }

    .andes-button.andes-button--loud {
      align-self: flex-end;
    }

    &.applied {
      .ui-card {
        padding: em(16) em(32);
        margin-bottom: 0;

        .group-row {
          margin: 0;

          .group-generic {
            .text:last-of-type {
              font-size: em(18);
              color: $cow-black;
              margin: em(6) 0 em(4) 0;
            }
          }
        }
      }
    }

    #button_add_coupon {
      position: static;
    }
  }
}
