@import "shared";

.group-legals {
  position: absolute;
  bottom: em(32);
  left: em(32);
  right: em(32);
  display: flex;
  align-items: center;
  padding: $gutter-base;

  .mp-brand {
    float: left;
    margin: 0 $gutter-base;
    flex-shrink: 0;
  }

  hr {
    height: em(30);
    width: em(2);
    display: block;
    float: left;
    flex-shrink: 0;
    flex-grow: 0;
    background: $cow-dark-gray;
    opacity: 0.3;
    margin: 0;
    margin-right: $gutter-base;
  }

  > a {
    // when there is only a link in group legals it must be centered
    display: block;
    margin: 0 auto;
    font-size: em(12);
    text-decoration: none;
  }

  .group-generic > a {
    font-size: em(11);
  }
}
.user-header,
.navbar--ml + .layout__col-content {
  .group-legals {
    position: inherit;
  }
}

// Special styles for redirect layout
.redirect-view .group-legals {
  position: relative;
  bottom: 0;
  left: 0;
  margin-top: em(16);
}

.step_review_split_payment {
  .group-legals {
    position: static;
  }

  .captcha-terms {
    position: static;

    &.logged {
      margin-top: em(24);
    }
  }
}
