.ui-card {
  padding-bottom: 0;

  .group-row {
    padding: 0;
    background-color: transparent;
    margin-bottom: 0;

    > * {
      flex-grow: 1;
    }

    .andes-dropdown {
      margin-right: em(16);
    }
  }

  .andes-dropdown {
    &.placeholder {
      .andes-form-control__label {
        visibility: hidden;
      }
    }

    &.unselected {
      select {
        color: $cow-dark-gray;
      }
    }
  }

  .identification-card {
    margin: em(32) auto;
  }
}
