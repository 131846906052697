.group-flex {
  justify-content: space-between;
}

.icon-info svg {
  width: $cow-spacing-16;
  height: $cow-spacing-16;
}

.icon-info path {
  fill-opacity: 1;
  fill: $andes-accent-color;
}

.c-title {
  margin-top: $cow-spacing-8;
  margin-bottom: $cow-spacing-32;
}

.text {
  font-weight: bold;
}

.form-actions {
  margin: $cow-spacing-24 0;
  padding-bottom: $cow-spacing-48;
}

.stepper + .rebranding {
  &__title {
    margin: $cow-spacing-16 0 $cow-spacing-32;
    margin: $cow-spacing-12 0 $cow-spacing-16 0;
  }
}

.stepper {
  margin-bottom: 0;
}