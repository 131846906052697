@import "shared";

.layout--modal {
  .group-flex {
    p.text {
      max-width: em(370);
    }

    .group_form {
      #button_popup {
        width: em(211);
        margin: 0 auto em(0.4);
      }
    }
  }
}
