@import "shared";

.step_shipping_review {
  .layout__col-content .andes-button--loud {
    margin-top: em(30);
  }
}

.row-dropdown {
  padding-right: em(12);
}
