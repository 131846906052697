.payment-method-info {
  background-color: $cow-white;

  .description {
    display: flex;
    flex-direction: row;

    > :first-child.icon-wrapper {
      width: em(40);
      height: em(40);
      border-radius: 50%;
      border: solid em(1) $cow-gray-070;
      margin-right: em(16);
    }

    //Icons with legacy arquitecture
    .icon-mercado-credito:has(.icon-credits-yellow) {
      border: none !important;
    }
    .icon-account-money-yellow {
      border: none !important;
    }

    &__details {
      .first-text {
        font-size: $cow-font-16;
        color: $cow-translucent-dark-gray;
      }

      .second-text {
        font-size: $cow-font-14;
        color: $andes-gray-550;
      }
    }
  }

  .amount-details {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $cow-gray-070;

    &__label {
      display: flex;
      align-items: center;
      font-size: $cow-font-16;
      color: $andes-gray-550;
    }

    &__values {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;

      .amount {
        font-size: $cow-font-16;
        font-weight: $cow-font-weight-semibold;
        color: $cow-black;
      }

      .amount-installments {
        &__row {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          .installments {
            font-size: $cow-font-14;
            font-weight: $cow-font-weight-regular;
            color: $andes-gray-550;
          }

          .extra-label {
            font-size: em(14);
            color: $cow-color-success;
            line-height: em(18, 14);
            margin-bottom: 0;
            margin-left: em(4, 14);
          }
        }
      }
    }
  }
}

.paymentMethodInfo {
  .list-item-asset {
    &:has(.icon_account_money_yellow),
    &:has(.icon_mercado_credito_yellow) {
      border-color: transparent;
    }
  }
}
