// This scroller styles only apply for desktop in modal
// In mobile is not required because the whole page scrolls in all cases
// It doesn't have specific height so it adapts well in modal and redirect view in desktop
.layout--modal {
  .scroller {
    width: 100%;
    bottom: 0;
    overflow-y: scroll;
    height: 410px;
    padding: 0 em(10) 0 0;
    // Firefox
    scrollbar-color: $cow-dark-gray transparent;
  }

  .user-header, .navbar + .layout__col-content, .cow-navbar-v2 {
    // When user header is present there is less viewport in scroller
    .scroller {
      height: em(365);
    }
    #shield_footer {
      height: em(16);
      margin: em(16) 0;
    }
  }

  .scroller::-webkit-scrollbar {
    width: em(4);
    background-color: transparent;
    border-radius: em(4);
  }

  .scroller::-moz-scrollbar {
    width: em(4);
    background-color: transparent;
    border-radius: em(4);
  }

  // Safari
  .scroller::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .scroller::-webkit-scrollbar-thumb {
    background-color: $cow-dark-gray;
    border-radius: em(4);
  }

  .scroller::-moz-scrollbar-thumb {
    background-color: $cow-dark-gray;
    border-radius: em(4);
  }

  .scroller::-moz-scrollbar-track {
    background-color: transparent;
    border-radius: 4px;
  }

  .scroller.with-legals {
    height: em(380);
  }

  .scroller.with-captcha {
    height: em(420);
  }
}
