@import "shared";

.layout--modal {
  .zipcode-result {
    .single-result {
      background: $cow-white;
      padding: em(40);
      box-shadow: 0 em(2) em(1) 0 $cow-translucent-lighter-gray;
      border-radius: em(4);
    }

    .group-list-wrapper {
      max-height: em(305); // calc(530px - 180px); 530: modal height
      overflow: scroll;
      margin-bottom: em(24);

      .andes-list__group--header {
        padding: 0;
        margin: 0;
      }
    }
  }

  // Group list shadows
  .group-list {
    border-radius: em(4);
    box-shadow: 0 0.07em 0.125em 0 $cow-translucent-light-gray;
  }
}

.layout--redirect,
.layout--embed {
  .modal-content > .modal-wrapper {
    .inner-wrapper {
      padding: em(32) 0;

      .header {
        margin-bottom: em(24);
        padding: 0 em(32);

        .title {
          font-weight: $cow-font-weight-semibold;
        }
      }

      .zipcode-finder {
        margin: 0;

        .buttons-container {
          padding: 0 em(32);
        }

        .single-result {
          p.text.gray {
            margin-bottom: em(32);
          }
        }

        .group-list-wrapper {
          max-height: calc(100vh - 300px);
          margin-bottom: em(24);
          overflow: scroll;

          .andes-list {
            .andes-list__group--header {
              padding: 0;
              margin: 0;
            }

            .andes-radio-button-list__list-item:first-of-type {
              border-top: em(1) solid $cow-medium-gray;
              border-radius: 0;
            }

            .andes-radio-button-list__list-item:last-of-type {
              border-bottom: em(1) solid $cow-medium-gray;
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  .modal-content.shield-modal > .modal-wrapper {
    .inner-wrapper {
      .header {
        padding: 0;
      }
    }
  }
}

.zipcode-finder {
  .andes-list__item-primary {
    font-size: em(16, 16);
  }

  .andes-list__item-secondary {
    margin-top: em(2);
    display: block;

    div.andes-list__item-selection-control > .andes-radio {
      align-self: center;
    }
  }

  .finder-result--secondary {
    display: flex;
    flex-direction: column;

    span {
      font-size: em(14);

      &:first-of-type {
        color: $cow-translucent-dark-gray;
      }
    }
  }
}
