@import "shared";

.brand {
  padding: 40px 0 24px;
  position: absolute;
  top: 0;
  width: 100%;
}

.layout--modal {
  .brand {
    padding: em(32) em(24) 0 em(24);
    z-index: 1;
    position: relative;
  }

  .group-summary .title-h2 {
    margin-block: 0 16px;
    margin-inline: 0;
  }

  .cow-payment_summary {
    height: 100%;
    .andes-card__content {
      height: 100%;
    }
  }

  .brand__name {
    max-width: em(150);
  }
}

.layout--redirect {
  .brand__name {
    max-width: em(190);
  }
}
