&_template {
  &_default {

    /*
      TODO: This adjusts navbar size so that is displays correctly with the new Grid changes. 
      To be removed once the grid initiative fixes this and goes live.
    */
    .cow-navbar__content {
      max-width: 960px;
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .cow-payment_summary {

      // TODO: TECH-DEBT:  MOMENTOUSLY SOLUTION FOR USE THE ANDES MoneyAmount COMPONENT WITH THE CURRENT DATA STRUCTURE FROM FLOWS
      .andes-money-amount__currency-symbol {
        display: none;
      }
    }

    .top-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-top: 0;
      margin-bottom: 32px;
      justify-content: space-between;
    }

    #third-party-disclaimer {
      margin-top: 24px;
    }

    .button-wrapper {
      margin-top: 24px;
    }
  }
}