.optimus {
  &::after {
    display: block;
    top: 0;
    left: 0;
    z-index: -100; // High z-index to send it to back when animation finishes
    width: 100%;
    height: 100%;
    background: rgba($cow-white, 0.8);
    animation: fade-out-overlay 400ms ease-out backwards;
    pointer-events: none;
    opacity: 0;
    content: "";
  }
}
