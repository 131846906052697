.step_card_form {
  .input-cvv {
    .andes-form-control {
      &__embedded {
        .icon-card-cvv-grey {
          margin-right: em(15);
        }
      }

      // Just apply to mozilla browsers
      @-moz-document url-prefix() {
        &__control {
          #cvv {
            width: 90%;
          }
        }
      }
    }

  }
  
  @supports (-moz-appearance:meterbar) {
    .ui-card .card-form-group #cvv::placeholder {
        color: rgba(0, 0, 0, 0.3)
    }
  }
}
