@import "shared";

#group_content {

  #title_combination {
    font-size: $cow-font-14;
  }
}

#group_form_scroller {
  .layout__col-content {
    padding-right: 0;
  }
}

#group_actions {
  margin-top: 24px;
}

.group-back-url {
  min-height: 8px;
  height: 8px;
}

.payment-detail__content {
  padding-left: 32px;
  padding-right: 32px;
}

#group_sidebar {
  .group-summary {
    padding: 32px 24px;

    .row-summary__text {
      font-weight: $cow-font-weight-regular;
    }
  }
}

.combination_row {
  label.with-switch {
    padding: 16px 22px 16px 32px;

    .row-details {
      .title {
        line-height: em(21, 16);
      }
    }
  }
}

.layout--modal {
  .scroller {
    height: 300px;
  }
}

.layout__col-content {
  .andes-card.payment-method-card-list {
    ul.andes-radio-list {
      li.andes-list__item.radio-list-item-desktop {
        //TODO: this will be deleted with new architect
        padding: 16px 32px 16px 20px !important;
      }
    }
  }
}
