@import "shared";

.open-finance-awaiting-container {
  &__lottie {
    .lottie-wrapper {
      height: em(300);
    }

    div[aria-label="animation"] {
      display: flex;
      align-items: center;
    }
  }

  &__text {
    margin: em(20) auto 0 auto;

    h1 {
      font-size: em(34);
      max-width: em(250);
    }
  }

  .lottie{
    &__container{
      height: 330px;
    }
  }
}
