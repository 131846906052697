.challenge {
  width: 100%;
  background: $cow-gray-070;
  border-radius: em(4);
  overflow: hidden;

  .login-header {
    height: 50%;
    padding: 20%;
    padding-bottom: 10%;
    background: $cow-gray-070;
    text-align: center;
  }

  .icon-wrapper {
    height: 0;
    visibility: collapse;

    svg {
      width: 0;
      height: 0;
    }
  }

  .login-titles {
    max-width: em(252);
    margin: 0 auto;

    .c-title {
      line-height: 1.1;
      color: $cow-translucent-dark-gray;
      margin: em(4);
    }

    .text {
      color: $cow-black;
      opacity: 0.8;
      font-size: em(16);
    }
  }

  .login-content {
    background: $cow-gray-070;
    height: 100%;
    padding: em(16) 20%;

    .andes-button {
      display: block;
      width: em(205);
      padding: 0;
      margin: 0 auto;
      text-align: center;

      &.andes-button--quiet {
        margin-top: em(16);
      }
    }
  }

  &.emisor_kyc,
  &.emisor_reauth,
  &.reauth,
  &.kyc_ifpe {
    .login-titles {
      max-width: em(340);

      .c-title {
        font-size: $cow-font-24;
      }

      .text {
        font-size: $cow-font-16;
      }
    }

    .login-content {
      .andes-button.andes-button--link {
        margin-top: em(8);
      }
    }
  }

  &.consumer_credits_kyc {
    .andes-button--link {
      margin-top: em(8);
      width: auto;
    }
  }
}

.login-redirect-iframe-wrapper {
  .login {
    margin-top: em(20);

    .login-header {
        height: 50%;
        padding: 19%;
    }
  }
}
