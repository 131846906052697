.issuers-row {
  display: flex;
  height: 100%;
  align-items: center;
}

.issuers-row__logo {
  max-height: em(48);
  margin: 0 auto;
}

.issuers-row__text {
  margin: 0 auto;
  font-size: em(16);
  color: $cow-gray-800;
}
