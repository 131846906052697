.modal-zipcode {
  width: em(720);

  .modal-zipcode-select {
    margin-bottom: $cow-spacing-16;
  }

  .modal-zipcode-input {
    margin-bottom: $cow-spacing-32;
  }

  .andes-modal__content {
    min-height: em(400);
  }

  .modal-zipcode-actions {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 0 !important;
    background: #fff;
    padding: $cow-spacing-24 $cow-spacing-24 $cow-spacing-16;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.07), 0 0 $cow-spacing-24 0 rgba(0, 0, 0, 0.1);
    justify-content: flex-end;

    button:first-child {
      margin-right: $cow-spacing-16;
    }
  }

  .modal-zipcode-radiolist {
    label {
      cursor: pointer;
    }

    .andes-list__item {
      align-items: center;
      border-bottom: 1px solid #f1f1f1;

      &:last-child {
        border-top: unset;
      }

      &:first-child {
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
      }
    }
  }
}
