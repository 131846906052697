#cow-select {
  .andes-list__item-text {
    .andes-list__item-primary {
      display: inline;
      margin-right: em(5);
    }
  
    .andes-list__item-secondary {
      display: inline-block;
      margin-top: 0;
  
      .installment__interest {
        color: $cow-color-success;
        font-size: $cow-font-16;
        margin: auto;
        font-weight: $font-weight-regular;;
      }
    }
  }
}

.andes-dropdown.fixedMessage .andes-form-control__message {
  opacity: 1;
}

.andes-dropdown__popover {
  top: initial !important;
}

//TODO: check Andes Dropdown component
.andes-card--flat {
  box-shadow: 0 em(1) em(3) 0 rgb(0 0 0 / 20%);
}

.select-installments {
  .andes-floating-menu > .andes-dropdown__trigger + [data-tippy-root] {
    transform: translate3d(0, 0, 0) !important;
    inset: auto auto auto 0 !important;
    margin-top: em(-8, 16) !important;
  }
}
