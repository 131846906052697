.terms {
  p {
    font-size: em(11);
    color: $cow-text-color-secondary;
  }

  a {
    color: $cow-light-blue;
  }

  span,
  a {
    margin-right: em(4, 11);
  }
}
