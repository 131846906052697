.error_boundary {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step_skeleton {
  .layout--modal {
    .error_boundary {
      height: 100%;
      position: absolute;
      inset: 0;
      margin: auto;

      .oops {
        position: absolute;
        top: 32%;
      }
    }
  }
}
