.step_open_finance_bank_selection {
  .andes-list {
    &__item {
      position: relative;
      padding: 0 em(20);

      &::before {
        background: inherit;
        border-bottom: em(1) solid $andes-blue-400;
        border-right: em(1) solid $andes-blue-400;
        bottom: 0;
        box-sizing: content-box;
        content: "";
        height: em(5);
        margin-bottom: auto;
        margin-top: auto;
        position: absolute;
        right: em(15);
        top: 0;
        transform: rotate(-45deg);
        width: em(5);
      }

      &:focus {
        outline: 0;
      }
    }

    &__primary-text {
      color: $andes-gray-900;
    }
  }

  .group-bank-selection {
    padding: 0;
  }

  .andes-form-control--search-box {
    height: em(30);
  }
}
