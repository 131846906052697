@import "~@andes/snackbar/index";
@import "~@andes/button/base";
@import "~@andes/button/lib/styles/modifier/transparent";

.andes-snackbar {
  &__message {
    color: $cow-white;
    font-size: em(16);
  }
}

.page-mask {
  .group-list-wrapper {
    .andes-list .andes-list__group .andes-list__group--sublist {
      box-shadow: 0 em(1) em(2) 0 rgb(0 0 0 / 12%);
    }
  }
}