$margin-in-use-on-screen: 220px;

.shipping-zipcode-form {
  .page-content {
    display: flex;
    flex-direction: column;
  }

  .page-content__mobile {
    justify-content: space-between;
    margin: $andes-spacing-24 0;
    height: calc(100vh - $margin-in-use-on-screen);
  }

  .page-content__desktop {
    margin: $andes-spacing-24 0;
  }

  .action__desktop {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
    margin-top: $andes-spacing-24;
  }

  .action__mobile {
    display: flex;
    flex-direction: column !important;
    gap: 12px;
  }

  .content {
    .full-width {
      width: 100%;
    }

    .form-content {
      display: flex;
      flex-direction: column;
      gap: $andes-spacing-24;

      .form-content-group__desktop {
        display: flex;
        flex-direction: row;
        gap: $andes-spacing-24;
      }

      .form-content-group__mobile {
        display: flex;
        flex-direction: column;
        gap: $andes-spacing-24;
      }
    }
  }
}

.form_address_info_modal_button {
  min-width: 110px;
  margin-left: -20px !important;
  font-size: $cow-font-14;
}
