@import "shared";

.layout--new-interface {
  .cow-navbar-v2 + .layout__col-content {
    margin-top: ($navbar-height)px;

    .cow-back-link {
      margin-top: 24px;
    }
  }
}
