.expiration-date-wrapper {
  display: block;

  .andes-form-control__label {
    min-width: 136px;
    padding-top: 1px;
  }

  .expiration-date__inner_wrapper {
    display: flex;
    flex-grow: 1;
    padding-top: 3px;

    > :nth-child(n) {
      flex-grow: 1;
      flex-basis: 0;
    }

    .andes-floating-menu {
      button {
        margin-top: 4px;
      }
    }
    .andes-dropdown.andes-dropdown--form {
      padding-top: 17px !important;
      .step_first_split_payment &,
      .step_second_split_payment & {
        padding-top: 21px !important;
      }
    }
  }

  &.error {
    .andes-dropdown {
      margin-bottom: 0;
    }

    .andes-form-control__message {
      color: $cow-color-error;
      font-weight: 600;
      opacity: 1;
      margin-top: 0;
    }

    .andes-form-control__border {
      background-color: $cow-color-error;
    }
  }

  .andes-form-control__message.hidden {
    display: none;
  }

  .andes-floating-menu>.andes-dropdown__trigger+[data-tippy-root] {
    transform: translate3d(0, 0, 0) !important;
  }

  .andes-form-control__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6px;
    .andes-form-control__error-icon {
      background: $cow-color-error;
      border-radius: 50%;
      height: 12px;
      width: 12px;
      display: block;
      margin: 6px 6px 0 0;
      svg {
        display: block;
      }
    }
    .andes-form-control__message {
      font-size: 13px;
    }
  }
}