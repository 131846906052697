.user-header {
    display: none;
}

#group_content {
    padding: 0;
    margin-top: 0;

    .ui-card-wrapper {
        .ui-card {
            margin: 0 auto;
            padding: 0 em(24) em(24);
            margin-top: em(16);

            .group_form {
                display: inline-block;

                .group-media-object {
                    margin: em(24) 0;

                    #payment-card-icon-wrapper {
                        width: em(40);
                        height: em(40);
                        margin-right: em(16);
                        border-radius: 50%;
                    }
                }

                .security-code,
                .input-cvv {
                    float: left;
                    width: em(200);

                    .andes-form-control---error-icon {
                        margin-right: em(6);
                    }

                    .andes-form-control__bottom {
                        min-height: 0;
                        margin: em(6, 13) 0 0 em(6, 13);
                    }
                }

                .andes-button--loud {
                    display: inline;
                    margin: em(21) 0 0 em(16);
                }
            }
        }
    }
}
