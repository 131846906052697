@import "shared";

.cow-payment_summary,
.cow-payment_summary--desktop {

  .andes-card__content {
    padding: 32px !important;

    //TODO: PCJ, quit this when new Summary will be added
    .flex-between-center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0
    }

    &:has(.pcj-summary) {
      .cow-payment_summary__title {
        margin-bottom: 0px !important;
      }
    }

    .pcj-summary {
      margin-bottom: 14px;

      .andes-accordion {
        border: none;

        .andes-accordion-header {
          &:hover {
            background: none;
          }

          .andes-accordion-header-container {
            padding: 0;

            &__indicator {
              width: 20px;
              margin-right: 0;
              margin-left: 0;

              svg {
                path {
                  fill: $andes-accent-color;
                }
              }
            }
          }
        }

        .andes-accordion-panel-transition {
          .andes-accordion-panel-transition__inner {
            padding: 0 20px;

            >div {
              min-height: unset;
            }

            &>div:first-child {
              padding: 0;
            }

            .andes-tooltip__trigger {
              margin-left: 4px;
              margin-top: 8px;

              svg {
                path {
                  fill: $andes-accent-color
                }
              }
            }
          }
        }
      }

      &__juros {
        padding-right: 18px;
      }

      .total-summary {
        margin-top: 26px;
        padding-top: 28px;
        border-top: 1px solid $cow-gray-070;
      }
      .pcj-summary__accordion {
        .andes-accordion-panel-transition {
          margin-bottom: 12px;
        }
      }
    }

    .summary-total {
      text-align: center;

      .tyc_consumer_credits {
        font-size: $cow-font-12;
      }

      a:visited,
      a:active,
      a:hover,
      a:link {
        text-decoration: none;
        color: $andes-accent-color;
      }
    }

    .cow-payment_summary__row--total {
      font-weight: $cow-font-weight-semibold;

      .row-item__observation {
        span {
          font-weight: $cow-font-weight-semibold;
        }
      }
    }

    .cow-payment_summary__button {
      margin-top: 2px;
    }

    .pcj-summary+.cow-payment_summary__button {
      margin-top: -26px;
    }
  }
}

.paymentMethodInfo {
  .andes-card.andes-card--flat {
    .andes-card__footer {
      padding: 16px 32px;
    }
  }
}
