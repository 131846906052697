@import 'shared';

.ui-card {
  .card-form-group:last-child {
    display: flex;
    flex-direction: row;

    .expiration-date,
    .input-expiration-date {
      padding-right: em(24);
    }

    // When the card doesn't have CVV, we want the EXPI input to have no padding
    .expiration-date:only-child,
    .input-expiration-date:only-child {
      padding-right: 0;
    }

    .expiration-date,
    .input-expiration-date,
    .input-cvv,
    .security-code {
      width: 100%;
    }
  }
}

.optimus {
  .layout__col-sidebar {
    .group-total-summary {
      .group-summary {
        .title-h2 {
          font-size: $cow-font-16;
          font-weight: $cow-font-weight-semibold;
        }
      }
    }
  }
}
