&_template {
  &_guest,
  &_default,
  &_express,
  &_saved_card,
  &_subscription_saved_card,
  &_credits {
    .icon-wrapper {
      width: $cow-font-16;
      margin-right: $cow-spacing-4;
    }

    .installments-selector > .interest-info {
      .interest-info_text {
        font-size: 13px;
        margin-left: 6px;
        display: block;
      }
    }

    .installments-selector > .andes-dropdown.andes-dropdown--form {
      padding-top: 0px;
      .andes-form-control__message {
        margin-top: $cow-spacing-4;
      }
    }

    div.cow-secure-fields.security-code > div {
      label > div.andes-form-control__control {
        margin-bottom: 0px;
      }

      div.andes-form-control__bottom {
        min-height: auto;
        margin-top: $cow-spacing-4;
        span.andes-form-control__message {
          line-height: $cow-font-12;
          min-height: auto;
        }
      }
    }

    .cow-card-switch > .andes-card--flat > .andes-card__content {
      padding: 16px 32px;

      div.andes-thumbnail-container {
        margin-right: 24px;

        div {
          width: 40px;
          height: 40px;
        }
      }
    }

    .layout--modal {
      .layout__col-sidebar {
        height: 100%;
        background-color: $andes-white;
        padding-top: em(52);

        .sidebar--default {
          height: 100%;

          .cow-payment_summary--desktop {
            height: 100%;
          }
        }
      }

      .brand {
        padding: em(24) em(20) 0;
        background-color: $andes-white;
      }
    }

    .layout--new-interface {
      align-items: flex-start;
      display: flex;

      .cow-navbar .cow-navbar__content .user-info {
        align-items: center;
      }

      .layout__col-content {
        max-width: em(876, 16);

        .top-row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          margin-bottom: 20px;
          justify-content: space-between;
          align-items: center;

          .c-title {
            margin: 0;
          }

          .discount--trigger {
            margin: 0;

            .andes-button__content {
              font-size: $cow-font-16;

              svg {
                width: em(20);
                height: em(20);
                max-height: em(20);
                max-width: em(20);
                margin-left: 12px;
              }
            }

            button {
              height: em(43);
              border: none;
            }
          }

          .coupon-button {
            margin-bottom: 0;

            span {
              color: $andes-accent-color;

              path {
                stroke: none !important;
              }
            }
          }
        }

        .message__free-trial {
          margin-bottom: em(26);
        }

        // SPEI Message
        #card-option__spei {
          margin-top: $cow-spacing-32;
          margin-bottom: $cow-spacing-32;
        }

        .content-row {
          .guest-subtitle {
            margin: 4px 0 16px 0;
          }
          .card-list {
            // Override CardOption Paddings
            #clabe-row.with-bottom-row .card-option__top-row {
              padding: em(24) em(32) em(22) em(32) !important;

              .card-option__content {
                padding: 0 !important;
              }
            }

            /* == Pix Row == */
            // Override CardOption Paddings
            #pix-row.with-bottom-row .card-option__top-row {
              padding-top: em(16) !important;

              .card-option__content {
                padding: 0 !important;
              }

              .card-option__disclaimer {
                .icon-wrapper {
                  width: em(15);
                  height: em(12);

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            // Pix Row
            #pix-row {
              .card-option__main-row {
                .cow-icon-wrapper {
                  margin-left: 2rem;
                }

                .card-option-cta {
                  margin-right: 2rem;
                }
              }

              .card-option__disclaimer {
                width: 100%;
                height: em(40);
                display: flex;
                align-items: center;
                flex-direction: row;
                background-color: $cow-white !important;
                border-top: 1px solid $cow-medium-gray !important;
                border-radius: 0 0 0.375em 0.375em !important;
                margin-top: 1rem;

                .andes-card__content {
                  display: flex;
                  align-items: center;
                  gap: $cow-spacing-8;
                  padding: 0 1rem;

                  .icon-info {
                    display: none;
                  }

                  .icon-info {
                    display: none;
                  }

                  .text {
                    color: $andes-gray-550-solid;
                    font-size: $cow-font-12;
                  }
                }
              }
            }

            // Installments
            #installments-quantity-row {
              .cow-icon-wrapper {
                color: $andes-accent-color;
              }
            }

            .cow-icon-wrapper {
              border: 1px solid $cow-gray-070;
            }

            .card-option-cta {
              margin: 0;
              color: $andes-accent-color;
              font-size: $cow-font-14;
              font-weight: $font-weight-semibold;
            }

            .cow-icon-wrapper {
              &:has(.icon-account-money-yellow) {
                border: none;
              }

              // Mercado Pago Black icon
              svg.icon-mercado-pago-black,
              svg.icon-account-money-yellow {
                width: 100%;
                height: 100%;
                max-width: fit-content !important;
              }

              svg {
                color: $andes-accent-color;
              }
            }

            .card-option:last-of-type {
              margin-bottom: $cow-spacing-24;
            }
          }

          &:has(.card-credits) {
            .card-option:last-of-type {
              margin-bottom: $cow-spacing-16;
            }
          }

          .andes-card.payment-method-card-list .andes-radio-list {
            .andes-list__item.radio-list-item-desktop {
              padding-bottom: $cow-spacing-24 !important;
            }

            .andes-accordion-panel-transition [data-andes-accordion-panel] {
              padding: $cow-spacing-16 $cow-spacing-32 $cow-spacing-24 !important;
            }
          }

          .andes-card {
            > :first-child {
              border-bottom-left-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }

          .andes-form-control__label {
            line-height: 18px;
            margin-bottom: $cow-spacing-4;
            margin-left: 6px;
            position: relative;
          }

          .andes-dropdown__trigger {
            margin: 4px 0px;
          }

          .card-credits {
            margin-top: em(16);
            margin-bottom: 24px;

            .cow-icon-wrapper {
              border: 1px solid $cow-gray-070;
            }
          }

          .message__payment-validation {
            margin-top: em(16);
          }

          .cow-back-link {
            margin-top: 24px;

            &:hover {
              opacity: 0.8;
              color: $andes-blue-500;
            }
          }

          .radio-list-item-desktop {
            .icon_account_money_yellow,
            .icon_mercado_credito_yellow {
              border: none;
            }
          }

          .card-taxes-mla {
            padding: 0;
            margin-top: $cow-spacing-20;

            &__cost {
              padding: 0;
              font-weight: $cow-font-weight-bold;
            }

            &__total {
              padding: 0;
              margin-top: $cow-spacing-24;
            }
          }

          .bank_interests {
            margin-bottom: $cow-spacing-32;
          }
        }

        .collapsible {
          margin-bottom: em(16);

          &--closed {
            margin: 0;
          }
        }

        .cow-legals {
          padding: em(16);
          justify-content: center;
        }

        .cow-captcha-terms {
          justify-content: center;
          margin: 32px 0 24px;
          margin-top: em(32, 16);

          a {
            color: $andes-blue-500;
            transition: all 150ms ease-in-out;
            text-decoration: none;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .cow-terms-and-conditions--terms {
          a {
            color: $andes-blue-500;
            transition: all 150ms ease-in-out;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        &:has(.rebranding) {
          .rebranding .cow-icon-wrapper {
            svg {
              color: $andes-text-color-primary;
            }
          }
        }
      }

      .layout__col-sidebar {
        padding-top: em(112);

        // TODO: USE COW PREFIX IN BRAND COMPONENT
        .brand {
          .andes-thumbnail img {
            all: unset;
            height: 100%;
            width: 100%;
            object-fit: contain;
          }

          .brand__name {
            font-size: em(20);
          }
        }

        .payment-summary__bottom-content {
          width: 100%;
        }

        .cow-payment_summary--desktop {
          .cow-terms-and-conditions {
            margin-top: $cow-spacing-16;

            .cow-terms-and-conditions--terms {
              text-align: center !important;
            }

            & + .cow-payment_summary__button {
              margin-top: 0;
            }
          }

          .cow-payment_summary__button {
            margin-top: $cow-spacing-16;
          }
        }
      }

      // === DISCOUNT MODAL ===

      .andes-form-control--textfield .andes-form-control__label {
        margin: 0 6px 4px;
        line-height: 18px;
      }

      .discount-modal {
        width: 70vw;
        max-width: initial !important;

        .generic-modal__content {
          color: #999;

          // TODO: REMOVE THIS AND FIX THE text-align defined in the generic-modal of the COW Core Components lib
          text-align: unset !important;
        }
      }

      // === CAPTCHA ===
      .cow-captcha__container {
        text-align: center;

        &--guest {
          .text {
            margin: em(12, 14) auto;
            margin-bottom: em(-12, 14);
            font-size: em(14);
            color: $andes-error-red;
          }
        }

        &--logged {
          .text {
            margin: em(12, 14) auto;
            font-size: em(14);
            color: $andes-error-red;
          }
        }

        #g-recaptcha {
          display: flex;
          justify-content: center;
          transform: scale(0.73);

          iframe {
            display: unset;
          }
        }
      }

      // TODO: MOMENTOUSLY SOLUTION FOR USE THE ANDES MoneyAmount COMPONENT WITH THE CURRENT DATA STRUCTURE FROM FLOWS
      .andes-money-amount__currency-symbol {
        display: none;
      }

      .andes-money-amount__cents {
        margin-top: 0 !important;
      }

      // == Coupon Collapsible Animation ==
      .content {
        .content-row--animated {
          position: absolute;
          z-index: 3;
          width: 100%;
          background-color: $cow-gray-070; // Background color of the page
          animation-duration: 0.5s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;

          // TODO: Find a better way
          min-height: em(230);

          .cow-button-back-to-site {
            margin-top: $cow-spacing-24;
          }
        }
      }

      .slide-down {
        animation-name: slideDown;
      }

      .slide-up {
        animation-name: slideUp;
      }

      @keyframes slideUp {
        0% {
          transform: translateY(400px);
          opacity: 0;
          display: none;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
          display: block;
        }
      }

      @keyframes slideDown {
        0% {
          transform: translateY(0);
          opacity: 1;
          display: block;
        }

        100% {
          transform: translateY(400px);
          opacity: 0;
          display: none;
        }
      }
    }

    // Show Pay Button above the loader
    .loader-wrapper {
      z-index: 100 !important;
    }

    // === DISCOUNT MODAL ===
    .discount-modal {
      width: 70vw !important;
      max-width: unset !important;

      .generic-modal__content {
        color: #999;

        // TODO: REMOVE THIS AND FIX THE text-align defined in the generic-modal of the COW Core Components lib
        text-align: unset !important;
      }
    }

    // === PAYMENT SUMMARY ===
    .cow-payment_summary--default {
      .cow-payment_summary__button {
        &:hover {
          background-color: $andes-blue-600 !important;
        }
      }
    }
  }
}
