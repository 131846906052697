.c-title {
  margin-top: $cow-spacing-8;
  margin-bottom: $cow-spacing-32;
}

.form-actions {
  margin: $cow-spacing-24 0;
  padding-bottom: $cow-spacing-48;
}

.andes-message {
  margin-top: $cow-spacing-16;
}

.stepper + .rebranding {
  &__title {
    margin: $cow-spacing-12 0 $cow-spacing-16 0;
  }
}

.stepper {
  margin-bottom: 0;
}