#group_user_header_ml {
  .user-info {
    align-items: center;
    &__text {
      color: $cow-black;
    }
  }
  .icon-wrapper.icon-more-options {
    margin-left: 0;
  }
}

.user-info {
  display: flex;
  font-size: $cow-font-14;
  line-height: em(28, 14);

  &__text {
    display: flex;
    color: $cow-translucent-medium-gray;
  }

  .user-avatar {
    margin-left: em(10, 14);
  }

  .ui-avatar__content {
    width: em(28);
    height: em(28);
  }

  .icon-wrapper {
    width: em(28, 14);
    height: em(28, 14);
    margin-left: em(8, 14);
  }
}
