@import "shared";

#group_switch_alternative_payment_method .ui-card {
  padding: 24px 32px;
  .icon-account-money-yellow {
    width: 40px;
  }
}

.group-summary {
  background: white;

  .group-summary__container {
    #pay {
      margin-top: 32px;
    }
  }
}

.layout__col-content {
  .title-h2 {
    margin: 0 0 em(12) 0;
    font-weight: $cow-font-16;
  }

  .ui-card {
    padding: em(32);

    &.options-list {
      padding: 0;
    }

    form {
      display: flex;
      align-items: center;
    }
  }

  .andes-dropdown {
    margin: 0;
  }

  & > .group-generic {
    display: flex;
    align-items: center;
    margin-bottom: em(10);
    margin-top: em(-20);

    .bank-deals-link,
    .toggle-button-wrapper {
      position: unset;
      margin: 0;
    }

    span {
      padding-left: em(15);
      padding-right: em(15);
    }
  }
}

#group_pay {
  .group-generic {
    display: flex;
    flex-wrap: wrap;

    .icon-wrapper {
      vertical-align: middle;
      margin-right: em(18);
      width: em(40);
    }
  }

  .ui-card {
    overflow: visible;
    min-height: em(112);
    padding: em(20) em(32);

    .andes-message--quiet {
      margin-top: em(38);
    }
  }

  // cvv
  .row_cvv {
    max-width: em(296);
    padding: em(20) 0 em(20) em(32);
  }
}

.group-generic {
  .select-additional-info {
    margin-top: em(6);
  }
}

// Input Cvv
.group-row {
  min-height: em(74);
}

.layout--modal {
  .layout__col-sidebar {
    background: $cow-white;

    .andes-button.andes-button--loud {
      margin-bottom: em(12);
    }
  }

  .c-title ~ .group-row + .group-row {
    max-height: em(74);
  }

  .c-title {
    margin-bottom: 32px;
  }

  @media (width <=832px) {
    .bank-deals-link {
      margin: em(-20, 14) 0 em(8, 14);
    }
  }
  // Legacy styles
  .optimus {
    .layout__col-sidebar {
      background-color: $cow-white;
      .group-total-summary {
        #pay {
          position: static;
          margin-top: 20px;
        }
      }
    }

    .layout__col-content {
      &:has(.group-total-summary) {
        .scroller {
          height: auto;
        }
      }

      .group-total-summary {
        .aspirational {
          margin-right: 10px;
        }
      }
    }
  }
}

.animated-content .group_form .ui-card {
  margin-top: 0;
}

// Experiment row
#change_payment_form {
  ul > li > .options-list__label {
    padding: 24px 32px;
  }

  .andes-button .andes-button__content {
    white-space: nowrap;
  }
}

.toggle-button-wrapper {
  .toggle-button {
    span.andes-button__content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.step_express .row_cvv .icon-wrapper,
.row_cvv .icon-wrapper {
  margin-right: em(20);
}

div.cow-secure-fields {
  width: inherit;
}

#installments_group_generic {
  .select-installments {
    max-width: 100%;
  }
}

.layout--modal {
  #installments_group_generic {
    .select-installments {
      max-width: em(335);
    }
  }
}

/** Styles for express combination */

#title_combination {
  margin-top: 27px;
  margin-bottom: 8px;
}

.payment-detail {
  &__row {
    margin-bottom: em(22, 16);
  }
}

#group_form_combination {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: em(24);

  .payment-method-info {
    flex-basis: 100%;

    .description {
      padding: 32px 32px 16px;
    }

    order: 1;
  }

  #installments_row {
    order: 3;
    width: 60%;

    .ui-card {
      padding: 18px 32px 32px 8px;
      border-bottom-right-radius: em(6, 16);
      height: 100%;
      margin-bottom: 0;
    }

    &:last-of-type {
      width: 100%;

      .ui-card {
        border-bottom-left-radius: em(6, 16);
        padding-left: em(24, 16);
      }
    }
  }

  .options-list.ui-card.installments_row {
    order: 3;
    margin-bottom: 0;
    border-radius: 0 0 em(6) 0;
    width: 60%;
    box-shadow: unset;
    padding-top: em(27);
  }

  #cvv_row {
    order: 2;
    width: 40%;

    .ui-card {
      height: 100%;
      padding: 14px 8px 32px 32px;
      border-bottom-left-radius: em(6, 16);

      .row_cvv {
        div.cow-secure-fields {
          min-width: 100%;
        }
      }
    }
  }

  // border case when installment is not exist
  .payment-method-info + #cvv_row {
    .ui-card {
      padding-left: em(24, 16);
      padding-right: em(24, 16);
      border-bottom-right-radius: em(6, 16);
    }
  }
}

.button_back_default {
  margin-right: 1em;
  float: right;
}

#pay {
  min-width: unset;
}

#group_sidebar_combination {
  padding-top: em(88, 15);

  .group-summary {
    background-color: $cow-lighter-gray;
    padding: 32px 24px;
    border-radius: em(6);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);

    .title-h2 {
      margin-top: em(10);
      margin-bottom: em(16);
      font-size: $cow-font-16;
      font-weight: $cow-font-weight-semibold;
      color: $cow-translucent-dark-gray;
    }

    .row-summary {
      font-size: $cow-font-16;
      font-weight: $cow-font-weight-regular;
      color: $cow-translucent-dark-gray;
    }
  }
}

.payment-detail__content {
  padding-left: em(24);
  padding-right: em(24);
}

// combination modal
.layout--modal {
  .layout__col-content {
    #title_2_combination + .payment-detail__row + .scroller {
      height: auto;
      margin-bottom: 0;
    }

    .andes-button.andes-button--loud {
      bottom: 0;
    }
  }

  #group_sidebar_combination {
    padding-top: 0;

    .group-summary {
      padding-top: 72px;
    }
  }
}

.layout--redirect,
.layout--embed {
  #shield_footer {
    font-weight: $cow-font-weight-semibold;
  }

  .row-summary {
    font-size: $cow-font-16;
    font-weight: $cow-font-weight-regular;
    color: $cow-translucent-dark-gray;

    &--pay {
      margin-bottom: 0px;
    }

    &--pay {
      font-weight: $cow-font-weight-semibold;
    }
  }

  #pay {
    margin-bottom: 0px;

    + .text.gray {
      margin-top: 12px;
    }
  }
}

.layout__col-sidebar {
  .group-total-summary {
    .group-summary {
      background-color: $cow-white;

      .title-h2 {
        font-size: $cow-font-16;
        font-weight: $cow-font-weight-semibold;
      }

      #pay {
        + .text.gray {
          color: $cow-translucent-medium-gray;
          font-weight: $cow-font-weight-semibold;
        }
      }
    }
  }
}
