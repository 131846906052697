.avatar {
  position: relative;
  display: flex;
  align-items: center;
  width: em(90);
  height: em(90);
  background: $cow-white;
  border-radius: 50%;

  .icon-wrapper {
    margin: 0 auto;
    font-size: em(24);
  }

  .icon-credit-card {
    display: block;
    width: em(40);
    height: em(25);
  }

  .icon-shopping {
    display: block;
    width: em(28);
    height: em(27);
    font-size: em(28);
    margin: 0 auto;
  }

  .icon-wallet {
    display: block;
    width: em(44);
    height: em(42);
    margin: 0 auto;
  }

  .icon-account-money {
    display: block;
    width: em(21);
    height: em(32);
    margin: 0 auto;
  }

  .icon-error-badge,
  .icon-warning-badge,
  .icon-check-badge,
  .icon-instructions-badge {
    position: absolute;
    bottom: 0;
    right: em(-8);
    width: em(30);
    height: em(30);
  }
}
