.user-menu {
  position: relative;
  display: inline-block;

  &__button {
    color: $cow-dark-gray;
    font-size: em(12);
    border: 0;
    background: none;
    border-radius: em(4, 12);
    padding: em(4, 12);
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: $cow-dark-gray;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.21429em rgb(0 158 227 / 15%);
    }

    span {
      position: relative;
      top: -3px;
      display: inline-block;
      width: 7px;
      height: 7px;
      border-bottom: 2px solid $cow-dark-gray;
      border-right: 2px solid $cow-dark-gray;
      transform: rotate(45deg);
      margin-left: 8px;

      &.chevron-up {
        top: em(-1, 12);
        transform: rotate(225deg);
      }
    }
  }

  &__popup {
    position: absolute;
    top: em(38);
    right: em(-15);
    z-index: 9;
    width: em(254);
    background: $cow-white;
    border-radius: em(4);
    box-shadow: 0 0 em(20) 0 $cow-translucent-dark-gray;
    animation: fade-in 70ms ease-in;

    &::before {
      position: absolute;
      top: em(-6);
      right: em(12);
      width: em(12);
      height: em(12);
      background: $cow-white;
      display: block;
      transform: rotate(45deg);
      content: "";
    }
  }

  &__section {
    padding: em(16);
    border-bottom: 1px solid $cow-gray-070;

    &:last-child {
      border: 0;
    }
  }

  &__header {
    font-size: em(12);
    color: $cow-dark-gray;
  }

  &__logo-mp {
    float: left;
    margin-right: em(16);
    margin-top: em(4);
  }

  .icon-mercado-pago {
    width: em(32, 12);
    height: em(22, 12);
  }

  &__mp-text {
    overflow: hidden;
  }

  &__avatar {
    position: relative;
    display: block;
    width: em(48);
    height: em(48);
    margin: 0 auto em(12);
    background: $cow-dark-gray;
    border-radius: 50%;
    text-align: center;

    img {
      position: absolute;
      top: em(1);
      left: em(1);
      display: block;
      width: em(46);
      height: em(46);
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__initials {
    font-size: 20px;
    line-height: em(48, 20);
    color: $cow-white;
    text-align: center;
  }

  &__username {
    display: block;
    font-size: em(14);
    line-height: 1.1;
    color: $cow-black;
  }

  &__user-info {
    text-align: center;
    padding-top: em(24);
    padding-bottom: em(24);
  }

  &__footer {
    text-align: center;
  }

  &__email {
    font-size: em(12);
    line-height: 1;
    color: $cow-dark-gray;
  }
}
