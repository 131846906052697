@import "shared";

.card-form-group {
  .andes-form-control {

    // Just apply to mozilla browsers
    @-moz-document url-prefix() {
      &__control {

        #input_expiration_date {
          width: 90%;
        }
      }
    }
  }
}