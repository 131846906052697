.technical_error {
  text-align: center;
  width: 100%;

  .technical_error__error-screen {
    background-color: transparent;
  }

  .icon-wrapper {
    width: auto;
  }
}
