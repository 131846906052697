.form_address_info {
  padding: $cow-spacing-16;

  .form_address_field {
    margin-bottom: $cow-spacing-32;

    &:last-child {
      margin-bottom: 0;
    }

    .form_address_info_modal_button {
      margin: 0;
      padding: $cow-spacing-24 $cow-spacing-16;
      font-size: $cow-font-14;
    }
  }

  .form_address_select {
    margin-bottom: $cow-spacing-24;

    select {
      cursor: pointer;
    }
  }

  .form_address_number_field {
    .checkbox-in-textfield {
      margin-right: $cow-spacing-12;
    }

    .andes-checkbox__input {
      margin: 0;
    }

    .andes-checkbox__label {
      font-size: $cow-font-14;
      margin-right: $cow-spacing-8;
    }

    .andes-form-control__field {
      width: inherit;
    }
  }

  .form_address_double_field {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $cow-spacing-32;

    .form_address_field {
      width: 100%;
    }
  }

  .andes-form-control__embedded {
    user-select: none;
  }
}
