.toggle-button-wrapper {
  display: flex;
  align-items: center;

  .toggle-button {
    font-size: em(14);
    margin-right: em(5, 14);
  }

  .toggle-button:hover {
    background-color: transparent;
  }

  .icon-wrapper {
    width: em(12);
    min-width: unset;
    transform: rotate(90deg);

    svg {
      color: $cow-light-blue;
    }
  }

  &.toggle-show {
    .icon-wrapper {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
