@import "shared";

.layout__col-sidebar {
  margin-top: em(60);
  padding-top: em(96);
}

.payment-method-info {
  box-shadow: 0 em(1) em(2) 0 rgba(0, 0, 0, 0.12);
}

.group-summary {
  background-color: $cow-white;
  padding: em(24);

  &__container {
    .title-h2 {
      margin-top: 0;

      +hr {
        margin-top: em(22);
        margin-bottom: em(16);
      }
    }

    .row-summary {

      &__text,
      &__price {
        font-size: $cow-font-16;
        font-weight: $cow-font-weight-regular;
        color: $cow-translucent-dark-gray;
      }

      &__price {
        margin-left: em(4);
      }

      &--pay {
        .row-summary {

          &__text,
          &__price {
            font-size: $cow-font-16;
            font-weight: $cow-font-weight-semibold;
            color: $cow-translucent-dark-gray;
          }
        }
      }
    }

    #group_pay {
      #pay {
        margin-top: em(16);
      }
    }

    hr {
      margin: em(24) 0;
    }

    #shield_footer {
      margin-top: em(4);
    }
  }

  box-shadow: 0 em(1) em(2) 0 rgba(0, 0, 0, 0.12);
  border-radius: em(6);

  .group-summary__container {
    .tyc_consumer_credits {

      a:visited,
      a:active,
      a:hover,
      a:link {
        text-decoration: none;
        color: $andes-accent-color;
      }
    }
  }
}

#summary-list-accordion {
  &+.row-summary {
    margin-right: em(24);

    &+hr {
      margin-top: em(16);
    }
  }
}

.layout--modal {
  #group_sidebar {
    padding-top: 0;

    .group-summary {
      padding-top: 72px;

      #pay,
      #shield_footer {
        position: static;
        margin-top: 2px;
      }
    }
  }
}

//Rebranding OFF
&.legacy-mp-theme {
  .layout--modal {
    #group_sidebar {
      .group-summary {
        #shield_footer {
          padding-top: 4px;
        }
      }
    }
  }
}
