.no-payment-option {
  padding: em(32);
}

.no-payment-option .title-h2 {
  margin-bottom: em(32, 20);
  font-size: em(20);
  font-weight: 400;
  line-height: 1.1;
}

.no-payment-option .andes-button {
  float: none;
}

.andes-button--quiet {
  margin: 0 auto;
  display: block;
}

.select_mp_cards + .select_credit {
  min-height: em(76);
  margin-top: em(-19);
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  li.item-consumer_credit_row {
    border-top: em(1) solid $cow-medium-gray;
    margin-top: em(2);

    div.row-details {
      position: relative;
      top: em(-1);
    }
  }
}

#options-title-guest {
  flex-direction: column;
  padding-top: $cow-font-16;
}

.modal-content.group-modal {
  .group-flex {
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2.title-h3 {
      font-weight: $cow-font-weight-semibold;
    }

    .title-h2 {
      font-weight: $cow-font-weight-regular;
    }
  }
}

.andes-message {
  margin-bottom: em(16);
}

.item-mp_login_row,
.item-opensea_credits_row,
.item-mp_login_credits_row {
  label .group-media-object :nth-child(3) {
    top: em(-4);
    left: 0;
    width: 100%;
    display: flex;
    position: absolute;
    flex-direction: row-reverse;

    .andes-badge {
      padding: em(1);

      .andes-badge__content {
        font-weight: 800;
        text-transform: uppercase;
      }
    }
  }
}

.item-mp_login_row {
  label .group-media-object div:nth-child(2) {
    .row-details :nth-child(2) {
      color: rgba($cow-black, 0.55);
      padding-top: em(4);
      font-size: em(14);
    }

    .row-details div.title_with_bpp {
      svg {
        padding: 0;
        margin-left: em(8);
        margin-bottom: em(2);
        vertical-align: middle;
        font-size: unset;
      }
    }

    :nth-child(1) {
      font-weight: $cow-font-weight-regular;
    }
  }

  label .group-media-object div:nth-child(3) {
    .andes-badge {
      .andes-badge__content {
        text-transform: unset;
        display: flex;
        align-items: center;
        column-gap: em(5);
        padding: em(4) em(6) em(4) em(10);
      }
    }
  }
}

.item-wallet_qr {
  .row-details {
    .title {
      margin-bottom: em(4);
    }
  }

  .andes-badge {
    .andes-badge__content {
      text-transform: uppercase;
      font-weight: bold;
      font-size: em(14);
      color: $cow-white;
    }
  }
}

.options-list__item.item-wallet_qr.icon-full {
  label.options-list__label:nth-child(2) {
    padding: 0;
  }
}

& .icon-wrapper .icon-mercado-pago:not(.login-incentive-mp-logo) {
  max-width: em(24);
  max-height: em(24);
}

.options-list__item.item-clabe {
  .andes-badge--accent--quiet .andes-badge__content {
    color: $cow-white;
  }
}

.options-list__item .group-media-object .andes-badge.andes-badge--neutral {
  background-color: $andes-accent-color;
}

// Set Badges Positions
.options-list__item .group-media-object {
  .andes-badge {
    margin-top: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.select_bank_open_finance {
  .group-media-object div:nth-child(3) .andes-badge {
    margin-top: 0;
  }
}
