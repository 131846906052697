.rebranding {
  &__title {
    margin: $cow-spacing-32 0 $cow-spacing-32;
    h1 {
      font-size: $cow-font-20;
      font-weight: $cow-font-weight-semibold;
    }
    
    &_h2 {
      margin-top: $cow-spacing-32;
      margin-bottom: $cow-spacing-16;
      line-height: $cow-font-24;
      h2 {
        font-size: $cow-font-18;
        font-weight: $cow-font-weight-semibold;
        line-height: $cow-font-22;
      }
    }  
  }
}

#extra-info{
  margin-top: $cow-spacing-16;
}

.terms-link {
  color: $andes-accent-color;
}
