.shipping-user-type-container {
  margin: $andes-spacing-24 0;

  .shipping-rebranding-content {
    margin: $andes-spacing-24 0;
  }

  .is-bpp-option .andes-list__item-action {
    margin-top: $andes-spacing-8;
  }

  .is-bpp-tag {
    position: absolute;
    top: 0;
    right: -1px;

    .andes-badge__content {
      display: flex;
      align-items: center;
      gap: $andes-spacing-4;
    }
  }

  .shipping-icon-user {
    color: $andes-accent-color;
  }
}
