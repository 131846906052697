@import "~@andes/common/variables";

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out-overlay {
  0% {
    opacity: 1;
    z-index: 1;
  }

  99% {
    opacity: 0;
    z-index: -1;
  }

  100% {
    opacity: 0;
    z-index: -100;
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes skeleton-animation {
  0% {
    background-color: $cow-translucent-lighter-gray;
  }

  50% {
    background-color: $cow-translucent-medium-gray;
  }

  100% {
    background-color: $cow-translucent-lighter-gray;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@mixin loader-step-1 {
  transform: scaleX(0.9);
  transition: transform 60s cubic-bezier(0, 0.56, 0.76, 0.96);
}

@mixin loader-step-2 {
  transform: scaleX(1);
  transition: transform 250ms ease-in;
}
