.blocked-popups-container {
  padding-top: em(24);
  padding-bottom: em(16);
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.icon-blocked-popups {
  margin-bottom: em(20);

  &.icon-wrapper {
    width: unset;
  }
}

h1 {
  width: em(220);
  font-size: $cow-font-20;
  font-weight: $cow-font-weight-semibold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $cow-translucent-dark-gray;
  margin: 0 auto;
}

h2 {
  width: em(270);
  font-size: $cow-font-16;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $cow-translucent-dark-gray;
  margin: em(14) auto em(40) auto;
}

a {
  font-size: $cow-font-16;
  font-weight: $cow-font-weight-semibold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: $andes-accent-color;
  margin: 0 auto;
}
