@import "shared";

.layout.layout--modal {
  .layout--new-interface {
    .layout__col-sidebar {
      height: 100%;
      background-color: $andes-white;

      .brand {
        position: static;
      }

      .sidebar--default {
        height: 100%;

        .cow-payment_summary {
          height: 100%;

          .andes-card__content {
            background-color: $andes-white;
          }
        }
      }
    }
  }
}

.options-combinations {
  .andes-card.payment-method-card-list {
    .andes-list {
      .andes-list__item {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
      }
    }
  }

  .payment-detail__row {
    .payment-detail__content {
      padding: 16px 32px;
    }
  }
}

.actions-payment-option-combination {
  margin-top: 24px;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.layout__col-footer {
  margin-top: 40px !important;
  min-height: 1px;
}

.layout__col-content {
  margin-bottom: 56px;
}
