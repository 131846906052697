@import "shared";

.user-header {
  &__content {
    margin: 0 auto;
    padding: em(16) 0;
    max-width: em($layout-max-width); // same max-width as layout
  }
}

.layout.layout--modal {
  .user-header, .cow-navbar-v2 {
    &__content {
      margin: 0 auto;
      padding: em(16) em(32);
      max-width: em($layout-max-width); // same max-width as layout
    }
  }
}
