.andes-message--quiet {
  background: $andes-bg-secondary;
}

div.optimus div.aspirational {
  margin-bottom: em(26);
}

.group-row + .group-generic .aspirational.andes-message--neutral {
  margin-top: em(32);
}
