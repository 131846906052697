#shield_footer.group-generic {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: em(12);

  span {
    font-size: $cow-font-12;
    color: $cow-translucent-medium-gray;
    font-weight: 600;
  }

  .icon-wrapper {
    width: em(16);
    margin-right: em(4);
  }
}
