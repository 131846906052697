@import "shared";

.zipcode-finder {
  .ui-card-wrapper > .ui-card {
    display: flex;
    flex-direction: column;
    gap: em(12);


    & > .group-flex {
      .andes-autocomplete {
        width: 100%;

        .andes-form-control--error {
          margin-bottom: em(17);
        }
      }
    }
  }


  .andes-dropdown.andes-dropdown--form .andes-dropdown__popover {
    top: 0 !important; // required to avoid overflow offset when selecting an option
  }

  .andes-autocomplete {
    .andes-dropdown > .andes-dropdown__popover {
      max-height: em(120);
    }
  }
}
