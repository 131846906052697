.cow-accordion {
  &, &--blocked {
    margin-bottom: em(16);
  }

  &__title--hide-chevron {
    .row-collapsible__title {
      margin-right: 0;
    }
  }
}

.row-collapsible {
  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: em(8);
    line-height: em(18);

    &__label {
      word-break: break-word;
    }

    &__value {
      flex-shrink: 0;
    }

    .andes-typography {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  &__content {
    display: flex;
    padding: em(11) 0 0 em(20);
    gap: em(11);
    flex-flow: column nowrap;
    width: 100%;
    line-height: em(18);

    &__row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .andes-typography {
        color: rgba(0, 0, 0, 0.55);
      }

      &__label {
        display: flex;
        align-items: center;

        .icon-with-tooltip {
          display: flex;
          margin-left: em(4);
        }
      }
    }
  }
}
