.redirect-view .layout-mp-brand {
  display: none;
}

.layout-main .title-h2 {
  margin-bottom: em(16);
  font-size: em(16);
  font-weight: 600;
  line-height: 1.3;
}

// Promos link
.layout__col-content .bank-deals-link {
  display: block;
  font-size: em(14);
}

.centered {
  text-align: center;
}

@media (max-width: em(320)) {
  #group_generic_links {
    flex-direction: column;

    .bank-deals-link {
      margin: em(10) 0;

      &+p.text {
        display: none;
      }
    }
  }
}

.sandbox-mode .layout,
.sandbox-mode .layout--modal .group-summary {
  background-image: url("../assets/images/sandbox-bg-es.png");
  background-repeat: repeat;
}

.sandbox-mode.site-mlb .layout,
.sandbox-mode.site-mlb .layout--modal .group-summary {
  background-image: url("../assets/images/sandbox-bg-pt.png");
}

.sandbox-badge {
  position: fixed;
  top: em(-70);
  left: em(-85);
  z-index: 999;
  display: block;
  width: em(190);
  height: em(160);
  padding-top: em(112);
  text-align: center;
  transform: rotate(-45deg);

  span {
    display: block;
  }

  &__title {
    font-size: em(18);
    font-weight: 600;
    line-height: 1.1;
    color: $cow-translucent-dark-gray;
  }

  &__text {
    font-size: em(12);
    color: $andes-accent-color;
  }

  &::after {
    position: absolute;
    top: em(-32);
    left: 0;
    z-index: -1;
    display: block;
    width: 0;
    height: 0;
    border-width: em(96);
    border-style: solid;
    border-color: transparent;
    border-bottom-color: $cow-yellow;
    box-shadow: -5px em(2) em(5) rgb(0 0 0 / 10%);
    content: "";
  }
}
//override to solve text clipping
.group-back-url .andes-button--link {
  padding-left: em(2);
}
