.button-back-arrow__link {
  .icon-wrapper {
    cursor: pointer;

    svg {
      path {
        fill: $andes-accent-color;
      }
    }
  }
}


.credits-conditions {
  padding: em(32);
  background-color: $cow-white;
  height: auto;

  iframe {
    height: 568px;
    display: block;
    border: 0;
  }

  .message__content {
    height: em(24);
    color: $cow-dark-gray;
    margin: em(21) 0;
    text-align: center;
  }
}

