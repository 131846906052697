.shipping-type-container {
  .local-pickup-icon-rebrand {
    color: $andes-text-color-primary;

    g {
      :nth-child(1) {
        fill: none;
        stroke: $andes-text-color-primary;
      }

      :nth-child(2) {
        stroke: $andes-text-color-primary;
        fill: none;
        stroke-width: 1.1;
        r: 4;
      }

      :nth-child(3) {
        stroke: $andes-text-color-primary;
        stroke-width: 1.1;
      }
    }
  }

  .shipping-icon {
    color: #009ee3;
  }
}
