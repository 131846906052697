@import "shared";

.status-bar {
  height: em(64);
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  &-developer {
    width: em(580);
    font-size: em(16);
    margin-top: em(124);

    .icon-warning-badge {
      top: em(94);
    }

    .button-group {
      .andes-button {
        &--loud {
          margin-right: em(8);
        }
      }
    }
  }
}
