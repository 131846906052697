@import "shared";

.layout__col-content {
  & > h1 {
    margin-top: 24px !important;
  }
}



.card-number-with-issuer__group {
  .icon-wrapper {
    margin-left: $cow-spacing-20;
    margin-top: em(25);
  }
}

// input icons vertical align center
.card-number-with-issuer__group,
.card-form-group .group-generic {
  #issuer_select {
    margin-left: em(16);
  }

  .icon-wrapper.icon-card-cvv {
    flex: 0 1 auto;
    padding: 0;
    margin: 0;
    margin-left: em(20);
    position: relative;

    > :first-child {
      position: absolute;
      margin: 0;
    }

    > div {
      top: em(32);
    }

    > svg {
      top: em(24);
    }
  }

  // When the card doesn't have CVV, we hide the CVV icon
  .icon-wrapper.icon-card-cvv:only-child {
    display: none;
  }
}

// Link de promos
.bank-deals-link {
  position: absolute;
  right: em(44);
  top: em(52);
  margin: 0;
}

.form.group_form {
  .ande-card {
    padding: em(24);
    margin-bottom: em(24);
  }
}

.card-form-group {
  display: flex;
  margin-top: 32px;

  .group-generic {
    flex: 1;
    width: 0;
    display: flex;
    flex-wrap: wrap;

    & > :nth-child(n) {
      flex: 1;
    }

    .input-cvv {
      max-width: 100%;
    }
  }

  .expiration-date-wrapper,
  #identification_generic_group,
  #installments_generic_group {
    .andes-dropdown {
      margin-right: em(12);
      .andes-dropdown__trigger {
        margin-bottom: 0;
      }
    }
  }

  .icon-wrapper,
  label {
    margin-right: em(12);
  }

  .group-generic#installments_generic_group {
    .select-installments {
      max-width: unset;

      .andes-dropdown {
        margin-right: 0;
      }
    }
  }

  & > :nth-child(2) {
    margin-left: em(11);
  }

  // Installment select
  #installments_select_id {
    max-width: 100%;
    flex: 1 1 100%;

    &.andes-dropdown--open {
      overflow: visible;
    }

    .andes-dropdown__trigger {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

// installments / document column view
.card-form-group:last-child {
  gap: 32px;
  flex-direction: column-reverse;

  .group-generic {
    width: 100%;
    padding-right: 0;
  }

  .group-generic:last-child {
    margin-left: 0;

    .input-document {
      margin-right: 0;
    }
  }
}

// document
.group-identification__children {
  .andes-dropdown {
    min-width: 22%;
  }
}

// Modal
.layout--modal {
  .scroller {
    height: em(400);
  }

  .user-header, .navbar--ml + .layout__col-content {
    // When user header is present there is less viewport in scroller
    .scroller {
      height: em(340);
    }
  }
}

.card-number-with-issuer__group.multiple-issuers #card_number {
  font-size: em(17);
}

.single-bin {
  > :first-child {
    .icon-wrapper {
      margin-right: 0;
    }
  }

  > :nth-child(2) {
    flex: 0;
    visibility: hidden;
  }
}

.icon-card-cvv {
  width: em(51);
  margin-right: em(-6);

  svg {
    width: em(51);
    flex: none;
    margin-left: $cow-spacing-20;
  }
}

.andes-button--link {
  margin-left: em(42);
}

.ui-card {
  overflow: visible;
}

#installments_select_id {
  .andes-list__item {
    display: flex;
  }
}

.scroller {
  // forms buttons
  > .group-generic {
    display: flex;
    margin-top: 24px;

    form {
      margin-left: auto;
      margin-right: 8px;

      .andes-button--link {
        padding: 0 24px;
      }
    }
  }
}

.layout--modal {
  .layout__col-content .scroller {
    top: em(128);
    margin-bottom: em(32);
  }
}

// Popover custom height & position
#installments_select_id > .andes-dropdown__popover {
  bottom: -20%;
  top: auto !important;
  max-height: em(250);
  overflow-y: scroll;
  overflow-x: hidden;
}

div[name*="year"] > .andes-dropdown__popover,
div[name*="month"] > .andes-dropdown__popover {
  max-height: em(300);
  top: em(-90) !important;
  overflow-y: scroll;
}

div[name*="identification_group_id"] > .andes-dropdown__popover {
  bottom: -20%;
  top: auto !important;
  max-height: em(250);
  overflow-y: scroll;
}

.ui-card {
  .card-number-with-issuer__wrapper:first-child,
  .card-form-group:not(:last-child),
  .card-form-group:last-child > .group-generic:last-child {
    margin-bottom: em(12);
  }
}
