.text {
  font-size: em(16);
  color: $cow-translucent-dark-gray;
  margin-bottom: em(24);
}

.group-generic-message {
  h1 {
    width: auto;
    font-size: em(20);
    margin: em(24, 20) auto;
    margin-bottom: em(12, 20);
  }

  h2 {
    width: em(317);
    margin: 0 auto;
    font-size: em(16);
  }

  .andes-button--loud {
    width: em(240, 16);
    margin: em(24, 16) auto;
    margin-bottom: 0;
    padding: 0;
  }

  .pref-expired,
  .pref-unavailable {
    & ~ .title-h3 {
      text-align: center;
    }
  }

  #button_close_modal_link {
    margin: 8px auto 0;
    width: 240px;
  }

}