*,
::before,
::after {
    box-sizing: border-box;
}

// Prevent font size adjustments after orientation changes in IE and iOS
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

// Remove the margin in all browsers
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    font-weight: 400;
}

// Removes browser box styles in fieldsets
fieldset {
    border: 0;
    padding: 0;
}

// Firefox: removes browser html5 validation styles
[required] {
    box-shadow: none;
}
