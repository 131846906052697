.captcha-terms {
  text-align: center;

  p {
    font-size: em(11);
    color: $cow-dark-gray;

    a {
      color: $cow-light-blue;
      text-decoration: none;
    }
  }
}
