.split-amount {
  display: flex;
  margin-bottom: em(40);

  > :first-child.icon-wrapper {
    width: em(40);
    height: em(40);
    border-radius: 50%;
    border: solid em(1) $cow-gray-070;
    margin-right: em(16);
    margin-top: em(25);
  }

  .icon-wrapper {
    svg {
      max-width: em(20);
      max-height: em(20);
    }
  }

  .payment-card-icon {
    zoom: 64%;
  }

  .andes-card__content {
    flex: 1;

    .split-amount__input_container {
      position: relative;
      flex: 1;
      margin-left: 0;

      // This is to remove dotted background of disabled status
      .andes-form-control--disabled .andes-form-control__border {
        background-image: none;
        background-color: transparent;
      }

      .split-amount__input {
        margin-bottom: 0;

        input.andes-form-control__field[disabled] {
          color: $cow-dark-gray;
        }
      }

      .text_promo {
        margin: 0;
        margin-left: em(6);
      }

      .andes-form-control--textfield {
        .andes-form-control__bottom {
          margin-left: em(6);

          .andes-form-control__message {
            font-size: 13px;
          }
        }
      }
    }

  }

  &.disabled {
    box-shadow: none;
    margin-bottom: 0;

    input.andes-form-control__field {
      color: $cow-translucent-dark-gray;
    }

    .andes-form-control__message {
      margin-top: 0;
    }

    .andes-form-control--textfield.andes-form-control--disabled .andes-form-control__label {
      color: $cow-black !important;
    }
  }
}

#group_pay {
  .ui-card.split-amount {
    //split of cards
    ~ .group-generic-block {
      margin-bottom: 0;

      .ui-card.split-amount.disabled.new-card-row {
        margin-bottom: 0;
      }

      + .ui-card {
        border-radius: 0 0 em(6) em(6);
      }
    }
  }
}
