@import "shared";

.layout--redirect,
.layout--embed {
  .modal-content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    .modal-wrapper {
      background-color: $cow-white;
      width: 70vw;
      box-shadow: 0 0 em(1) em(1) rgb(0 0 0 / 7%), 0 em(20) em(25) 0 rgb(0 0 0 / 15%);
      border-radius: em(4);
      color: $cow-translucent-dark-gray;

      .header {
        display: flex;
        margin-bottom: em(64);
        justify-content: space-between;
      }

      .cross {
        display: flex;
        flex-direction: column;
        justify-content: center;

        svg {
          align-items: center;
        }
      }

      .cross svg path {
        fill: black;
      }

      .arrow {
        display: none;
      }

      .title {
        font-size: em(24);
      }

      .content {
        font-size: em(14);
        padding: 0 em(32);
      }

      .inner-wrapper {
        padding: em(64);
      }
    }
  }
}

.layout--modal {
  .modal-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $cow-common-background;
    z-index: 99;
    color: $cow-translucent-dark-gray;

    .inner-wrapper {
      margin: auto;
      max-width: 70%;
    }

    .header {
      display: flex;
      margin-bottom: em(32);
      margin-top: em(48);
    }

    .arrow {
      display: flex;
    }

    .icon-back {
      align-items: center;
    }

    .title {
      font-size: em(32);
    }

    .content {
      background: $cow-white;
      padding: em(32, 14);
      font-size: em(14);
      color: $cow-translucent-dark-gray;
    }
  }
}

.modal-content.shield-modal {
  .modal-wrapper {
    max-width: em(500);
    padding: em(32) em(48);

    .inner-wrapper {
      background: url("../assets/images/kycshield.svg") no-repeat;
      background-size: 100% auto;
      background-position-y: 11%;
      padding: 0 0 em(16) 0;

      .header {
        margin-top: 0;

        .cross:hover {
          cursor: pointer;
        }
      }

      .group-flex {
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding-top: em(110);

        .title-h3 {
          font-weight: $cow-font-weight-semibold;
          font-size: $cow-font-24;
          color: $cow-translucent-dark-gray;
        }

        .title-h2 {
          font-weight: $cow-font-weight-regular;
          margin-top: em(8);
        }

        .kyc-why-link {
          font-size: $cow-font-14;
          font-weight: $cow-font-weight-semibold;
          color: $cow-lighter-blue;
        }

        .andes-button.andes-button--loud {
          margin-top: em(24);
          margin-bottom: em(8);
        }
      }
    }
  }

  &.without-icon .modal-wrapper .inner-wrapper {
    background: none;

    .group-flex {
      padding-top: 0;

      .title-h3 {
        margin: 0 auto;
      }
    }

    .header {
      margin-bottom: 0;
    }
  }
}

.step_opensea_credits_first_use {
  .layout--redirect {
    .modal-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .modal-wrapper {
        margin: calc((100vh - 32.25em) / 2) auto;

        .cross {
          display: none;
        }
      }
    }
  }

  .layout--modal {
    .modal-content {
      border-radius: em(6);
      background: transparent;

      .modal-wrapper {
        height: em(508);
        margin: em(24) auto em(34) auto;

        .cross {
          display: none;
        }
      }

      .inner-wrapper {
        max-width: unset;
      }
    }
  }
}

.step_opensea_credits_first_use {
  .layout--redirect .modal-content .modal-wrapper {
    width: em(480);
    max-width: 100%;

    .c-title {
      font-size: em(24);
    }

    .title-h2 {
      font-size: em(16);
      padding: 0 em(72);
      max-width: 100%;
      margin-bottom: em(8);
    }

    .inner-wrapper {
      .cross {
        display: grid;
      }

      .group_form {
        display: flex;
        justify-content: center;

        .andes-button {
          width: auto;
        }
      }
    }
  }
}

.step_express {
  .modal-content.shield-modal{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1020;
    background-color: rgba(0,0,0,.8);
    display: flex;
    justify-content: center;
    transform: none;

    .modal-wrapper{
      position: absolute;
      background-color: #fff;
      box-sizing: border-box;
      outline: none;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      button {
        min-width: em(220);
      }
    }
  }
}
