$margin-in-use-on-screen: 170px;

.shipping-address-form {
  .page-content {
    display: flex;
    flex-direction: column;
  }

  .page-content__mobile {
    justify-content: space-between;
    margin: $andes-spacing-24 0;
    height: calc(100vh - $margin-in-use-on-screen);
  }

  .page-content__desktop {
    margin: $andes-spacing-24 0;
  }

  .action__desktop {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
    margin-top: $andes-spacing-24;
  }

  .action__mobile {
    display: flex;
    gap: 10px;
    margin-top: $andes-spacing-24;
  }

  .content {
    .full-width {
      width: 100%;
    }

    .form-content {
      display: flex;
      flex-direction: column;
      gap: $andes-spacing-24;

      .form-content-group {
        display: flex;
        flex-direction: row;
        gap: $andes-spacing-24;
      }

      .form-content-group__desktop {
        display: flex;
        flex-direction: row;
        gap: $andes-spacing-24;

        input {
          width: 100%;
        }

        .andes-form-control__embedded {
          padding-right: $andes-spacing-12;
        }
      }

      .form-content-group__mobile {
        display: flex;
        flex-direction: column;
        gap: $andes-spacing-24;
      }
    }
  }

  .list-group {
    display: flex;
    flex-direction: row;
  }

  .residence-options {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }

    .icon-address-type-wrapper svg {
      height: 14px;
    }
  }

  .icon-rebrand {
    color: var($andes-accent-color);

    g {
      :nth-child(1) {
        fill: none;
        stroke: var($andes-accent-color);
      }

      :nth-child(2) {
        stroke: var($andes-accent-color);
        fill: none;
        stroke-width: 1.1;
        r: 4;
      }

      :nth-child(3) {
        stroke: var($andes-accent-color);
        stroke-width: 1.1;
      }
    }
  }

  .checkbox-in-textfield {
    display: flex;
    align-items: center;

    .andes-checkbox__checkbox {
      padding-right: 23px;
      margin: 0 !important;
    }

    .andes-checkbox__label-text {
      margin: 0 !important;
      padding: 4px;
    }
  }
}

.andes-radioGroup {
  display: flex;
}

.helper-street-number {
  margin-left: -17px;
  padding: 5px;

  span input {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 5px !important;
    height: 16px !important;
    width: 16px !important;
    appearance: auto !important;
  }
}
