.andes-money-amount__currency-symbol {
  display: none;
}

.layout__title {
  margin-top: 8px;
}

.content-split-amount {
  margin-top: 16px;

  & >.andes-card {
    .andes-list {
      padding: 0;

      .andes-list__item {
        padding: 0;

        .andes-list__item-first-column {
          padding: 0;

          .andes-thumbnail.icon_mercado_pago_black {
            img {
              min-width: 100%;
            }
          }
        }

        &:hover {
          background: none;
          cursor: auto;
        }
      }
    }

    .andes-card__content {
      .andes-form-control__control {
        margin-bottom: 0;
      }
    }

    .andes-card__footer {
      border-top: none;
      padding: 0;
      margin-top: 32px;

      .andes-card.split-amount {
        box-shadow: none;
        margin: 0;
      }

      .progress-container {
        margin-top: 24px;
      }
    }
  }
}
