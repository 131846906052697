.brand {
  display: flex;
  align-items: center;

  &__img {
    width: em(42);
    height: em(42);
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    box-shadow: 0 em(1) em(2) 0 $cow-translucent-light-gray;

    img {
      height: 100%;
      display: block;
    }
  }

  &__name {
    word-break: break-word;
    max-width: em(200);
    margin-left: em(8);
    font-weight: $cow-font-weight-semibold;
    line-height: 1;

    &__small {
      font-size: em(20);
    }

    &__large {
      font-size: em(18);
    }
  }

  .small {
    span {
      font-size: $cow-font-16;
      line-height: 3;
    }
  }
  .cow-brand {
    p {
      color: $andes-accent-color;
    }
  }
}

.legacy-mp-theme {
  .cow-brand {
    border: 1px solid $andes-accent-color;
  }
}
