@import "shared";

.options-list {
  margin: 0;
  margin-bottom: em(16);
  position: relative;

  &__label {
    padding: em(16) em(32);
  }

  &.group_payment_method {
    padding: 0;
  }

  .icon-wrapper {
    margin-right: em(20);
  }
}

.options-list__input:focus + .options-list__label {
  background: $cow-lighter-gray;
}

.options-list__label:hover,
.options-list__label:focus {
  background: $cow-lighter-gray;
}

.options-list__item {
  &.icon-full {
    height: unset;

    .options-list__label {
      padding-left: 0;

      .icon-wrapper {
        border: none;
        border-radius: 0;
        width: unset;
        height: unset;

        svg {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
