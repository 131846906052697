.layout {
  background-color: $cow-white;
  padding-top: em(150);
  height: 100vh;
  width: 100%;

  &.layout--modal {
    padding-top: 0;
  }

  .random-charge-callback-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
