@import "shared";

.sub_step_consumer_credits_tyc {
  .layout {
    .credits-conditions {
      height: em(460);

      &--particular {
        height: em(460);
      }

      &--message {
        height: em(122);
        padding: em(32);
      }
    }
  }
}
