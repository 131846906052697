.form_personal_info {
  padding: $cow-spacing-16;

  .form_personal_field {
    margin-bottom: $cow-spacing-32;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form_personal_double_field {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $cow-spacing-32;

    .form_personal_field {
      width: 100%;
    }
  }
}
