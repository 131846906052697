// Congrats header module
.congrats-header {
  color: $cow-white;
  padding: em(48) em(24) em(82);

  .price--wrapper {
    .amount-decimal-separator {
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }

    sup {
      vertical-align: top;
      font-size: em(12);
      line-height: 1.2;
      margin-left: em(3);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .price--wrapper {
    display: inline-block;
  }

  .congrats-titles {
    order: 2;
    margin-top: em(18);

    .c-title+.text+.group-flex {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: em(4);
      font-size: em(11);

      .icon-shield-bpp-white+p.text {
        font-weight: $cow-font-weight-semibold;
        margin-left: em(4);
      }
    }
  }

  .c-title {
    margin: em(4, 26) 0;
    font-size: em(26);
    font-weight: 600;
    line-height: 1.1;
    max-width: em(460, 26);
  }

  p {
    font-size: em(16);
    color: $cow-white;
  }

  .payment-card-avatar {
    order: 1;
  }
}

// Congrats content module
.congrats-content {
  margin-top: em(-52);

  .title-h3 {
    margin-bottom: em(8, 20);
    font-size: em(20);
    line-height: 1.4;
  }

  .title-hr {
    margin-bottom: em(32, 20);
    font-size: em(20);
    line-height: 1.4;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      width: em(32);
      margin: em(12) 0;
      border: 1px solid $cow-gray-070;
    }
  }

  .text {
    font-size: em(14);
    font-weight: 300;
    color: $cow-gray-800;
    margin-bottom: em(8, 14);

    &.gray {
      color: $cow-dark-gray;
    }
  }

  .row-details {
    .price--wrapper {
      sup {
        vertical-align: baseline;
        font-size: inherit;
      }
    }
  }

  .cow-link {
    cursor: pointer;
  }

  .cow-link:hover, .cow-link:focus {
    color: $andes-accent-color;
  }

  .ui-card {
    .icon-wrapper {
      &:has(.icon-account-money-yellow){
        border: none;
      }
    }
  }
}

// Congrats modifiers: APPROVED / REJECTED / RECOVER / INSTRUCTIONS
.congrats--rejected {
  .congrats-header {
    background: $cow-color-error;

    #group_card_ui_top {
      .ui-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .group-row {
          background-color: rgba($cow-white, 0);
          flex-direction: column;
          align-items: flex-start;
          padding: em(0);
          min-height: auto;
          margin-bottom: em(0);

          .c-title {
            color: $cow-translucent-dark-gray;
            font-size: $cow-font-20;
            margin-bottom: em(8);
            text-align: left;
            max-width: 100%;
            margin-right: em(16);
            line-height: em(20);
            margin-top: 0;
          }
        }

        .icon-wrapper.icon-rejected {
          border-radius: 50%;
          border: 2px solid $cow-color-error;
          width: em(56);
          height: em(56);

          svg {
            fill: $cow-color-error;
          }
        }
      }
    }
  }

  #group_card_ui {
    .title-h2 {
      font-size: $cow-font-18;
      line-height: em(19.5555552);
      margin-bottom: em(14);
      color: $cow-translucent-dark-gray;
    }

    .text {
      font-size: $cow-font-16;
      color: $cow-translucent-dark-gray;
      font-weight: $font-weight-regular;
      line-height: em(20);
      margin-bottom: 0;
    }
  }

  .andes-button {
    margin: em(24) 0 0;
    line-height: em(14);
    font-size: em(16);
  }
}

@mixin green-congrats-header-background {
  .congrats-header {
    background: $cow-color-success;

    .congrats-titles {
      margin-top: em(0);

      #group_card_ui_top {
        .ui-card {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .group-row {
            background-color: rgba($cow-white, 0);
            flex-direction: column;
            align-items: flex-start;
            padding: em(0);
            min-height: auto;
            margin-bottom: em(0);

            .c-title {
              color: $cow-translucent-dark-gray;
              font-size: $cow-font-20;
              margin-bottom: em(8);
              text-align: left;
              max-width: 100%;
              margin-right: em(16);
              line-height: em(20);
              margin-top: 0;
            }

            .text {
              color: rgba($cow-black, 0.55);
            }

            #group-bpp {
              margin-top: em(14, 12);

              .icon-wrapper {
                border: none;
                margin-right: em(8, 14);
                width: auto;
                height: auto;
              }

              .text {
                $color: $cow-translucent-medium-gray;

                font-size: $cow-font-12;
                font-weight: $font-weight-semibold;
              }
            }
          }

          .icon-wrapper.icon-success,
          .icon-wrapper.icon-instructions {
            border-radius: 50%;
            border: em(2) solid $cow-color-success;
            width: em(56);
            height: em(56);

            svg {
              fill: $cow-color-success;
            }
          }
        }
      }
    }
  }
}

@mixin grand_total_split_payments {
  #grand_total {
    margin: em(24) 0 0;

    .row-details {
      .text {
        margin-top: 0;
        color: rgba($cow-black, 0.9);
      }

      .title_2 {
        font-size: em(14);
        color: rgba($cow-black, 0.55);
        font-weight: 400;
      }
    }
  }
}

.congrats--approved {
  @include green-congrats-header-background;

  #group_congrats_content {
    margin-top: em(0);

    .ui-card {
      .group-media-object {
        align-items: start;

        .icon-wrapper {
          height: em(40);
          width: em(40);

          #account-money-b {
            height: em(24);
            width: em(24);

            path {
              fill: $cow-translucent-dark-gray;
            }
          }
        }

        .icon-wrapper.icon-account-money-yellow {
          border: none;
        }
      }

      .row-details {
        .title {
          font-size: em(18);
          margin-top: em(6);
        }

        .text {
          margin: em(4) 0 0 0;
          color: rgba($cow-black, 0.55);
          font-weight: $font-weight-regular;
        }

        del {
          color: $cow-translucent-medium-gray;
        }
      }

      .icon-wrapper.icon-mercado-credito+.row-instructions {
        .row-details {
          &:first-child {
            margin-bottom: em(20);
          }
        }
      }

      .group-generic {
        flex-direction: column;
        align-items: flex-start;

        .andes-badge+.row-details>.title_2 {
          margin-top: em(7);
        }
      }
    }

    .group-row:last-child {
      background-color: transparent;
      min-height: em(62);
    }

    .group-congrats-shipping {
      .group-media-object {
        .row-details {
          .title {
            margin-top: 0;
          }
        }
      }
    }

    .disclaimer-row {
      .icon-with-tooltip {
        .andes-tooltip__trigger {
          margin: 0;
        }
      }
    }

    .button_icon_copy {
      padding: 0;
      
      .icon-wrapper.icon-copy {
        path {
          fill: $andes-accent-color;
        }
      }
    }

    #group_copy_value {
      .button_icon_copy {
        .icon-copy {
          width: auto;
        }
      }
    }
  }

  hr {
    opacity: 0.2;
    margin-top: em(24);
    margin-bottom: em(24);
  }

  .group-split-congrats {
    @include grand_total_split_payments;

    .group-media-object+.group-media-object {
      margin-top: em(25);
      margin-bottom: em(25);
    }

    hr {
      margin-top: 0;
    }

    p.text {
      span {
        b {
          padding-left: em(25);
        }
      }
    }
  }

  .group-congrats-summary {
    display: flex;
    flex-direction: row;

    .icon-wrapper {
      align-items: flex-start;

      &.icon-ticket {
        align-items: center;

        .with-custom-color {
          color: $cow-translucent-dark-gray;
        }
      }
    }

    .group-generic#congrats_summary_content {
      margin: 0 0 0 em(24);
      flex-direction: column;
      width: 100%;

      .row-summary {
        width: 100%;
        margin-bottom: em(8);

        &__discount_price {
          color: $cow-color-success;
        }
      }

      .row-summary:last-of-type {
        font-weight: 600;
      }
    }
  }

  #download_app_ui_card .ui-card {
    display: flex;

    .row-instructions {
      .title-h2 {
        margin-bottom: em(4);
      }

      .icon-wrapper {
        border: none;
      }

      .text {
        font-weight: 400;
        color: $cow-dark-gray;
      }
    }
  }

  .group_congrats_shipping_domicile {

    #pick_up_media_object,
    #front_media_object {
      .icon-wrapper {
        .with-custom-color {
          color: $cow-translucent-dark-gray;
        }
      }

      .row-details {
        .title {
          margin-top: 0;
          font-size: $cow-font-16;
          color: $cow-translucent-dark-gray;
        }
      }
    }
  }

  #group_card_combination {
    .ui-card {
      @include grand_total_split_payments;

      .group-media-object {
        .row-details {
          .title_2 {
            font-size: em(16);
            color: $cow-translucent-dark-gray;
            font-weight: $font-weight-regular;
          }
        }
      }
    }
  }

  #group_copy_value {
    .ui-card {
      display: flex;
      flex-direction: row;
      padding: 24px;

      .row-details {
        width: 100%;

        .title {
          margin-top: 0;
          font-size: $font-size-14;
          font-weight: $font-weight-semibold;
          line-height: em(18, 16);
          color: $cow-translucent-dark-gray;
        }
      }

      button {
        background-color: transparent;
        border: none;

        .icon-wrapper {
          border: none;
        }
      }
    }
  }

  #easy_login_ui_card {
    #easy_login_header {
      display: flex;
      flex-direction: row;

      .icon-wrapper {
        margin-right: em(16);
      }

      .row-instructions {
        .title-h2 {
          margin-bottom: em(3);
          color: $cow-translucent-dark-gray;
        }

        .text {
          color: $cow-translucent-dark-gray;
          font-weight: $cow-font-weight-regular;
          font-size: $cow-font-16;
          margin-top: em(3);
        }
      }
    }

    .with-track-event-wrapper {
      margin-right: 0;

      #easy_login_button_id {
        font-size: $cow-font-16;
        max-width: unset;
        width: 100%;
        margin-bottom: 0;
        text-indent: em(42.46);

        &::before {
          content: "";
          position: absolute;
          top: em(8.64);
          width: em(45);
          height: em(32);
          margin-left: em(-44);
          background: url("../assets/images/logo-mp-small@2x.svg") no-repeat;
          background-size: cover;
          left: initial;
          transform: scale(0.69);
        }
      }
    }

    .text {
      text-align: center;
      margin-bottom: 0;
      margin-top: em(16, 12);
      font-size: $cow-font-12;
      font-weight: $font-weight-regular;
      color: $cow-translucent-medium-gray;
    }
  }
}

.congrats--instructions {
  @include green-congrats-header-background;

  .congrats-header {
    .congrats-titles {
      #group_card_ui_top {
        .ui-card {
          align-items: center;

          .group-row {
            justify-content: center;

            .c-title {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .congrats-content {
    margin-top: em(0);

    .title-h3 {
      font-size: em(18);
      margin-bottom: em(8);
    }

    .group-button {
      margin-top: em(32);
    }

    .row-instructions {
      margin: em(18) 0;

      &:first-child {
        margin-top: 0;
      }

      .text {
        margin-bottom: 0;
        font-size: $cow-font-16;
        color: $cow-translucent-dark-gray;
        font-weight: $font-weight-regular;

        &:has(~ p) {
          padding-bottom: em(12);
        }
      }

      .text_congrats_additional_info {
        font-size: $cow-font-14;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: $cow-gray-800;
        margin-bottom: em(16, 14);
      }

      .group-generic {
        display: flex;
        margin-bottom: em(8);

        &:has(.icon-with-tooltip){
          align-items: end;
        }

        .andes-tooltip__trigger {
          margin: none;
          margin-left: em(6);

          .icon-wrapper {
            border: none;
            height: auto;

            svg {
              color: $andes-accent-color;
            }
          }
        }
      }

      .disabled-text {
        padding: em(12) em(0) em(12) em(12);
        margin-top: em(8);
        border-color: $cow-translucent-light-gray;
        background-color: $cow-bg-secondary;
        display: flex;

        .text {
          flex: auto;
        }

        .button_icon_copy {
          background-color: transparent;
          border: none;
          float: right;
          align-self: center;

          .icon-wrapper.icon-copy {
            border: none;
            border-radius: none;

            path {
              fill: $andes-accent-color;
            }
          }
        }
      }

      .row-details .text:last-child {
        color: $cow-text-color-secondary;
        font-weight: $cow-font-weight-semibold;
      }

      &:has(.cow-link) {
        p.text {
          margin-bottom: 8px;
        }
      }
    }

    .text-link {
      color: $cow-gray-800;
    }

    .ordered-list li {
      margin-bottom: em(16);

      >span {
        font-weight: 600;
      }

      .order-list-number::after {
        content: none;
      }

      .text {
        margin-bottom: 0;
        padding-left: em(8);
        font-size: em(16);
        color: $cow-text-color-secondary;
      }
    }

    hr {
      width: em(32);
      background: none;
      border: 0;
      border-top: 1px solid $cow-medium-gray;
      text-align: left;
      margin: em(20) 0 0 0;
    }

    .andes-button--link {
      display: inline-block;
      line-height: normal;
      width: auto;
    }

    .group-button {
      button.andes-button--link {
        font-size: em(16);
      }

      button.andes-button--loud+textarea+.andes-button--link {
        font-size: em(16);
        margin-left: em(24);
      }
    }

    a[href="#map"] {
      font-size: inherit;
    }

    .title-congrats-agencies {
      font-size: $cow-font-18;
      color: $cow-translucent-dark-gray;
      font-weight: $font-weight-semibold;
      margin-bottom: em(8, 20);
    }

    .subtitle-congrats-agencies {
      font-size: $cow-font-16;
      color: $cow-translucent-dark-gray;
      font-weight: $font-weight-regular;
      margin-bottom: em(24);
    }

    #ui_card_group {
      .title-hr {
        font-size: $cow-font-16;
        color: $cow-translucent-dark-gray;
        font-weight: $font-weight-regular;
      }

      ::not(.andes-tooltip-button-close)::after {
        content: unset;
      }
    }
  }

  .group-row:last-child {
    background-color: transparent;
    min-height: em(62);
  }
}

.congrats--recover {
  #group_card_media_recover_without_margin {
    margin-top: 0;
  }

  .congrats-header {
    background: $cow-color-warning;
    padding-bottom: 0;

    .congrats-titles {
      margin-top: em(24.5);
    }

    .c-title {
      margin-bottom: em(64, 20);
      font-size: em(20);
    }
  }

  .congrats-content {

    .group-button {
      margin-top: em(32);
    }

    #email_guest_login {
      margin-top: em(25);

      .icon-wrapper {
        width: em(40);
        height: em(40);
        margin-right: em(16);
        border-radius: 50%;
        border: solid em(1) $cow-gray-070;
      }

      svg {
        color: $andes-text-color-primary;
      }

      &~.group-button {
        margin-top: em(25);

        .andes-button {
          font-size: $cow-font-16;
        }
      }

      .row-details .text {
        font-size: em(12);
        padding-bottom: em(4);
      }
    }

    .group-media-object {
      margin-top: em(20);

      .cvv-icon {
        margin: em(2) em(20) 0 0;
      }

      .payment-card-icon {
        margin-left: 0;
      }

      .row-details .text {
        margin: 0;
      }
    }

    .ui-card-wrapper {
      .ui-card {
        .cow-secure-fields.security-code {
          flex-grow: 1;
        }
      }
    }

    #card_media_cvv {
      margin: em(24) 0;

      .icon-wrapper {
        width: em(40);
        height: em(40);
        border: solid em(1) $cow-gray-070;
        margin-right: em(16);
        border-radius: 50%;
      }

      &~.andes-button--loud {
        display: inline;
        margin: em(21) 0 0 em(16);
      }

      &~.security-code {
        .andes-form-control__bottom {
          min-height: 0;
          margin: em(6) 0 0 em(6);

          .andes-form-control__message {
            margin-left: em(6);
          }
        }
      }
    }

    .cow-secure-fields {
      .andes-form-control__bottom {
        min-height: 0;
        margin: em(6) 0 em(6) em(6);
      }
    }

    .ordered-list {
      padding: em(8) em(32) em(32) em(10);

      .text {
        margin: 0;
        padding-left: em(15);
        max-width: 94%;
      }
    }
  }
}

.group-hr {
  padding: em(33) 0;
  display: flex;
  border-top: em(1) solid $cow-medium-gray;
  border-bottom: em(1) solid $cow-medium-gray;
}

// Agencies Map
.button-back-arrow+.c-title {
  margin-left: em(16);
  display: inline-block;
}

.step_congrats_remedy_offer {
  .congrats-header__container {
    .icon-wrapper svg {
      height: em(36, 24);
    }
  }

  .andes-button+.andes-button {
    margin-left: em(0);
    margin-top: em(0);
  }

  .group-generic {
    height: fit-content;
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: em(22);
    border-radius: em(4);
    box-shadow: em(0) em(1) em(2) em(0) $cow-medium-gray;

    .icon-wrapper {
      width: em(124);
      height: em(83);
    }

    .title-h3 {
      width: em(288);
      text-align: center;
      margin: em(15);
      font-size: em(18);
    }
  }
}

.congrats.congrats--redirect {
  position: absolute;
  width: em(312);
  left: calc(50% - 9.75em);
  top: calc(50% - 6.25em);
  flex-direction: column;
  justify-content: center;

  .congrats-content {
    margin-top: 0;
    text-align: center;

    .group-redirect-loader {
      display: flex;
      justify-content: center;
      margin-bottom: em(24);

      .icon-wrapper:not(.icon-arrow-tail, .icon-arrow-head, .icon-success, .icon-failure) {
        width: em(64);
        height: em(64);
        box-shadow: 0 em(6) em(14) 0 rgb(0 0 0 / 12%);
        background-color: $cow-white;
        border-radius: 50%;
      }
    }

    .title-h2 {
      margin-bottom: em(12, 16);

      span {
        font-size: $cow-font-20;
        font-weight: $cow-font-weight-semibold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $cow-gray-800;
      }
    }

    .title-h3 {
      font-size: $cow-font-16;
      width: em(257);
      margin: auto;

      span {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: $cow-translucent-dark-gray;
      }
    }
  }

  .andes-button--link {
    width: 100%;
    margin: em(16) auto;
    display: none;

    &.done-dynamic {
      display: unset;
    }
  }
}

[class*="step_congrats_recover_timeout"],
[class*="step_congrats_recover_deferred_retry"] {
  .congrats--recover {
    .ui-card-wrapper {
      .ui-card {

        .text,
        #notification-text,
        #activities_button {
          margin-bottom: 0;
        }

        .group-button {
          display: flex;
          margin-top: $cow-spacing-24;
        }
      }
    }
  }
}

[class*="step_congrats_express_recover_"],
[class*="step_congrats_bad_security_code"],
[class*="step_congrats_call_for_auth_authorize"] {
  .congrats--recover {
    .navbar {
      display: none;
    }

    .title-h2 {
      margin-bottom: 0;
    }

    .group-generic {
      margin: 0 auto;
      width: em(580);
      margin-bottom: em(24);

      .ui-card-wrapper {
        .ui-card {
          width: 100%;
          overflow: initial;
          margin-bottom: em(8);

          .title-h2:first-child {
            font-size: em(18);
          }
        }

        .group-generic-block {
          margin-bottom: 0;
          box-shadow: none;

          .group-row {
            padding: 0;
            box-shadow: none;
            background: none;
            border-radius: none;

            .group-media-object {
              margin: 0;
              max-width: initial;
            }

            .icon-wrapper svg {
              max-width: em(48);
              max-height: em(48);
            }

            .row-details {
              display: flex;
              flex-direction: column;
            }

            +.group_form #installments_row .ui-card {
              padding: 0;
              border-bottom: none;
            }
          }

          form#group_pay .ui-card-wrapper .ui-card {
            border-bottom: none;
          }

          .andes-message {
            margin-top: em(24);
          }
        }

        .group_form {
          width: 100%;
          position: relative;

          %icon-wrapper {
            width: 2.5em;
            height: 2.5em;
            margin-left: 0;
            border-radius: 50%;
            border: solid 1px $cow-gray-070;
          }

          .text,
          .title-h2 {
            margin-bottom: 0;
          }

          .group-flex {
            font-weight: bold;
            flex-direction: column;

            .title-h2 {
              font-size: em(16);
            }
          }

          .installments-summary {
            font-weight: bold;
          }

          #installments_group_generic {
            margin-bottom: em(24);
            width: 100%;
            position: relative;

            .icon-wrapper .icon-installments {
              @extend %icon-wrapper;
            }

            .andes-dropdown {
              max-width: 70%;

              &.andes-dropdown--form .andes-dropdown__popover {
                padding-bottom: 0;
              }

              button {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .andes-form-control__message {
              padding-top: em(8, 13);
            }
          }

          .ui-card {
            box-shadow: none;
            padding-left: 0;

            .row_cvv {
              width: em(288);
              align-items: center;

              .icon-wrapper {
                @extend %icon-wrapper;

                margin-right: 0;
                position: relative;

                &.icon-card-cvv {
                  margin-right: em(24);

                  svg {
                    width: em(26);
                    height: em(26);
                  }
                }
              }
            }
          }
        }
      }

      .row-instructions {
        #shield_footer {
          margin-top: em(16);

          .text {
            font-size: em(12);
            margin-bottom: 0;
          }
        }

        .installment-detail .installment-detail__content {
          color: $cow-translucent-medium-gray;
          font-size: em(24);
          font-weight: 300;
          line-height: em(18);
          margin-bottom: em(16);
        }

        .group-button {
          display: flex;
          margin-top: em(16);

          .andes-button {
            min-width: em(200);

            &.andes-button--quiet {
              margin-left: em(8);
            }

            &#select_other_method_button span {
              &::before {
                background: none;
              }
            }
          }
        }

        .text+.group-button {
          margin-top: em(16);
        }
      }
    }

    .group-back-url {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      width: em(580);
    }
  }
}

[class*="step_congrats_express_recover_"],
[class*="step_congrats_call_for_auth_authorize"] {
  .congrats--recover {
    .andes-button {
      margin-bottom: 0;
    }
  }
}

.step_congrats_call_for_auth_authorize,
.step_congrats_bad_security_code {
  .security-code {
    margin-bottom: em(14);
  }
}

.step_congrats_call_for_auth {
  .congrats--recover {
    .congrats-header {
      .c-title {
        max-width: none;
      }
    }

    .ui-card {
      .title-h2 {
        font-size: $cow-font-18;
        margin-bottom: em(8, 18);
      }

      .text {
        font-size: $cow-font-16;
        font-weight: $cow-font-weight-regular;
        color: $cow-translucent-medium-gray;
      }

      .group-button {
        margin-top: em(24);
      }
    }
  }
}

.step_congrats_call_for_auth_authorize {
  .congrats--recover {
    .ui-card {
      .title-h2 {
        font-size: $cow-font-18;
        margin-bottom: 0;
      }

      .title-h2 + .text {
        display: none;
      }

      #card_media {
        margin: em(24) 0;

        .text {
          font-weight: $cow-font-weight-regular;
          color: $cow-translucent-medium-gray;
        }

        .icon-wrapper {
          width: em(40);
          height: em(40);
          border: solid em(1) $cow-gray-070;
          margin-right: em(16);
          border-radius: 50%;
        }

        &~.andes-button--loud {
          display: inline;
          margin: em(21) 0 0 em(16);
        }

        &~.security-code {
          .andes-form-control__bottom {
            min-height: 0;
            margin: em(6, 13) 0 0 em(6, 13);

            .andes-form-control__message {
              margin-left: em(3, 13);
            }
          }
        }
      }

      .security-code {
        margin-bottom: 0;
      }

      .required {
        .andes-form-control__label::after {
          content: " ";
        }
      }
    }
  }
}

[class*="step_congrats_express_recover_"],
[class*="step_congrats_insufficient_amount"] {
  .congrats--recover {
    .c-title {
      max-width: none;
    }

    #ui_card_group .ui-card {
      .group-generic-block .group-row {
        padding: $cow-spacing-24 0;

        .icon-wrapper {
          margin-right: $cow-spacing-16;
        }

        .row-details {
          margin-left: 0;

          .text {
            font-weight: $cow-font-weight-regular;
            color: $cow-translucent-medium-gray;
            line-height: em(18, 14);
          }
        }
      }

      #group_pay {
        display: flex;
        gap: $cow-spacing-16;

        #cvv_row {
          .ui-card {
            display: flex;
          }

          .row_cvv {
            width: 100%;

            div.andes-form-control__bottom,
            div.andes-form-control {
              margin-bottom: 0;
            }
          }
        }

        #installments_row {
          width: 100%;

          #installments_group_generic {
            display: block;
            margin-bottom: 0;

            #installments_select_express {
              margin-bottom: 0;

              span.andes-form-control__label {
                margin: 0 0 em(6, 14) em(6, 14);
              }

              button {
                margin-bottom: 0;
              }
            }

            p.text {
              margin: 0;
              padding: em(4, 14) 0 0 em(6);
              max-width: 90%;
            }

            .select-additional-info {
              font-size: $cow-font-14;
              font-weight: $cow-font-weight-regular;
              color: $cow-translucent-medium-gray;
              margin: 0;
              padding: em(4, 14) 0 0 em(6, 14);
            }
          }
        }
      }

      .row-instructions {
        .group-button {
          margin-top: $cow-spacing-32;

          #group_redirect_button {
            button {
              margin-left: $cow-spacing-16;
            }
          }
        }
      }

      & > p.text {
        font-size: $cow-font-16;
        font-weight: $cow-font-weight-regular;
        color: $cow-translucent-medium-gray;

        &~.group-button {
          margin-top: $cow-spacing-24;

          button {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

[class*="step_congrats_express_recover_consumer_credits"] {
  .congrats--recover {
    #ui_card_group .ui-card {
      #group_pay {
        flex-direction: column;
        gap: 0;

        #installments_row {
          #installments_select_credits {
            button:first-child {
              margin-bottom: 0;
            }
          }
        }

        #group_generic {
          display: flex;
          margin-bottom: 0;
          align-items: flex-end;

          .installments-summary {
            padding-left: em(5);
          }
        }

        .title-h2 {
          font-size: $cow-font-16;
          padding-top: $cow-spacing-24;
        }

        p.text {
          padding: em(4, 14) 0 em(24, 14) 0;
          font-weight: $cow-font-weight-regular;
          color: $cow-translucent-medium-gray;
        }

        #amount_text_credits {
          margin-bottom: 0;
          .text{
            padding-bottom: 0;
          }
        }
      }

      .installment-detail {
        p.installment-detail__content {
          padding-bottom: em(8, 14);
          color: $cow-dark-gray;
        }

        p.installment-detail__content.large {
          font-size: $cow-font-24;
        }

        p.installment-detail__content.small {
          font-size: $cow-font-14;
        }
      }

      .group-generic-block {
        p.text {
          margin-bottom: 0;
          color: $cow-dark-gray;

          span {
            font-weight: $cow-font-weight-regular;
          }

          a {
            color: $andes-accent-color;
            font-weight: $cow-font-weight-semibold;
          }
        }
      }
    }
  }
  .terms-and-conditions {
    &.remedies_terms_and_condition {
      background-color: transparent;
      font-size: $cow-font-14;
      font-weight: $cow-font-weight-regular;
      color: $cow-translucent-medium-gray;
      padding: 0;
    }
  }
}

[class*="step_congrats_express_recover_account_money"] {
  .congrats--recover {
    #ui_card_group .ui-card {
      .row-instructions .group-button {
        margin-top: 0;

        #group_pay {
          width: auto;
        }
      }
    }
  }
}

.legacy-mp-theme {
  .congrats--instructions {
    .congrats-content {
      a,
      .andes-button--link {
        font-size: $cow-font-14;
      }

      a.andes-button--loud {
        font-size: $cow-font-16;
      }
    }
  }

  .congrats--recover {
    .congrats-content {
      .group-button {
        margin-top: em(32);

        #button_guest_login {
          text-indent: em(42.46);

          &::before {
            content: "";
            position: absolute;
            top: em(8.64);
            width: em(45);
            height: em(32);
            margin-left: em(-115);
            background: url("../assets/images/logo-mp-small@2x.svg") no-repeat;
            background-size: cover;
            left: initial;
            transform: scale(0.69);
          }
        }
      }

      #email_guest_login {
        svg {
          color: $andes-accent-color;
        }  
      }
    }
  }
}
