.open-finance-terms {
  p {
    font-size: em(11);
    color: $andes-gray-900;
    text-align: center;
  }

  span,
  a {
    margin-right: em(4, 11);
  }
}
