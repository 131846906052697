@import "shared";

.layout--modal {
  .group-flex {
    flex-flow: column wrap;
    width: 100%;
    justify-content: center;
    align-content: center;
    background-color: $cow-white;

    .icon-wrapper.icon-3ds-anchor {
      margin-bottom: em(8);
      width: auto;
    }

    h1.c-title {
      text-align: center;
      font-size: $cow-font-20;
      margin: em(16) 0 em(12) 0;
    }

    p.text {
      font-size: $cow-font-16;
      margin-bottom: em(24);
      text-align: center;
      font-weight: $cow-font-weight-regular;
      color: $cow-black;
    }

    .group_form {
      display: flex;
      flex-direction: column;
      max-width: fit-content;
      align-self: center;

      .andes-button {
        padding: em(8) em(56);
      }
    }
  }
}
