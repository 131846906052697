#root-app {
  display: flex;
  justify-content: center;
}

.sniffing-scan-qr {
  max-width: 544px;
  margin: 40px auto 0;

  &__qr-container {
    .andes-card__content {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      gap: 24px;
    }

    &__title {
      text-align: center;
      padding: 0 35px;
    }

    &__qr-code {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      gap: 24px;

      &__brand {
        .brand {
          position: relative;
          padding: 0;

          .brand__thumbnail {
            height: 20px;
            width: 20px;

            &--image {
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      &__value {
        margin-top: 0;
      }

      &__qr-code {
        &--expired {
          path {
            fill-opacity: 50%;
          }
        }
      }
    }

    &__timeout {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      gap: 8px;

      &__message {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 8px;
      }
    }

    &__steps {
      display: flex;
      flex-flow: column nowrap;
      gap: 12px;

      &__step {
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;

        &__number {
          color: $andes-accent-color;
          min-width: 14px;
          text-align: center;
        }
      }
    }
  }

  &__message {
    margin-top: 16px;
  }

  &__back-button {
    margin-top: 24px;
  }
}
