@import "shared";

.andes-card.input-amount-card {
  margin-bottom: 24px;

  & > .andes-card__content {
    padding: 32px;
  }
}

#submit {
  min-width: unset;
}

#group_sidebar {
  .group-summary {
    padding: 32px 24px;
  }

  .row-summary__text {
    font-weight: $cow-font-weight-regular;
  }
}
