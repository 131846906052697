.user-title {
  margin-bottom: em(24);

  &__name {
    display: block;
    color: $cow-black;
    line-height: 1;
  }

  &__email {
    font-size: em(12);
    color: $cow-dark-gray;
  }
}
