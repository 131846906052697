.stepper {
  margin-top: $cow-spacing-16;
  margin-bottom: -$cow-spacing-12;

  &__secondary-text {
    color: $cow-text-color-secondary;
    font-weight: $cow-font-weight-bold;
  }
}

.step_card_form {
  .stepper__secondary-text {
    margin-top: em(16);
    margin-bottom: em(-16);
  }
}
