.group-list-wrapper {
  .andes-list__group--header {
    font-size: em(14);
    font-weight: 800;
    margin-bottom: em(8);
    margin-top: em(24);
  }

  #undefined__group-heading-0,
  #undefined__group-heading-1 {
    padding-left: 0;
    color: $cow-gray-800;
  }

  .andes-list {
    background-color: transparent;

    .andes-list__item {
      font-size: $cow-font-16;
      padding: em(16);
      height: em(74);
      margin-bottom: 0;
      border-radius: 0;
      border-bottom: em(1) solid $cow-common-background;
      margin-top: 0;
      background-color: $cow-white;

      &:hover {
        background-color: $cow-lighter-gray;
      }

      &:nth-of-type(1) {
        border-top-right-radius: em(4);
        border-top-left-radius: em(4);
      }

      &:last-of-type {
        border-bottom-right-radius: em(4);
        border-bottom-left-radius: em(4);
        border-bottom: 0;
      }

      .andes-radio {
        padding-left: em(9);
      }
    }

    .andes-list__item-primary {
      font-size: $cow-font-16;
      line-height: em(18, 16);
    }

    .andes-list__item-with-secondary {
      font-size: $cow-font-14;
      line-height: em(18, 14);

      .andes-radio {
        align-items: center;
        padding-left: em(10);
      }

      .andes-radio-element {
        margin-top: 0;
      }
    }

    .andes-list__item-tertiary {
      font-size: $cow-font-14;
      line-height: em(20, 14);
      align-self: center;
      white-space: nowrap;
    }

    .andes-list__item-primary + .andes-list__item-secondary {
      margin-top: em(3, 16);
    }
  }

  .hide-header {
    .andes-list__group--header {
      display: none;
    }
  }
}

.group-list-wrapper + .andes-button--loud,
.group-list-wrapper ~ .andes-button--quiet,
.group-list-wrapper ~ .with-track-event-wrapper > .andes-button--quiet,
.group-list-wrapper + .andes-button--loud + .button_back_default {
  margin-top: em(30);
}

body.step_shipping_options {
  .group-list-wrapper .andes-list .andes-list__item-tertiary {
    padding-right: em(16);
  }
}
