@import "../express/styles.desktop";

.group-generic-block {
  margin-bottom: 32px;
}

.layout--modal .layout__col-content .content {
  max-width: 100%;
}

.layout--modal .layout__col-content .scroller {
  height: em(420);
}

.layout--redirect .layout__col-content .content {
  max-width: 100%;
}

#extra_data_form {
  .ui-card {
    overflow: visible;
    padding: em(24);

    .payment-extra-data {
      visibility: hidden;
      height: 0;
    }

    .group-row {
      padding: 0;
      background-color: transparent;
      margin-bottom: 0;
      -webkit-box-align: unset;
      -ms-flex-align: unset;
      align-items: unset;

      .andes-dropdown {
        margin-right: em(16);

        .andes-form-control__border {
          margin-top: em(-1);
        }
      }

      label {
        margin-top: 0;
      }

      > * {
        flex-grow: 1;
      }

      > * {
        width: 26%;
        margin-right: em(16);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .group-row:not(:last-child) {
      margin-bottom: em(12);
    }
  }

  .group-identification__children > #type {
    width: em(82);
  }
}

.group-row + .title-h2 {
  margin-top: em(16);
}
