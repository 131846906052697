.user-header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: $cow-white;
  z-index: 99;
  box-shadow: 0 em(2) em(8) 0 $cow-translucent-lighter-gray;

  &__content {
    display: flex;
    justify-content: space-between;
    padding: em(16) em(24);
    .icon-wrapper.icon-mercadopago-full {
      width: em(120);
      flex-grow: 1;
    }
  }
}

#group_user_header_ml.user-header{
  background-color: $cow-yellow;
  .user-header__content {
    display: flex;
    align-items: center;
    .icon-wrapper.icon-mercadolibre-full,
    .icon-wrapper.icon-mercadolivre-full {
      display: block;
      width: em(120);
      flex-grow: 1;
    }
  }
}

