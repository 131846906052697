.shipping-review-container,
.shipping-options-container,
.address-selector-desktop {
  --padding-y-address: $andes-spacing-12;
  --padding-x-address: 0;

  .address-card {
    padding: var(--padding-y-address) var(--padding-x-address);
  }

  .address-item {
    .andes-list__item-asset {
      margin-bottom: 0;
    }
  }

  .shipping-content {
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-16;
  }

  .shipping-option {
    .andes-list__item-second-column {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .shipping-option-radio {
    display: flex;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .shipping-footer {
    display: flex;
    justify-content: right;
    gap: $andes-spacing-8;
  }

  .shipping-option-title {
    margin-top: $andes-spacing-24;
    font-weight: 800;
  }

  .shipping-icon-address path {
    fill: $andes-accent-color;
  }
}
