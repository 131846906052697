@keyframes loading-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.processing-loading {
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loading-fade-in 500ms ease-out both;
  background: rgba($cow-white, 0.8);
}
