@import "shared";
@import "../express/styles.desktop";

#group_payment_method > ul > li > .options-list__label {
  padding: 24px 32px;
}

.select-installments .select-additional-info {
  margin-top: em(4);
}

&.modal-view {
  .group-summary {
    .group-summary__container {
      h2.title-h2 {
        margin-top: em(72);
      }
    }
  }
}

& #group_content {
  padding-top: 0;

  & > h1 {
    margin-top: 40px;
  }
}


#installments_row {
  margin-bottom: em(16);

  .ui-card {
    #installments_group_generic.group-generic {

      .select-installments {
        .options-list__item {
          .row-details {
            .text_promo {
              margin-left: 0;
            }
          }
        }
      }
    }

    #amount_text_credits {
      margin-left: em(63);
      margin-top: em(-8);
    }
  }
}

#back_button {
  float: right;
}

/* Styles for consumer credits combination */

#title_combination {
  font-size: $cow-font-14;
  margin-bottom: 8px;
}

.payment-detail {
  &__row {
    margin-bottom: 16px;
  }
}

#group_pay {
  .payment-method-info {
    .description {
      padding: 32px 32px 0;
    }

    & + #installments_row {
      margin-bottom: em(8);

      .ui-card {
        margin-bottom: 0;
        padding: 24px 32px;
        .select-installments {
          padding-left: 0 !important;
        }
      }

      & + .installment-detail {
        .installment-detail {
          &__content {
            font-size: $cow-font-14;
            color: $cow-translucent-medium-gray;
            &.large {
              font-size: $cow-font-24;
            }
          }
        }
      }

      & + #submit,
      & + .installment-detail + #submit {
        min-width: unset;

        & + .with-track-event-wrapper {
          margin-right: 0;
        }
      }
    }
  }
}

.group-generic-block {
  .row-instructions {
    margin-bottom: em(8);
    .text {
      font-size: $cow-font-14;
      color: $cow-translucent-medium-gray;
    }
    & + .text.gray {
      font-size: em(12, 16);
      margin-bottom: em(8);
      & + .text {
        font-size: em(12, 16);
        margin-bottom: em(24,12);
        color: $cow-translucent-medium-gray;
      }
    }
    & + .text {
      font-size: em(12, 16);
      margin-bottom: em(24, 12);
      color: $cow-translucent-medium-gray;
    }
  }
  .installment-detail.large {
    & + .text {
      font-size: em(12, 15);
      margin-bottom: em(8);
      color: $cow-translucent-medium-gray;
    }
  }
}

.group-summary {
  .row-summary + hr + .row-instructions {
    margin-bottom: 18px;

    & + p.text {
      margin-top: 0px;

      span{
        font-size: $cow-font-12;
      }

      & + button#pay {
        margin-top: 20px;
      }
    }
  }
}

#group_sidebar_combination {
  padding-top: em(88, 15);
  .group-summary {
    background-color: $cow-lighter-gray;
    padding: 32px 24px;
    border-radius: em(6);
    box-shadow: 0 em(1) em(2) 0 rgba(0, 0, 0, 0.12);

    .title-h2 {
      margin-top: em(10);
      margin-bottom: em(16);
      font-size: $cow-font-16;
      font-weight: $cow-font-weight-semibold;
      color: $cow-translucent-dark-gray;
    }

    .row-summary {
      font-size: $cow-font-16;
      font-weight: $cow-font-weight-regular;
      color: $cow-translucent-dark-gray;
    }
  }
}

.layout--modal {
  #installments_group_generic {
    .select-installments {
      max-width: unset;
    }
  }
}