@import "shared";

div.optimus div.group-generic-block {
  .form .ui-card-wrapper .ui-card {
    margin-bottom: em(1);

    .select-installments {
      margin-left: em(1);
    }
  }

  // Installments
  #group_pay {
    #installments_group_generic {
      min-height: em(70);
      display: flex;

      .icon-wrapper {
        vertical-align: middle;
        margin-right: em(20);
        width: em(40);
      }

      .andes-dropdown {
        margin-bottom: em(8);
      }

      .select-additional-info {
        margin-top: em(-6);
      }

      .text {
        margin-left: 66px;
      }
    }

    .ui-card {
      .andes-message--quiet {
        margin-top: em(38);
      }
    }
  }

  form.form div.ui-card-wrapper div.ui-card {
    padding: em(18) em(32);
  }

  div.group-row {
    padding: em(18) em(32);
  }
}
