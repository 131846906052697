.icon-with-tooltip {
  display: flex;

  &__content {
    width: em(276);

    &.andes-tooltip-placement-bottomLeft .andes-tooltip-arrow::before {
      left: em(30);
    }

    &.andes-tooltip-placement-bottom .andes-tooltip-arrow::before {
      left: em(130);
    }
  }

  .andes-tooltip__trigger {
    margin: 0 em(10);
    display: flex;

    .icon-wrapper {
      width: em(16);
      margin-top: em(2);
    }
  }
}
