.andes-money-amount__currency-symbol {
  display: none;
}

.switch-combination__content {
  margin-top: 32px;

  .andes-list {
    padding: 16px 32px;

    .andes-list__item {
      padding: 0;

      .andes-list__item-first-column {
        padding: 0;
      }

      .andes-list__item-second-column {
        padding: 0;

        .andes-list__item-tertiary-container {
          .andes-list__item-tertiary {
            height: 24px;

            .andes-switch {
              padding: 0;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;
        background: $cow-white;
      }
    }
  }
}

.options-combinations {
  margin-top: 32px;

  &__title {
    > h1.andes-typography {
      margin-top: 8px;
    }
  }

  &__list {
    margin-top: 16px;
  }

  .payment-detail__row {
    margin-top: 16px;
  }

  .andes-card {
    &.payment-method-card-list--single {
      .andes-radio {
        display: block !important;
      }
    }
    .andes-list {
      .andes-list__item {
        .andes-list__item-first-column {
          .andes-thumbnail {
            &.icon_account_money_yellow,
            &.icon_mercado_credito_yellow,
            &.icon_mercado_pago_yellow {
              border: none;
            }

            &.icon_mercado_pago_black {
              img {
                min-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
