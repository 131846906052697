.zipcode-result {
  .buttons-container {
    font-size: $cow-font-16;

    .andes-button--quiet {
      font-weight: $cow-font-weight-semibold;
      margin-bottom: 0;
    }
  }

  .single-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon-wrapper {
      height: em(64);
      width: em(64);
      border-radius: em(50);
      background: $cow-translucent-lightest-gray;
    }

    h2 {
      font-weight: $cow-font-weight-semibold;
      margin: em(8) 0;
    }

    p.text.gray {
      margin-bottom: em(24);
    }

    .buttons-container {
      display: flex;
      flex-direction: column;

      .andes-button--loud {
        align-self: unset;
      }

      .andes-button--quiet {
        margin-left: 0;
        margin-top: em(8);
      }
    }
  }

  .list {
    margin-bottom: em(24);

    .list-item {
      .options-list.ui-card {
        padding: em(16) em(32);

        .options-list__label {
          .row-details > .text {
            line-height: em(20);

            span {
              display: flex;
              flex-direction: column;

              span:first-child {
                color: $cow-translucent-dark-gray;
              }
            }
          }
        }
      }
    }
  }

  .list + .buttons-container {
    display: flex;
    flex-direction: row;
  }
}
