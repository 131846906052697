@import "shared";

.group-summary {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  padding: $gutter-24;
  padding-bottom: $gutter-base;
  border-top-right-radius: em(6);
  border-bottom-right-radius: em(6);

  &__container {
    position: relative;
    width: 100%;
  }

  .title-h2 {
    margin: em(72) 0 em(32);
  }

  .row-summary+hr+.title-h2 {
    margin: em(10) 0 em(32);
  }

  .andes-button--loud {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  hr {
    margin: em(16) 0;
    border: none;
  }

  .summary-header {
    height: em(32);
  }

  .summary-header+.title-h2 {
    margin-top: em(48);
  }

  .user-menu {
    margin-top: em(8);
    margin-right: em(-1);
    float: right;
  }

  .user-menu__button {
    margin-right: em(-3);
  }

  .row-summary+hr+.row-instructions {
    margin-bottom: em(40);

    .text {
      font-size: em(14);
      text-align: center;
      margin-top: 0;
      position: initial;
    }
  }

  // For terms & conditions rendered in the summary
  .row-summary.row-summary--pay+p.text {
    font-size: em(12);
    text-align: center;
    margin: em(32) 0;
  }
}

// Special styles for redirect layout
.layout--redirect,
.layout--embed {
  .group-summary {
    padding: $gutter-32 $gutter-24;
    margin-bottom: em(16);
    border-radius: em(4);
    background: $cow-lighter-gray;
    box-shadow: 0 em(2) em(2) 0 $cow-medium-gray;

    .title-h2 {
      margin-top: em(8);
    }

    .andes-button--loud:not(.open-finance-terms ~ div > div button) {
      position: relative;
      margin-top: em(48);
    }
  }
}

.layout--modal {
  .group-summary {
    height: 100%;
  }
}

.step_review_split_payment {
  .layout__col-sidebar {
    .group-summary {
      .row-summary__price+span.row-summary__text_promo {
        margin-top: em(-9);
      }
    }
  }
}

.step_review_yape {
  .layout--modal {
    #group_content {
      overflow-y: scroll;
      margin-right: em(14);
      padding-right: em(10);

      .scroller.with-legals {
        height: 410px;
        overflow-y: unset;
        padding-right: 0;
      }

      .group-media-object {
        height: unset;
      }

      .group-legals {
        bottom: unset;
      }
    }
  }

  .layout__col-sidebar {
    .group-summary {
      background-color: $cow-white;

      .group-legals {
        bottom: 4em;
      }

      #form-yape {
        +#shield_footer {
          margin-top: 12px;
          padding: 0;
        }
      }
    }
  }
}

.layout__col-content:has(.stepper)+.layout__col-sidebar .group-summary {
  margin-top: em(24);
}

// styles when rebranding is not active
:has(.legacy-mp-theme) {
  .layout__col-sidebar {
    .group-summary {
      #form-yape {
        +#shield_footer {
          margin-top: 6px;
        }
      }
    }
  }
}
