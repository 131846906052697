.andes-button {
  margin-bottom: 0;
}

@include modal-with-button-back;


.layout--modal {
  .scroller {
    height: 330px;
  }
}
