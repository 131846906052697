.legacy-mp-theme {
  // Progress button modifier
  .andes-button {
    position: relative;
    overflow: hidden;

    .andes-button__content {
      position: relative;
      z-index: 1;
    }
  }

  .andes-button::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: em(1, 18);
    margin: em(-1, 18);
    background: $andes-accent-color;
    border-radius: em(4, 18);
    box-sizing: content-box;
    content: "";
    transform: scaleX(0);
    transform-origin: left center;
  }

  .andes-button--quiet::after {
    background: rgba($cow-lighter-blue, 0.1);
  }

  .andes-button {
    &[data-loading="true"] {
      &.andes-button--loud {
        z-index: 200;
      }

      &::after {
        @include loader-step-1;
      }
    }

    &.progress-done {

      &.andes-button--loud,
      &.andes-button--quiet {
        &::after {
          @include loader-step-2;
        }
      }
    }
  }

  .andes-button--link {
    padding: 0;
    margin: 0;
    border: none;
    color: $andes-accent-color;
    background: none;

    &:focus {
      outline: 0;
      box-shadow: none;
      color: $cow-blue-300;
    }

    &:active {
      color: $cow-blue-darker;
    }

    &:hover {
      background: none;
      cursor: pointer;
    }
  }

  .icon.icon-lock--outline span::before {
    content: "";
    position: relative;
    margin: 0 em(8) 0 0;
    width: em(14);
    height: em(16);
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij4KICAgIDxnIGZpbGw9IiNGRkYiPgogICAgICAgIDxwYXRoIGQ9Ik0xMi4zMSA2LjIwMlY1LjQzQzEyLjMwNyAyLjQzMiA5Ljg3Ni4wMDMgNi44OC4wMDNTMS40NTIgMi40MzIgMS40NSA1LjQyOXYuNzczQy42MjcgNi42MDguMTA1IDcuNDQ3LjEwMyA4LjM2N3Y1LjIxNkMuMTA0IDE0LjkxNyAxLjE4NSAxNS45OTggMi41MTkgMTZoOC43MjJjMS4zMzQtLjAwMiAyLjQxNS0xLjA4MyAyLjQxNy0yLjQxN1Y4LjM2N2MtLjAwMi0uOTItLjUyNC0xLjc1OS0xLjM0OS0yLjE2NXptLTUuNDMtNC44YzIuMjIzLjAwMyA0LjAyNCAxLjgwNCA0LjAyNyA0LjAyN3YuNTJIMi44NTN2LS41MmMuMDAzLTIuMjIzIDEuODA0LTQuMDI0IDQuMDI3LTQuMDI3em01LjM3NiAxMi4xODFjMCAuMjctLjEwNy41MjgtLjI5Ny43MTgtLjE5LjE5LS40NDkuMjk3LS43MTguMjk3SDIuNTJjLS4yNyAwLS41MjgtLjEwNy0uNzE4LS4yOTctLjE5LS4xOS0uMjk3LS40NDktLjI5Ny0uNzE4VjguMzY3YzAtLjI3LjEwNy0uNTI4LjI5Ny0uNzE4LjE5LS4xOS40NDktLjI5Ny43MTgtLjI5N2g4LjcyMmMuMjcgMCAuNTI4LjEwNy43MTguMjk3LjE5LjE5LjI5Ny40NDguMjk3LjcxOHY1LjIxNnoiLz4KICAgICAgICA8Y2lyY2xlIGN4PSI2Ljg4IiBjeT0iMTAuOTc1IiByPSIxIi8+CiAgICA8L2c+Cjwvc3ZnPgo=");
  }
}
