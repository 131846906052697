@at-root body.step_open_finance_awaiting {
  & {
    background-color: $andes-white;
  }

  .page-mask {
    padding-top: em(20);
  }

  .layout-main {
    padding: 0;
  }
}

.open-finance-awaiting-container {
  width: 100%;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      margin-bottom: em(10);
      line-height: em(20);
      font-weight: bold;
    }

    p {
      font-weight: $font-weight-semibold;
      font-size: em(18);
      color: $andes-gray-550;
    }
  }
}
