@mixin reset-styles-andes-list {
  .andes-list {
    &__item {
      padding: 0;
      margin-bottom: 0;

      &-first-column {
        padding: 0;
        margin-right: em(4);
      }

      &-second-column {
        padding: 0;
        margin-right: 0;
      }

      &-text {
        margin: 0;
      }

      &:hover {
        background-color: transparent;
        cursor: unset;
      }
    }
  }
}

/* Section to modify Andes styles */
#summary-list-accordion {
  .andes-accordion {
    &-header {
      &:hover {
        background-color: transparent;
      }
      min-height: auto;
      margin-bottom: em(8);

      &-container {
        padding: 0;
        &__indicator {
          margin-left: 0;
          svg {
            path {
              fill: $andes-accent-color;
            }
          }
        }
      }
      @include reset-styles-andes-list;
    }

    &-panel-transition {
      .andes-accordion-panel-transition__inner {
        margin-bottom: em(12);
        & > div:first-child {
          position: relative;
          padding: 0 em(24) 0 em(20);
          min-height: auto;
        }
        @include reset-styles-andes-list;
        .andes-list {
          &__item {
            margin-bottom: em(8);

            .secondary-text {
              font-size: $cow-font-14;
              font-weight: $cow-font-weight-regular;
              color: $andes-gray-550;
              display: flex;
              align-items: center;

              .icon-with-tooltip {
                margin-left: em(4);
                .andes-tooltip__trigger {
                  margin: 0;
                }
              }
            }
            &-primary {
              height: auto;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  &.no-display {
    .andes-accordion-header-container__indicator {
      display: none;
    }
    .andes-accordion-panel-transition__inner {
      & > div:first-child {
        padding: 0;
      }
    }
  }
}
