@import "shared";

.layout--modal {
  .group-flex {
    .icon-wrapper.icon-3ds-anchor {
      margin-bottom: em(8);
      width: auto;
    }

    p.text {
      max-width: em(350);
    }
  }
}
