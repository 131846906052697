@import "shared";

.andes-dropdown--open {
  .andes-dropdown__popover {
    padding-bottom: 0;
    // Firefox
    scrollbar-color: $cow-dark-gray transparent;

    &::-webkit-scrollbar,
    &::-moz-scrollbar {
      width: em(5);
      background-color: transparent;
      border-radius: em(4);
    }

    // Safari
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb,
    &::-moz-scrollbar-thumb {
      background-color: $cow-dark-gray;
      border-radius: em(4);
    }
  }
}

.select-installments {
  max-width: em(382);
}

.step_congrats_express_recover_credit_card .select-installments {
  max-width: em(240);
}

div#layout-modal {
  .select-installments {
    max-width: em(335);
  }
}