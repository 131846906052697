.input-action {
  .andes-form-control__embedded {
    button.andes-button--quiet {
      font-size: $cow-font-14;
      white-space: nowrap;
      margin-bottom: 0;
      padding: 0;
    }

    button.andes-button--transparent {
      font-size: $cow-font-14;
      white-space: nowrap;
      margin: 0;
      padding-left: em(16);
      padding-right: em(16);
    }
  }
}
