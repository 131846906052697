@import "shared";

.fullscreen-message {
  background: rgba($cow-white, 0.92);

  &__content {
    animation: scale-up 280ms ease-out;
  }

  .layout--modal & {
    border-radius: em(4);
  }
}
