.wrapper-without-cvv {
  background-color: $cow-white;
  min-height: em(10);
  padding: 0;
  margin: 0;
  border-radius: 0 0 em(6) em(6);
  margin-top: em(-4);
}

#group_pay {
  #cvv_row>.ui-card {
    min-height: auto;
    padding: 0;
  }
}
