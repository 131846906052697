#group_open_finance {
  .icon-wrapper {
    width: em(40);
    height: em(40);
    border-radius: 50%;
    border: solid 0.1px $cow-light-gray;
  }

  .ui-card {
    .group-media-object {
      .icon-wrapper {
        width: em(40);
        height: em(40);
        border-radius: 50%;
        border: solid 0.1px $cow-light-gray;

        svg {
          max-width: 60%;
          max-height: 90%;
        }

        img {
          max-width: 80%;
          max-height: 80%;
        }
      }
    }

    .text {
      color: $andes-gray-900;
    }
  }

  .group-media-object:last-child {
    margin-top: em(32);
  }

  .row-details {
    .title {
      margin-top: 0;
    }
  }
}

#group_menu.group-media-object {
  .icon-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    padding: 0.5em;
    border: 1px solid $cow-light-gray;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    svg {
      height: 22px;
      width: 22px;
    }
  }

  .layout__col-content {
    padding: 0;

    .title-h2 {
      margin-bottom: 0;
    }

    .andes-button--link {
      font-size: 14px;
      line-height: normal;
      height: auto;
    }
  }
}

#view_details {
  margin: 0;
  text-align: left;
  height: em(32);
  line-height: em(32);
}
