@import "shared";

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// New layout for optimus and new architecture
**************************************************************/

// The new layout applies only to pages that contain the content and sidebar columns
// Mixin to reuse the new design for the different modes.(modal, redirect, embed)

@mixin apply-new-layout() {
  .layout-main {
    &:has(.optimus),
    &:has(.layout--new-interface) {
      &:has(.layout__col-content) {
        &:has(.layout__col-sidebar) {
          @content;
        }
      }
    }
  }
}

// New layout specifications

@mixin grid-layout-main {
  max-width: $layout-desktop-max-width;
  width: 100%;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

@mixin grid-layout {
  display: grid;
  grid-template-columns: repeat(12, 64px);
  grid-template-rows: 1fr auto;
  column-gap: 16px;
  margin: 0 40px;
  width: auto;
  height: 100vh;
}

@mixin grid-layout-content {
  grid-column: span 7;
  padding-right: 0;
}

@mixin grid-layout-sidebar {
  grid-column: span 5;
  margin-left: 24px;
}

@mixin grid-layout-footer {
  grid-column: span 12;
  bottom: 0;
  width: 944px;
  display: flex;
  align-items: flex-end;
  margin-top: 40px;
}

@include apply-new-layout {
  @include grid-layout-main;

  .optimus,
  .layout--new-interface {
    @include grid-layout;
  }

  .layout__col-content {
    @include grid-layout-content;
  }

  .layout__col-sidebar {
    @include grid-layout-sidebar;
  }

  .layout__col-footer {
    @include grid-layout-footer;
  }
}

.layout--modal {
  @include apply-new-layout {
    .toggle-button-wrapper {
      position: absolute;
      right: 10px;
      margin: 0;
    }

    .cow-navbar-v2 {
      position: absolute;
    }
    .optimus,
    .layout--new-interface {
      height: 566px;
    }
  }
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// New layout for hybrid architecture
**************************************************************/

// Mixin to reuse the new design for the different modes.(modal, redirect, embed)

@mixin apply-hybrid-layout() {
  .layout-main {
    &:has([class*="payment-option-desktop-screen"]) {
      @content;
    }
  }
}

@include apply-hybrid-layout {
  @include grid-layout-main;

  .payment-option-desktop-screen {
    @include grid-layout;
  }

  .payment-option-desktop-screen__content {
    @include grid-layout-content;
  }

  .layout__col-footer {
    @include grid-layout-footer;
  }

  .optimus {
    @include grid-layout-sidebar;
  }
}

.layout--embed {
  @include apply-hybrid-layout {
    .payment-option-desktop-screen__content {
      margin-top: 0;
    }
  }
}

.layout--modal {
  @include apply-hybrid-layout {
    .cow-navbar-v2 {
      position: absolute;
    }

    .payment-option-desktop-screen {
      .optimus {
        height: auto;
      }
    }

    .payment-option-desktop-screen__content {
      padding-left: 0;
      height: 100%;
    }
  }
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Layout legacy for pages that do not apply to the new layout
**************************************************************/

// These styles are preserved for pages that do not apply to the new layout
// Mixin to reuse the new design for the different modes.(modal, redirect, embed)

@mixin apply-legacy-layout() {
  .layout-main {
    &:not(:has([class*="payment-option-desktop-screen"])) {
      &:has(.optimus) {
        &:not(:has(.layout__col-content)),
        &:not(:has(.layout__col-sidebar)) {
          @content;
        }
      }
    }
  }
}

@include apply-legacy-layout {
  min-width: $layout-desktop-max-width;
  max-width: $layout-desktop-max-width;
  width: 100%;
  margin: 0 auto;
  border-radius: $modal-border-radius + "px";

  .optimus {
    display: flex;
    align-items: stretch;
  }
}

// Layout Legacy Modifiers
.layout--embed {
  @include apply-legacy-layout {
    margin: 0 auto;

    .optimus {
      align-items: flex-start;
    }
  }
}

.layout--modal {
  @include apply-legacy-layout {
    max-width: $layout-desktop-max-width;
  }
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// General layout styles
**************************************************************/

body.modal-view {
  padding: 0 12px;
}

.button-back-arrow {
  display: inline-block;

  > :hover {
    path {
      opacity: 0.7;
    }
  }
}

.button-back-arrow + .c-title {
  margin-left: em(16);
  display: inline-block;
}

.layout-close-btn {
  position: absolute;
  top: em(16);
  right: em(-68);
  padding: 0; // button browser reset for correct alignment in firefox
  color: $cow-white;
  border: 0;
  background: none;
  cursor: pointer;
}

.layout__col-content {
  position: relative;
  padding: $gutter-base $gutter-32;
  flex-grow: 1;
  width: 100%; // IE explorer needs a defined width for responsive to work well

  > :last-child {
    margin-bottom: 0;
  }

  .andes-button--loud {
    margin-bottom: 0;
    float: right;
  }

  // Promos link
  .bank-deals-link {
    margin: em(-27, 14) 0 em(8, 14);

    @media (width >=833px) {
      // "Hard coded" media query needed so the link doesn't overlap with the title
      position: absolute;
      right: em(32, 14);
      top: em(52);
      margin: 0;
    }

    @media (width <=832px) {
      margin-top: em(-26);
      margin-bottom: em(17);
    }
  }

  .toggle-button-wrapper {
    position: absolute;
    right: 0;
    margin: 0;
  }
}

// Layout Modifiers
//------------------------------------------
// MODAL
.layout--modal {
  .layout-main {
    margin-top: 16px !important;
    max-width: $layout-desktop-max-width;
    position: relative;
    margin: $gutter-base auto;
    background: $cow-gray-070;
    border-radius: em(6);
    box-shadow: 0 em(2) em(6) rgba($cow-black, 0.3);
    transition: opacity 450ms cubic-bezier(0.67, 0, 0.74, 1); // opacity transition is used whan modal is closed
  }

  // Both containers must have height defined because
  // divs generated in between these by react
  .layout-main,
  .layout-main .optimus {
    height: em(566); // modal height
  }

  .layout__col-sidebar {
    background-color: $cow-lighter-gray;
    border-top-right-radius: em($modal-border-radius);
    border-bottom-right-radius: em($modal-border-radius);
  }
}

.layout__col-sidebar {
  position: relative;
}

// Redirect & embed
.layout--redirect,
.layout--embed {
  .layout-main {
    margin: 0 auto;

    .optimus {
      align-items: flex-start;
    }
  }

  .processing {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  .layout__col-content {
    // The left padding is already overwritten. Is the right one needed?
    padding: em(16) em(32);
    padding-bottom: 0;
    padding-left: 0;
  }

  .layout__col-sidebar {
    padding-top: em(96);
  }

  .layout-close-btn {
    display: none;
  }
}

.layout--redirect {
  // This is: please let room for a position absolute navbar only when it's present
  .navbar ~ [class^="layout__"],
  .cow-navbar-v2 ~ [class^="layout__"],
  .cow-navbar ~ [class^="layout__"] {
    margin-top: em($navbar-height);
  }
}

.layout--modal {
  // This is: please let room for a position absolute navbar only when it's present
  .navbar ~ [class^="layout__"],
  .cow-navbar-v2 ~ [class^="layout__"],
  .cow-navbar ~ [class^="layout__"] {
    margin-top: em($navbar-height);
  }

  .navbar,
  .cow-navbar-v2 {
    border-top-left-radius: em(6);
    border-top-right-radius: em(6);
  }

  .cow-navbar-v2 {
    position: absolute;
  }

  .layout__col-content {
    padding-left: 0;
  }
}

.layout--embed .layout__col-content {
  padding-left: 0;
}

// Back url for group content
.layout__col-content {
  .group-back-url {
    display: flex;
    gap: em(8);

    .andes-button {
      margin: 0;
      line-height: em(14);
      font-size: em(14);
      padding-top: 1px;
    }
  }
}
