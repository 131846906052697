@import "shared";

.form_address_info {
  .form_address_double_field {
    .form_address_field {
      margin-bottom: 0;

      &:first-child {
        margin-right: $cow-spacing-8;
      }

      &:last-child {
        margin-left: $cow-spacing-8;
      }

      &:only-child {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
      }
    }

    .form_address_number_field {
      margin-left: $cow-spacing-8;
    }
  }
}
