.shipping-address-form .andes-card__content .andes-form-control.required label .andes-form-control__label::after {
  content: " *";
  font-size: em(20);
}

.group_form_scroller {
  .ui-card {
    p.text {
      margin: em(16) 0;
    }

    .andes-form-control .andes-form-control__control {
      #input_description {
        min-height: em(70);
      }
    }

    .andes-form-control {
      .andes-form-control__bottom #countdown-progress {
        width: 100%;
      }
    }
  }
}

.input-action {
  .andes-checkbox--embedded.andes-checkbox {
    padding-right: em(18);
    margin: 0;

    .andes-checkbox__background {
      top: em(-4);
    }

    .andes-checkbox__label-text {
      font-size: $cow-font-12;
      color: $andes-accent-color;
      padding-bottom: 0;
    }
  }

  input {
    flex: 1;
  }
}

.group-flex > label {
  flex: 1;

  &:first-of-type {
    margin-right: em(32);
  }
}

.andes-form-control__control .andes-button.andes-button--quiet {
  padding-left: em(16);
  padding-right: em(16);
  margin-bottom: 0;
}

label.andes-form-control--multiline .andes-form-control__control {
  textarea {
    min-height: em(68);
    max-height: em(68);
    text-indent: unset;
    padding: em(16);
  }

  textarea::placeholder {
    font-size: $cow-font-14;
  }

  span.andes-form-control__label {
    white-space: pre-wrap;
    padding: em(16) em(32) em(16) em(16);
    font-size: $cow-font-14;
  }
}

label.andes-form-control--multiline.andes-form-control--focused {
  span.andes-form-control__label {
    display: none;
  }
}

.andes-checkbox__input, .andes-checkbox__label.andes-checkbox__label-text {
  font-size: $cow-font-12;
  color: $andes-accent-color;
  margin: em(16) em(18) em(16) 0;
}

.andes-checkbox__input {
  margin-right: em(6);
}

.row-summary__free {
  color: $andes-success-green;
}

.layout--modal {
  .andes-form-control {
    &__control {
      #input_street_number {
        width: 80%;
      }
    }
  }
}
