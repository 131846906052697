.row-details {
  line-height: 1.2;

  // Mapping middle-end types
  // -------------------------------------------
  .text_title {
    margin-bottom: em(1, 16);
    font-size: em(16);
    color: $cow-translucent-dark-gray;
    line-height: em(18);
  }

  .text_cft {
    font-size: em(22);
    line-height: 1;
    color: $cow-dark-gray;
  }

  .text_price {
    display: inline-block;
    margin-left: em(10);
  }

  .text,
  .text_price,
  .text_interest_total,
  .text_with_hint {
    color: $cow-translucent-medium-gray;
    font-size: em(14);
  }

  .text_interest_total,
  .text_interest_promo,
  .text_promo {
    display: inline-block;
    margin-right: em(8, 14);
    font-size: em(14);
  }

  .text_promo,
  .text_interest_promo {
    color: $cow-color-success;
  }

  div.text_with_hint {
    display: flex;
    flex-direction: row;
    margin-top: em(4);
  }

  div.title_with_bpp {
    svg {
      padding: 0;
      margin-left: em(8);
      margin-bottom: em(2);
      height: em(14);
      vertical-align: middle;
      width: em(14);
    }
  }
}
