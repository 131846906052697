@import "shared";

.icon-with-tooltip__content {
  margin-bottom: 0;
}

.payment-detail__text {
  .icon-with-tooltip {
    margin-left: 0;
  }
}
