.restart-flow-container {
  padding-top: em(24);
  padding-bottom: em(16);
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  h1 {
    font-size: $cow-font-24;
    font-weight: $cow-font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: $cow-translucent-dark-gray;
    margin: 0 auto;
    padding: $cow-spacing-24 0;
  }

  h1.another-tab-text{
    padding: $cow-spacing-20 0 0 0;
  }

  h2 {
    width: em(550);
    font-size: $cow-font-16;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: $cow-translucent-dark-gray;
    margin: $cow-spacing-8 auto $cow-spacing-20 auto;
  }

  a {
    width: em(175);
    font-size: $cow-font-16;
    font-weight: $cow-font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    padding: em(16);
  }
}
