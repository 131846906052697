.group-identification {
  &__children {
    display: flex;
  }

  .input-document {
    flex-grow: 1;
  }
}

.andes-form-control.andes-form-control--textfield {
  padding-top: 0;
}
