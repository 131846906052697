.payment-detail {
  &__row {
    box-shadow: none;
  }

  &__secondary {
    background-color: $cow-lighter-gray;
    box-shadow: none;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__text {
    font-size: em(16);
    font-weight: $cow-font-weight-regular;
    color: $andes-gray-550;
    display: flex;
    align-items: center;
  }

  &__value {
    font-size: em(16);
    font-weight: $cow-font-weight-semibold;
    color: $cow-translucent-dark-gray;
  }
}

.payment-detail__text {
  .icon-with-tooltip {
    svg {
      path {
        fill: $andes-accent-color;
      }
    }
  }
}

.icon-with-tooltip {
  &__content {
    margin-bottom: 0;
    width: em(230);
    padding: em(16);

    .andes-tooltip {
      &__content {
        margin: 0;
      }
    }
  }
}