.shipping-address-selector {
  .address-selector-desktop {
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-12;

    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}
