div.optimus div.group-payment-method-row-with-cvv .group-row {
  padding: em(24) em(16);
}

.group-split-congrats {
  .ui-card {
    padding-bottom: em(24);

    // Summary information to user
    > .row-details {
      margin-left: em(60);
      margin-bottom: em(24);
    }
  }

  // Total text
  #grand_total {
    display: flex;
    flex-direction: row;
    width: auto;

    .title-h2 {
      font-weight: 400;
      margin-bottom: 0;
    }

    .title-h2:first-child {
      width: em(36);
      margin-right: em(24);
      color: $cow-dark-gray;
    }
  }

  // BPP
  .group-media-object#grand_total {
    .text {
      font-size: $cow-font-16;
      line-height: $cow-line-height-base;
      margin-right: em(4);
    }
  }

  .group-media-object#grand_total + div.group-congrats-shipping {
    .group-media-object:first-child {
      margin-top: em(24);

      .group-congrats-shipping:nth-child(2) > .group-media-object {
        margin-top: 0;
      }
    }
  }

  // End BPP
}

.ui-card {
  .row-details {
    .text_congrats_additional_info {
      font-size: $cow-font-14;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $cow-translucent-medium-gray;
      margin-bottom: em(16, 14);
    }
  }

  .group-generic-block {
    margin-top: em(16);
    margin-bottom: 0;
  }

  .group_congrats_shipping_domicile {
    .group-media-object:nth-child(2) {
      margin-top: em(32);
    }

    #pick_up_media_object .icon-wrapper > svg,
    #front_media_object .icon-wrapper > svg {
      height: em(24);
    }
  }

  .group_congrats_shipping_domicile:nth-child(3),
  .group-congrats-shipping:nth-child(3) {
    margin-top: em(30);

    h1 {
      margin-top: em(0);
      margin-bottom: em(4);
    }

    hr {
      margin: 0 em(-32);
      border-bottom: 0;
    }

    .group-media-object:nth-child(1) {
      .andes-badge {
        margin-left: em(10);
      }

      .row-details,
      .text {
        margin-bottom: em(4, 16);

        .title_2 {
          font-size: em(16);
          font-weight: $cow-font-weight-bold;
        }
      }
    }

    .group-media-object:nth-child(2) {
      margin: 0 em(-32) em(10);

      .andes-badge {
        margin-top: 0;
        padding-right: em(6);
      }
    }

    .group-media-object:nth-child(3) {
      margin-top: em(16);
      display: flex;
      justify-content: space-between;

      .group_congrats_shipping_domicile,
      .group-congrats-shipping {
        width: 80%;

        .title-h2 {
          margin-bottom: em(5);
        }
      }
    }
  }
}

.group_congrats_shipping_domicile,
.group-congrats-shipping {
  a {
    font-size: $cow-font-14;
    font-weight: $cow-font-weight-bold;
  }

  h1 {
    margin-top: em(0);
    margin-bottom: em(4);
  }

  hr {
    margin: 0;
    border-bottom: 0;
  }

  #btn_bpp_check_coverage {
    margin: 0;
    text-align: left;
    height: em(32);
    line-height: em(32);
  }

  .group-media-object:nth-child(1) {
    margin-top: em(32);
  }

  .group-media-object:nth-child(2) {
    .andes-badge {
      margin-top: em(0);
    }
  }

  .group-media-object:nth-child(3) {
    margin-top: em(16);
    display: flex;
    justify-content: space-between;

    .group_congrats_shipping_domicile,
    .group-congrats-shipping {
      width: 80%;

      .title-h2 {
        margin-bottom: em(5);
      }
    }
  }

  .group-media-object:first-of-type {
    margin-bottom: em(0);

    .text + .text {
      margin-top: 0;
    }

    .andes-badge {
      margin-left: auto;
    }

    .group_congrats_shipping_domicile,
    .group-congrats-shipping {
      .andes-badge {
        margin-left: em(16);
      }
    }
  }

  .group-media-object {
    .group_congrats_shipping_domicile,
    .group-congrats-shipping {
      width: 80%;

      .title-h2 {
        margin-bottom: em(5);
      }

      .text {
        color: $cow-dark-gray;
      }

      .group-media-object {
        margin-top: 0;
      }

      .row-details .title {
        margin-top: 0;
      }

      .c-title {
        line-height: 1.4;
      }
    }

    .row-details .title {
      margin-top: em(7);
    }

    .text {
      margin-top: em(4);
    }

    .text + .text {
      margin-top: em(21);
    }

    .andes-badge {
      margin-left: em(16);
      padding: 0;

      .andes-badge__content {
        display: flex;
        align-items: center;
        column-gap: em(5);
        text-transform: uppercase;
      }
    }
  }
}

.group_congrats_shipping_domicile {
  .group-media-object .row-details .title {
    margin-top: 0;
  }
}

.separator {
  display: grid;
  background: $cow-black;
  opacity: 0.25;
  height: em(1);
  margin: em(16) 0;
}

.icon-wrapper {
  width: em(40);
  height: em(40);
  border-radius: 50%;
  border: solid 0.1px $cow-light-gray;

  div.payment-card-icon {
    zoom: 64%;
  }

  &.icon-mercado-pago-black {
    border: none;
  }

  &.icon-feedback-mp {
    width: $cow-spacing-56;
    height: $cow-spacing-56;
  }
}

#group_card_combination .ui-card {
  .title_2 {
    font-weight: $cow-font-weight-semibold;
    font-size: em(16);
  }

  .group-media-object + .group-media-object {
    margin-top: em(24);

    + .group-flex {
      margin-top: em(24);

      .title-h2 {
        font-weight: $cow-font-weight-regular;
        margin-bottom: 0;

        &:first-child {
          width: em(64);
        }
      }
    }
  }
}

#group_open_finance {
  .icon-wrapper {
    width: em(40);
    height: em(40);
    border-radius: 50%;
    border: solid 0.1px $cow-light-gray;
  }

  .ui-card {
    .group-media-object {
      .icon-wrapper {
        width: em(40);
        height: em(40);
        border-radius: 50%;
        border: solid 0.1px $cow-light-gray;

        svg {
          max-width: 60%;
          max-height: 90%;
        }

        img {
          max-width: 80%;
          max-height: 80%;
        }
      }
    }

    .text {
      color: $cow-translucent-dark-gray;
    }
  }

  .group-media-object:last-child {
    margin-top: em(32);
  }

  .row-details {
    .title {
      margin-top: 0;
    }
  }
}

#group_congrats_content {
  .ui-card {
    #group_card_media {
      div.row-instructions {
        div.row-details {
          margin-bottom: 0;

          .text:nth-child(n+3) {
            margin-top: em(16);
          }
        }
      }

      #credits_aft_details {
        margin-top: em(16);
        padding: 0  em(32) 0 0;

        .text {
          margin-bottom: 0;
        }

        #btn_credits_home {
          font-size: $font-size-14;
          margin: em(-8, 8) 0 0;
          width: fit-content;
        }
      }
    }
  }

  #download_app_ui_card {
    .ui-card {
      .row-instructions {
        padding: 0;

        .icon-wrapper {
          height: auto;
        }
      }
    }
  }
}
