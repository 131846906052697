.options-list {
  padding: 0;

  #select_installments &__item {
    min-height: em(0);
  }

  &__item {
    display: block;
    background: $cow-white;
    border-bottom: 1px solid $cow-gray-070;

    &:last-child {
      border: 0;
    }

    &.disabled {
      .group-media-object div:first-child .icon-wrapper,
      .group-media-object div:first-child.icon-installments {
        background-color: $cow-white;
      }

      .options-list__label {
        cursor: default;

        &:hover {
          background-color: $cow-white;
        }
      }

      &.item-account_money_row {
        background-color: $cow-lighter-gray;

        .options-list__label {
          .group-media-object div + div {
            width: 100%;

            .text_with_hint,
            .icon_with_tooltip {
              display: flex;
              justify-content: flex-end;

              .icon-wrapper {
                top: em(-16);
                width: 0;
                height: 0;
                border: none;
                display: block;
                position: relative;
                margin-right: em(8);

                svg {
                  max-width: em(20);
                  max-height: em(20);
                }
              }
            }

            .icon_with_tooltip {
              .icon-with-tooltip {
                flex-grow: 0;
              }

              .andes-tooltip__trigger {
                top: em(-12);
                margin: 0;
                position: relative;

                .icon-wrapper {
                  top: em(-16);

                  svg {
                    width: em(18);
                    height: em(18);
                  }
                }
              }
            }

            .text_with_hint {
              justify-content: space-between;

              .question-mark .icon-wrapper {
                cursor: pointer;

                svg {
                  width: em(20);
                  height: em(20);
                }
              }
            }
          }

          .icon-wrapper.icon-wrapper-disabled {
            background-color: $cow-medium-gray;
          }

          &:hover {
            background-color: $cow-lighter-gray;
          }
        }
      }

      &.item-account_money_combination_row {
        background-color: $cow-lighter-gray;
      }
    }

    &:not(.disabled, .with-action-text, .with-switch) {
      .options-list__label {
        &::before {
          background: inherit;
          border-bottom: em(1.75) solid $cow-light-blue;
          border-right: em(1.75) solid $cow-light-blue;
          bottom: 0;
          box-sizing: content-box;
          content: "";
          height: em(5);
          margin-bottom: auto;
          margin-top: auto;
          position: absolute;
          right: em(22);
          top: 0;
          transform: rotate(-45deg);
          width: em(5);
        }

        &:focus {
          outline: 0;
        }
      }
    }

    &.with-action-text .options-list__label .group-media-object div {
      &:nth-child(2) {
        flex-grow: 1;
      }

      & .andes-badge {
        flex-grow: initial;
      }

      &:nth-child(3) .text {
        color: $cow-light-blue;
        font-size: $cow-font-14;
        font-weight: $font-weight-semibold;
        line-height: em(18, 14);
        padding-left: em(10, 14);
      }
    }

    &.with-switch {
      .options-list__label .group-media-object > div:nth-child(3) {
        display: flex;
        flex-direction: row-reverse;
        flex: 1;
      }

      :hover {
        background-color: $cow-white;
      }
    }

    &.item-debit_and_prepaid_card_row {
      .andes-badge {
        position: absolute;
        margin: 0;
        top: 0;
        right: 0;
        height: em(18);
        padding: em(5);
        background: $cow-lighter-blue;

        .andes-badge__content {
          font-weight: bold;
          padding: em(2);
          text-transform: uppercase;
          color: $cow-white;
        }
      }
    }
  }

  &__item.with-bottom-row {
    height: auto;

    .options-list__bottomRow {
      background-color: $cow-lighter-gray;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 em(24) 0 em(20);
      font-size: $cow-font-12;

      & > div.icon-wrapper {
        border: none;
        margin: 0;
      }

      .text {
        color: $cow-dark-gray;
        font-size: $cow-font-12;
        padding: em(8) 0;
      }
    }
  }

  &__label {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    transition: background 120ms ease-out;

    &::after {
      position: absolute;
      left: em(5);
      top: em(1);
      bottom: em(1);
      z-index: -1;
      width: 100%;
      padding: em(1, 18);
      margin: em(-1, 18);
      background: #ebebeb;
      box-sizing: content-box;
      content: "";
      transform: scaleX(0);
      transform-origin: left center;
    }

    :checked + &:not(.with-switch) {
      z-index: 99;
      box-shadow: inset em(4) 0 0 0 $cow-lighter-blue;
      background: $cow-lighter-gray;

      [data-loading="true"] &::after {
        @include loader-step-1;
      }

      &.progress-done::after {
        @include loader-step-2;
      }
    }

    &.with-switch {
      cursor: default;
      height: em(52);

      .andes-switch {
        bottom: em(10);
        padding-right: 0;
      }
    }
  }

  &__item.with-bottom-row &__label {
    height: em(74);
  }

  &.max-elements {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }

  .icon-wrapper {
    width: em(40);
    height: em(40);
    margin-right: em(16);
    border-radius: 50%;
    border: solid 1px $cow-gray-070;

    svg {
      max-width: em(40);
      max-height: em(40);
    }

    svg.icon-pix-outline,
    svg.icon-boleto {
      max-width: 24px;
      max-height: 24px;
      color: $andes-gray-250-solid;
    }

    img {
      max-width: 64%;
    }

    &.icon-mercado-credito svg {
      max-width: em(40);
      max-height: em(40);
    }

    &.icon-mercado-pago-black,
    &.icon-account-money-yellow {
      border: none;

      svg {
        max-width: unset;
        max-height: unset;
      }
    }

    .icon-mercado-pago:not(.login-incentive-mp-logo) {
      max-width: em(24);
      max-height: em(24);
    }
  }

  .payment-card-icon {
    transform: scale(0.64);
  }

  &.ui-card {
    &.select_payment_method {
      box-shadow: none;
    }

    &.select_personalized_payment_options + .title-h2 {
      font-size: em(14);
      margin-bottom: em(8);
      margin-top: em(16);
    }

    &.combination_row {
      & ~ .title-h2 {
        margin-bottom: em(16);
        font-size: em(16);
      }

      .options-list__item {
        min-height: em(52);

        .options-list__label.with-switch {
          .group-media-object {
            div:first-child {
              width: 100%;
            }

            .row-details {
              .title {
                font-weight: $cow-font-weight-regular;
                color: $cow-translucent-dark-gray;
              }
            }
          }
        }
      }
    }
  }

  &.select_extra_info_row {
    .options-list__item {
      height: 100%;

      .options-list__label {
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        padding: em(24) em(32) em(8) em(32);

        div.group-media-object:first-child {
          padding-bottom: em(16);
        }
      }
    }
  }

  .icon-wrapper {
    svg {
      color: $andes-accent-color;
    }
  }

  &.rebranding {
    .icon-wrapper {
      svg {
        color: $andes-text-color-primary;
      }
    }
  }
}

:not(.legacy-mp-theme) {
  .options-list {
    padding: 0;
  }
}
