.step_login, .step_login_irreversible {
  div.layout--modal {
    .layout-main {
      background-color: $andes-bg-white;

      .login {
        margin: auto;
      }
    }
  }
}

.step_login_irreversible {
  div.layout--embed {
    .layout-main {
      background-color: $andes-bg-white;

      .login {
        margin: 15% auto;
      }
    }
  }
}

.login {
  border-radius: em(4);
  overflow: hidden;
  width: 368px;

  .login-header {
    text-align: center;
  }

  .icon-wrapper {
    width: unset;
    svg {
      height: 55px;
      width: 80px;
      max-width: 80px;
    }
  }

  .login-titles {
    color: $andes-text-color-primary;
  }

  .c-title {
    line-height: 1.1;
    padding: 24px 0 40px 0;
    margin: auto;
    max-width: 356px;
  }

  .login-content {
    display: flex;
    justify-content: center;

    .andes-button {
      display: block;
      width: 100%;
      margin: 0 auto 8px auto;
      text-align: center;
    }

    #loginForm {
      width: 278px;
    }
  }
}

.login-redirect-iframe-wrapper {
  .login {
    margin-top: em(20);

    .login-header {
      height: 50%;
    }
  }
}
