.group-summary {
  position: relative;

  &__container {
    position: relative;
    width: 100%;
  }

  .title-h2 {
    font-size: em(16);
    font-weight: 600;
    line-height: 1.31;
  }

  hr {
    border: 0;
    height: em(1);
    width: 100%;
    display: block;
    background: $cow-medium-gray;
  }
}
