.transition-redirect {
  display: flex;

  .ui-loading__container {
    font-size: $cow-font-16;
    width: em(32);
    height: em(64);

    .ui-loading__spinner {
      margin-top: em(16);
      width: em(32);
      height: em(32);
    }
  }

  .icon-wrapper {
    &.icon-arrow-tail,
    &.icon-arrow-head {
      width: em(48);
      height: em(64);
    }
  }

  .cow-loader {
    width: 34px;
    display: flex;
    align-items: center;
    background: transparent;
  }
}
