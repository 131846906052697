.cow-secure-fields {
  .andes-form-control__field {
    // Overrides Andes Styles for Secure Fields
    all: unset;
    // ==
    padding: 0 em(12);
  }

  .andes-form-control__bottom {
    min-height: em(20);

    .andes-form-control__message {
      line-height: em(18, 13);
    }
  }

  .icon-card-cvv-grey {
    margin-right: em(16);
  }

  iframe {
    display: block;
  }

  .cow-secure-field-card-icon {
    margin-right: em(5);

    &.icon-card--default {
      .default-card-icon {
        height: em(24);

        svg {
          width: 100%;
          height: em(24);
        }
      }
    }

    &.icon-card--pm {
      border: em(1) solid $cow-translucent-light-gray;
      border-radius: em(4);
      padding: em(4);
      justify-content: center;
      display: flex;
      align-items: center;
      overflow: hidden;
      background: white;
      width: em(30);
      height: em(25);

      img {
        display: block;
        max-width: em(25);
        max-height: em(15);
        width: auto;
        height: auto;
      }
    }
  }

  // fix for icon cvv + secure fields in express
  &.security-code {
    .icon-card-cvv {
      width: em(34);
      height: em(24);
      margin-right: em(10);

      svg {
        width: 100%;
        height: em(24);
      }
    }
  }

  .security-code-tooltip {
    .andes-tooltip__trigger {
      margin-right: $cow-spacing-8;

      .icon-wrapper {
        .with-custom-color {
          color: $andes-blue-500;
        }
      }
    }
  }
}
