@import "./shared";

.toggle-button-wrapper {
  position: absolute;
  right: em(32);
  top: 29px;
  margin: 0;

  .toggle-button {
    padding-right: em(12);
  }
}
