.group-legals {
  > a {
    color: $cow-light-blue;
  }

  .group-generic > a {
    color: $cow-light-blue;
  }
}

body:not(.legacy-mp-theme) .group-legals {
    .terms {
      p a {
        color: $andes-accent-color;
        text-decoration: none;
      }
    }
  }
