@import "shared";

.c-title {
  margin-bottom: em(16);
}

.group_form_scroller {
  .ui-card {
    margin-bottom: em(24);
  }

  button.andes-button {
    margin-top: em(16);
  }
}

.ui-card {
  padding: em(24);
}

.andes-form-control {
  margin-bottom: em(12);

  .andes-form-control__control {
    #input_street {
      width: em(263);
    }

    #input_street_number {
      width: em(130);
    }
  }
}

.group-flex {
  .andes-form-control:first-child {
    margin-right: em(6);
  }

  .andes-form-control:last-child {
    margin-left: em(6);
  }
}

.layout--modal {
  .layout__col-content {
    width: 66%;
  }

  .andes-form-control__control {

    span.andes-form-control__label:first-child {
      margin-bottom: em(4);
    }

    #input_street {
      width: em(223);
    }

    #input_street_number {
      width: em(77);
    }
  }
}
