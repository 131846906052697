.animated-group {
  &.overlay_and_expand {
    .animated-content-container{
      display: none;
      transition: display 0.5s allow-discrete;
    }
    .animated-content {
      position: relative;
      height: 0;
      max-height: 0;
      transition: max-height 0.5s, height 0.5s;
      overflow: hidden;
    }

    .content {
      display: block;
      opacity: 1;
      transition:
        display 0.5s allow-discrete,
        opacity 0.5s;
    }

    .content .animated-content {
      opacity: 1;
    }

    &.animate .animated-content {
      max-height: em(350);
      height: em(350);
    }

    &.animate {
      .animated-content-container{
        display: block;
      }
      .content {
        display: none;
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &.disabled.animate {
    button {
      pointer-events: none;
    }
  }

  &.disappear {
    display: none;
  }
}

.legacy-mp-theme{
  .animated-group {
    &.overlay_and_expand {
    &.disabled.animate {
      button {
        background: $cow-light-gray;
        border-color: $cow-light-gray;
        color: $cow-white;
      }
    }
    }
  }
}
