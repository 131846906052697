@import "shared";

.layout--modal {
  .group-flex {
    .icon-wrapper.icon-facetec-unico {
      margin-bottom: em(8);
      width: auto;
    }

    p.text {
      font-size: $cow-font-14;
    }
  }
}
