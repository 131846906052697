@import "default.scss";

.review-saved-card-item {
  padding: 24px 32px;
  .andes-list__item-first-column {
    padding: 0;
  }
}

ul + .andes-card__footer,
ul + .andes-card__footer .andes-card__footer__content {
  padding: 0;
}

.review-express__collapsed-content.has-content {
  padding: $cow-spacing-16 $cow-spacing-32 $cow-spacing-24 !important;
}

.single_installment-notification-message {
  margin-bottom: 24px;
}

#ReviewCardListV2BottomContent > div.installments-selector > div > div > button {
  margin-top: 3px;
  margin-bottom: 0px;
}

#consumer_credit_row > button > div > div.andes-list__item-asset > .andes-thumbnail-container > .andes-thumbnail--circle {
  border: 0;
}

.review-express {
  &__collapsed-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding: 0;

    & > div:first-child {
      width: 35%;
      margin-right: $cow-spacing-16;
    }

    & > div:last-child {
      width: 65%;
    }

    & > div:only-child {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__consumer-credits {
    &__summary {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      width: 100%;

      & > span:first-child {
        margin-top: $cow-spacing-4;
        margin-bottom: $cow-spacing-16;
      }
    }

    &__footer {
      line-height: 15px;
      margin-top: $cow-spacing-24;
    }

    &__modal {
      &__content {
        width: em(720);
        height: em(400);

        iframe {
          display: block;
          border: solid em(1) $cow-translucent-lighter-gray;
        }
      }
    }
  }

  &__change-payment-method {
    border: none;
    padding: $cow-spacing-24 $cow-spacing-32;

    &--single {
      border-top: 1px solid $cow-translucent-lighter-gray;
    }
  }

  &__bank_interests {
    margin: $cow-spacing-16 0;
  }
}
