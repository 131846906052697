.c-title {
  font-size: em(18);
}

.group-split-card-review {
  .payment-method-info {
    border-radius: em(6);
  }

  .group-generic-block {
    box-shadow: unset;

    .options-list {
      background-color: transparent;
      box-shadow: unset;
      margin-bottom: 0;

      &__item {
        background-color: transparent;

        #first_amount_row {
          &:checked + .options-list__label {
            box-shadow: unset;
            background-color: transparent;
          }
        }
      }
      &__label,
      &__label:hover,
      &__label:focus {
        background-color: transparent;
        padding: 0;

        .group-media-object {
          align-items: unset;

          .text {
            text-align: right;
            color: $andes-accent-color;
            font-weight: $cow-font-weight-semibold;
            font-size: $cow-font-16;
          }
        }
      }
    }
  }
}

.group-split-card-review:nth-of-type(2) {
  margin-top: em(36);
}

.icon-with-tooltip {
  &__content {
    margin-bottom: 0;
    width: em(260);
    padding: em(16);
    .andes-tooltip {
      &__content {
        margin: 0;
      }
    }
  }
}

.tyc_consumer_credits {
  line-height: $cow-font-16;
  margin-top: em(25);
  text-align: center;
  span {
    color: $cow-dark-gray;
    font-size: $cow-font-12;
  }
}
