@import "shared";

.layout--modal {
  .layout__col-content {
    z-index: 100;

    div.group-generic {
      width: em(810);
      top: 0;
      left: 0;
      padding: em(24) em(140) 0 em(140);
      height: 100%;
      position: absolute;
      background: $cow-common-background;

      .c-title {
        margin-bottom: em(16);
      }
    }
  }
}

.layout__col-content {
  .ui-card-wrapper > .ui-card {
    margin-top: 0;
    padding: em(32);

    .group-flex {
      flex-direction: row;
      justify-content: space-between;
      gap: em(12);
    }
  }
}

.layout--redirect,
.layout--embed {
  .layout__col-content > .modal-content {
    .modal-wrapper {
      max-width: em(600);
    }
  }
}

.ui-card {
  overflow: inherit;

  .group-flex {
    .andes-dropdown,
    .andes-form-control {
      width: 50%;
    }

    .andes-dropdown:first-child,
    label:first-child {
      margin-right: em(8);
    }

    .andes-dropdown:nth-child(3),
    label:last-child {
      margin-left: em(8);
    }
  }

  .autofiller {
    margin-bottom: em(12);
  }
}
