.ordered-list {
  li {
    display: flex;
    align-items: baseline;

    .order-list-item {
      .row-instructions {
        margin: 0;

        .layout__col-content {
          padding: 0;

          & > .text:first-child {
            display: block;
            margin-bottom: em(18);
          }
        }

        .title-h3 {
          padding-left: em(10);
          margin-bottom: em(18);
        }
      }
    }
  }

  .order-list-number {
    min-width: em(14);
    display: inline-block;
    text-align: left;
    font-weight: 300;
    font-size: 0.875em;
    vertical-align: top;

    &::after {
      content: ".";
    }
  }

  .text {
    display: inline-block;
    vertical-align: top;
  }
}
