.group-menu {
  position: relative;
  display: inline-block;
  font-size: $cow-font-16;
  width: em(38, 16);

  &__button {
    font-size: em(12);
    cursor: pointer;
    margin-top: em(4, 12);
    margin-left: em(6, 12);
    background-color: inherit;
    border: none;
    margin-right: em(-2, 12);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.21429em rgb(0 158 227 / 15%);
    }

    span {
      position: relative;
      top: -3;
      display: inline-block;
      width: 7px;
      height: 7px;
      border-bottom: 2px solid $cow-dark-gray;
      border-right: 2px solid $cow-dark-gray;
      transform: rotate(45deg);
      margin-left: 8px;
    }

    .icon-wrapper {
      width: unset;
    }
  }

  &__popup {
    text-align: center;
    position: absolute;
    top: em(42);
    right: em(-8);
    z-index: 9;
    width: em(260);
    background: $cow-white;
    border-radius: em(6);
    box-shadow: 0 em(3) em(6) 0 $cow-translucent-light-gray, 0 em(1) em(1) 0 $cow-translucent-lighter-gray;
    animation: fade-in 70ms ease-in;

    &::before {
      position: absolute;
      top: em(-6);
      border-top: solid em(1) $cow-translucent-lighter-gray;
      border-left: solid em(1) $cow-translucent-lighter-gray;
      right: em(12);
      width: em(12);
      height: em(12);
      background: $cow-white;
      display: block;
      transform: rotate(45deg);
      content: "";
    }

    &-option {
      padding: em(16);

      &:not(:first-child) {
        border-top: em(0.5) solid $cow-translucent-light-gray;

        .user-logout {
          background: none;
          border: none;
          padding: 0;
          color: $andes-accent-color;
          cursor: pointer;
          font-size: em(16);
          font-family: inherit;
        }
      }
    }
  }
}

#group_user_header_ml{
  .group-menu {
    width: 32px;
  }
}

#group_user_header_ml {
  .group-menu {
    &__button {
      margin-left: 0;
    }
    .icon-more-options > .with-custom-color{
      color: $cow-black;
    }
  }
}
