.zipcode-finder {
  .ui-card-wrapper > .ui-card {
    .andes-dropdown {
      margin-right: 0;
    }

    .group-flex {
      flex-direction: row;
      justify-content: space-between;

      .andes-form-control--textfield.ui-input-text:only-child {
        width: 100%;
      }
    }

    .andes-button--loud {
      align-self: flex-start;
      float: unset;
      margin-top: em(12);
    }
  }
}

.layout--redirect,
.layout--embed {
  .modal-wrapper {
    .ui-card {
      box-shadow: none;
    }
  }
}
