.ui-card {
  padding: 32px;
  margin-top: 2px;
  margin-bottom: 24px;

  input::placeholder {
    color: rgba(0, 0, 0, 0.55);
  }

  .card-number,
  .input-card-number {
    margin-bottom: 32px;
  }

  .input-fullname {
    margin-bottom: 32px;
  }

  &:has(.andes-message) {

    .card-number,
    .input-card-number {
      margin-top: 32px;
    }

    .andes-message__content {
      width: auto;
    }
  }
}