.andes-dropdown__arrow::after {
  border-right: em(2) solid $cow-lighter-blue;
  border-bottom: em(2) solid $cow-lighter-blue;
}

.card-form-group {
  .group-generic:empty {
    display: none;
  }
}

.split-amount__input {
  &.andes-form-control--error {
    .andes-form-control__message {
      min-height: em(14);
    }
  }
}

.andes-form-control--disabled .andes-form-control__border {
  background-size: auto;
  background-color: rgba($cow-black, 0.25);
  background-image: none;
}
