.qr-controller {
  margin-bottom: em(16);

  .spinner-wrapper {
    height: em(16);

    .andes-progress-indicator-circular__container {
      flex-direction: row;
    }
  
    .andes-progress-indicator-circular__icon {
      width: em(16);
      height: em(16);
      left: em(8);
    }

    .andes-progress-indicator-circular__label {
      margin-left: em(12);
    }
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:empty) {
      margin: 0 0 em(8) 0;
    }

    form {
      &:first-child:not(:last-child) {
        margin-right: em(4);
      }

      &:last-child:not(:first-child) {
        margin-left: em(4);
      }
    }
  }
}

.qr-code-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: em(8);

  &.expired {
    .qr-code {
      opacity: 0.3;
    }
  }

  .qr-code {
    padding: em(18) em(28) em(12) em(28);
  }

  .qr-container {
    border: 1px solid $andes-gray-070;
    border-radius: em(4);
    margin-bottom: em(8);

    .brand-wrapper {
      padding: em(8) em(32);
      border-bottom: em(1) dashed $andes-gray-070;
      font-size: em(12);

      .brand {
        padding: 0;
        position: relative;
        display: flex;
        justify-content: center;

        .brand__img {
          width: em(32);
          height: em(32);
        }

        .brand__name.brand__name__small {
          font-size: em(20);
        }
      }
    }

    .price-wrapper {
      text-align: center;
      padding-bottom: em(8);

      h1 {
        font-size: em(24);
        font-weight: $cow-font-weight-semibold;
      }
    }
  }
}

.layout__col-content {
  .custom-medium {
    min-width: unset;
    font-size: em(14);
    font-weight: $cow-font-weight-semibold;
    line-height: em(37);
    height: em(37);
  }
}