@import "shared";

div.optimus div.group-total-summary {
  .group-summary {
    background-color: $cow-lighter-gray;
  }

  .andes-button__content {
    white-space: nowrap;
  }

  .title-h2 {
    font-size: em(18);
    font-weight: normal;
  }

  hr+.row-summary+.row-details .text {
    width: 70%;
    margin-top: em(-20);
  }

  hr+.row-summary+.row-details+.text.centered {
    margin-top: em(10);
  }

  .group-summary__container {
    &>.group-generic:first-of-type {
      padding: em(24) em(32);
    }

    .row-summary--discount .row-summary__discount:first-child {
      padding-right: em(5);
    }
  }

  .andes-button {
    margin-top: em(48);
    padding: 0;
  }

  #pay {
    margin-top: 16px;
  }

  .andes-button+p.text.gray,
  p.text.gray~p.text.gray,
  form.group_form+p.text.gray,
  .group-captcha+p.text.gray {
    text-align: center;
    font-size: em(12);
    line-height: em(16);

    &:not(:last-child) {
      margin-bottom: em(6);
    }
  }

  .group-row {
    padding: em(16) em(32);
  }
}