@import "shared";
@import "shipping-user-type";
@import "shipping-zipcode-form";
@import "shipping-type";
@import "shipping-review";
@import "shipping-address-selector";
@import "shipping-address-form";

.list-desktop {
  .andes-list__item {
    padding: 16px 32px;

    .andes-list__item-action {
      .andes-list__item-first-column {
        padding: 0 !important;
      }
    }
  }
}
