&:not(.legacy-mp-theme) {
  .andes-card.input-amount-card {
    .andes-card__content {
      .icon-wrapper {
        &.icon-account-money-yellow, &.icon-mercado-credito {
          border: 0;
        }
      }
    }
  }
}

#group_content {
  margin-top: 40px;
  .c-title {
    font-size: $cow-font-14;
    font-weight: $cow-font-weight-regular;
    color: $andes-gray-550;
    margin-bottom: 8px;
    margin-top: 40px;
  }

  .title-h2 {
    font-size: $cow-font-18;
    color: $cow-translucent-dark-gray;
    margin-bottom: 16px;
  }
}

.andes-card.input-amount-card {

  .andes-card__content {
    .payment-method-info {
      .description {
        margin-bottom: 32px;
      }
    }

    .split-amount__input_container {
      .progress-container {
        margin-top: 32px;
      }
    }
  }
}

.cow-button-back-to-site {
  margin-top: 24px;
}
