.feedback-screen {
  height: auto;
}

.feedback-screen.andes-feedback-screen--no-body {
  background: none;

  .andes-feedback-screen__wrapper {
    .icon-wrapper {
      svg {
        height: em(25.5, 28);
      }
    }
  }
}
