@import "shared";

.user-title + .scroller {
  top: em(146);
}

.layout--redirect {
  .modal-content.group-modal {
    .modal-wrapper {
      max-width: 70vh;

      .inner-wrapper {
        padding: em(48);

        .header {
          margin-bottom: em(8);
          padding: 0;
        }

        .group-flex {
          h2.title-h3 {
            margin-bottom: em(12);
          }

          .andes-button--loud {
            margin-top: em(8);
          }
        }
      }
    }
  }
}

// when opening a modal in modal layout
// we'll try to emulate a nested page look and feel
.layout--modal {
  .modal-content.group-modal {
    background-color: $cow-common-background;
    position: fixed;
    width: em(812);
    height: em(566);
    left: 50%;
    top: em(16);
    transform: translate(-50%);
    border-radius: em(6);

    .modal-wrapper {
      max-width: unset;

      .inner-wrapper {
        padding: em(48);

        .header {
          display: none;
        }

        .group-flex {
          margin-top: em(120);
          padding: 0 em(32);

          h2.title-h3 {
            margin-bottom: em(12);
          }

          .andes-button--loud {
            margin-top: em(8);
          }
        }
      }
    }
  }
}


#unprotected-purchase {
  margin-bottom: em(16);
  p {
    color: $cow-text-color-secondary;
    font-size: $cow-font-14;
  }

  svg {
    width: em(11);
    height: em(18);
    margin-left: em(4);
  }
}

.options-list__item {
  &:not(.disabled, .with-action-text, .with-switch) {
    .options-list__label::before {
      right: em(34);
    }
  }
}

.item-mp_login_row {
  .andes-badge {
    top: em(4);
  }
}

.group-flex > .title-h2 {
  margin-bottom: em(4);

  + div.andes-badge.badge {
    margin-left: em(16);
    margin-top: em(3);
    padding: em(1) em(5);
    height: min-content;

    .andes-badge__content {
      font-weight: $cow-font-weight-bold;
      padding: em(2) em(1) em(1);
      svg {
        vertical-align: middle;
        margin-bottom: em(1.5);
      }
    }
  }
}

#group_sidebar {
  .group-summary {
    padding: em(6) em(16);
  }

  .row-summary__text {
    font-weight: $cow-font-weight-regular;
  }
}

// Supposed to match with page subtitle. In this case it follows a title with a badge on its side
.group-flex + p.text:first-of-type {
  color: $cow-black;
  margin-bottom: $cow-font-16;
}

#sub-title-guest.text {
  color: $cow-text-color-secondary;
  font-size: $cow-font-14;
}

.c-title {
  margin-bottom: em(25.6);
}

