@import "shared";

.row-summary {
  margin-bottom: em(12);
  font-weight: 300;
}

.row-summary__text {
  word-break: break-word;
}

.row-summary--pay {
  font-weight: 400;
  flex-wrap: wrap;
}

.two-lines-row {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: em(16);
}

.product-name-tooltip {
  max-width: em(270);
}

.step_first_split_payment,
.step_second_split_payment,
.step_review_split_payment {
  .group-summary .row-summary {
    flex-wrap: wrap;
    font-weight: 300;
    font-size: em(16);

    &:first-of-type,
    &:last-of-type {
      .row-summary__text,
      .row-summary__price {
        font-size: em(16);
      }
    }

    .row-summary__text {
      font-size: em(16);

      &.two-lines-row {
        width: min-content;
      }
    }

    .row-summary__price {
      font-size: em(16);
    }

    .row-summary__price + .row-summary__text_promo {
      width: 100%;
      text-align: right;
      font-size: em(14);
    }

    .title-h2:last-of-type {
      font-size: em(18);
      font-weight: 400;
    }
  }
}
