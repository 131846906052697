.access-denied {
  padding-top: em(24);
  padding-bottom: em(16);
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  &__icon {
    display: block;
    margin-bottom: em(24);
  }

  .button-link {
    margin-top: em(24);
  }

  h1 {
    font-size: em(20);
    margin: 0 auto;
    margin-bottom: em(5);
  }

  h2 {
    width: em(302);
    margin: 0 auto;
    height: em(40);
    font-size: em(16);
  }
}
