.layout {
  background-color: $cow-white;
  height: 100vh;

  .icon-wrapper {
    &:not(.icon-arrow-tail) {
      &:not(.icon-arrow-head) {
        &:not(.icon-success){
          border: 1px solid $cow-translucent-transparent-gray ;
          box-shadow: unset !important;
        }
      }
    }
  }
}