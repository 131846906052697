form {
  > .group-row,
  > .group-generic .options-list {
    margin-bottom: em(8);
  }

  > .group-generic .options-list li {
    .group-media-object > div:last-of-type {
      margin-left: auto;

      .icon-wrapper {
        margin-right: 0;
        width: em(16);
      }
    }
  }
}

.item-login_user_row_id {
  label .group-media-object :nth-child(2) {
    font-weight: $cow-font-weight-regular;

    .row-details :nth-child(2) {
      color: rgba($cow-black, 0.55);
      padding-top: em(4);
      font-size: em(14);
    }
  }

  label .group-media-object :nth-child(3) {
    top: em(-4);
    left: 0;
    width: 100%;
    display: flex;
    position: absolute;
    flex-direction: row-reverse;

    .andes-badge {
      padding: 0;

      .andes-badge__content {
        font-weight: $cow-font-weight-bold;
        display: flex;
        align-items: center;
        column-gap: em(5);
      }
    }
  }
}
