.collapsible-selector-container.payment-option-selector {
  font-weight: 400;
  line-height: 1;
  background-color: $cow-white;

  .options-list.ui-card {
    width: 100%;
  }

  .select_mp_cards {
    .group-media-object .text {
      color: $cow-translucent-medium-gray;
      font-size: em(14);
    }
  }

  &.folded  {
    .group-media-object > .icon-wrapper {
      margin-right: em(20);
    }
  }

  .group-media-object > div:nth-of-type(3) {
    margin-left: auto;

    > .icon-wrapper {
      margin: 0;
      width: em(24);
      transform: rotate(90deg);
    }
  }

  &.unfolded {
    .group-media-object > div:nth-of-type(3) {
      > .icon-wrapper {
        transform: rotate(0deg);
      }
    }
  }

  .options-list__item.title {
    padding: em(14) 0 em(14) em(24);
  }

  .andes-list__item {
    font-size: em(16);
    padding: em(7) em(16) em(7) em(16);

    .row-details {
      .text_title {
        font-size: em(16, 16);
      }
    }

    .options-list.ui-card {
      margin: 0;
      box-shadow: none;

      label {
        padding: 0;
      }
    }
  }
}
