#logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: em(32) em(16) em(16) em(16);

  .icon-wrapper {
    width: em(40);
  }
}

.c-title {
  margin-top: em(20, 16);
  margin-bottom: em(22, 16);
  text-align: center;
  color: rgb(0 0 0 / 90%);
  font-weight: 600;
  font-size: em(16);
  line-height: em(22, 16);
}

.title-h2 {
  margin-top: em(22, 16);
  margin-bottom: em(12, 16);
  color: rgb(0 0 0 / 55%);
  font-size: em(12);
  line-height: em(15, 16);
  text-transform: uppercase;
  font-weight: 400;
}

.item-mp_payment_methods {
  height: auto;
}

.item-mp_payment_methods .options-list__label {
  padding-top: em(20);
  height: auto;
}

.title_with_bpp {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  right: 0;
  top: 0;
  padding: em(1) em(4) em(1) em(4);
  background: rgb(0 166 80 / 10%);
  border-radius: 0 0 0 em(5);
}

.title_with_bpp span {
  color: $cow-color-success;
  font-weight: 600;
  font-size: em(11);
  line-height: em(11, 16);
}

.title_with_bpp svg {
  width: em(12) !important;
  height: em(12) !important;
  margin-left: em(4) !important;
}

.user_pill {
  margin-top: 10px;
}

.item-mp_payment_methods .row-details .title {
  color: rgb(0 0 0 / 90%);
  font-weight: 400;
  font-size: em(16);
  line-height: em(20);
}

.payment-methods-list {
  border-top: 1px solid rgb(0 0 0 / 8%);
}

.andes-tag__avatar-icon-container,
.andes-tag__avatar-text-container {
  background-color: transparent;
}

.andes-tag__avatar-icon-container > * {
  height: $cow-font-24;
  width: $cow-font-24;
}

.andes-tag--large .andes-tag__label {
  font-size: $cow-font-12;
  max-width: 55vw;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(0 0 0 / 55%);
}
