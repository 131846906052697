@import "shared";

#group_content {
    padding-top: em(32);

    .ui-card-wrapper {
        .ui-card {
            width: em(540);
        }
    }
}
