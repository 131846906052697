@import "shared";

.form_personal_info_pse {
  .form_personal_double_field {
    input {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .form_personal_field {
      width: 100%;
      margin-bottom: 0;

      &:first-child {
        margin-right: $cow-spacing-8;
      }

      &:last-child {
        margin-left: $cow-spacing-8;
      }

      &:only-child {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
      }
    }

    .form_personal_field_phone {
      width: 35%;
    }

    .form_personal_field_email {
      width: 65%;
    }
  }
}

.rebranding {
  &__title {
    margin: $cow-spacing-16 0 $cow-spacing-32;
  }
}
