@import "shared";

.group-media-object .icon-wrapper {
  margin-right: em(24);
}

.step_review,
.step_payment_option_form {
  .group-media-object {
    &:only-child {
      .row-details {
        .title {
          max-width: em(380);
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    &:not(:only-child) {
      .row-details {
        .text {
          max-width: em(322, 14);
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .layout--modal {
    .group-media-object {
      &:only-child {
        .row-details {
          .title {
            max-width: em(334);
          }
        }
      }

      &:not(:only-child) {
        .row-details {
          .text {
            max-width: em(280, 14);
          }
        }
      }
    }
  }
}
