.list .list-item {
  margin-bottom: em(16);

  .options-list.ui-card {
    min-height: em(94);
    max-height: em(94);
  }

  .options-list__label {
    min-height: em(94);
    max-height: em(94);
    padding-top: em(34);
    padding-bottom: em(38);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

// Group list shadows
.group-list {
  .andes-list {
    border-radius: em(4);
    box-shadow: 0 0.07em 0.125em 0 $cow-translucent-light-gray;
  }
}

.group-list-wrapper,
.group-list {
  margin-bottom: em(24);
}
