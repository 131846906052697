@import "shared";

.options-list.select_extra_info_row .options-list__item .options-list__label {
  padding: em(16) em(32);
}

.form_yape {
  margin: em(16);
}

.group-summary {
  padding: 32px;
}


//Exclusive styles for legacy architecture
.optimus {
  .group-legals {
    margin-top: 24px;
    padding: 0;
    position: static;

    .terms {
      p {
        text-align: center;
        font-size: 12px !important;
      }
    }

    &+#form-yape {
      margin-top: 12px;
    }
  }
}

.layout--modal {
  button#form-yape {
    position: static;
  }
}
