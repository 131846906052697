@import "default.scss";

.top-row {
  margin-bottom: 32px !important;
}

.review-express {
  &__collapsed-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding: 0;

    & > div:first-child {
      width: 35%;
      margin-right: $cow-spacing-16;
    }

    & > div:last-child {
      width: 65%;
    }

    & > div:only-child {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    #installments-Mensualidades-trigger {
      .andes-dropdown__chevron {
        position: relative;
        top: 2px;
      }
    }

    .security-code-tooltip {
      .andes-tooltip {
        &__trigger {
          margin-right: 12px;
        }
      }
      .icon-wrapper {
        width: 16px;
      }
    }
  }

  &__consumer-credits {
    &__summary {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      width: 100%;

      & > span:first-child {
        margin-top: $cow-spacing-4;
        margin-bottom: $cow-spacing-16;
      }
    }

    &__footer {
      line-height: 15px;
      margin-top: $cow-spacing-24;
    }

    &__modal {
      &__content {
        width: em(720);
        height: em(400);

        iframe {
          display: block;
          border: solid em(1) $cow-translucent-lighter-gray;
        }
      }
    }
  }

  &__change-payment-method {
    border: none;
    padding: $cow-spacing-24 $cow-spacing-32;

    &--single {
      border-top: 1px solid $cow-translucent-lighter-gray;
    }
  }

  &__bank_interests {
    margin: $cow-spacing-24 0;
  }
}

/*
This is a fix for the footer that is not being pushed down by the
content when credits are selected just to express review
*/

.layout__col-footer {
  margin-top: 165px !important;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .layout__col-footer {
    margin-top: 210px !important;
  }
}

.andes-card {
  &__footer-arrow {
    width: 8px !important;
    height: 8px !important;
    margin-top: 6px !important;
  }
}
