@import "shared";

[class*="step_congrats_"] {
  .layout--redirect,
  .layout--embed {
    .layout-main {
      max-width: none !important;
      padding-left: 0;
      padding-right: 0;

      &:has(.credits-conditions) {
        max-width: em($layout-max-width + 64);
      }
    }
  }
}

[class*="step_congrats_"] {
  .layout--embed {
    .layout-main {
      .congrats--rejected,
      .congrats--approved,
      .congrats--instructions {
        .congrats-header {
          &__container {
            margin: 0;
            padding: 0;
            max-width: 100%;
            padding-left: em(16);
            padding-right: em(16);

            .congrats-titles {
              width: 100%;
            }
          }
        }

        #group_congrats_content,
        #congrats_content_group {
          padding-left: em(16);
          padding-right: em(16);
        }

        #group_congrats_content {
          .group-row:last-child {
            width: 100%;
          }
        }

        .scroller {
          > .group-row:last-child {
            width: 100%;
            padding-left: em(16);
            padding-right: em(16);
          }
        }
      }
    }
  }

  .row-instructions {
    #shield_footer {
      justify-content: start;
    }
  }
}

.congrats {
  width: 100%;

  .layout--modal & {
    border-radius: em(5);
    overflow: hidden;

    > .scroller {
      border-radius: em(5);
    }
  }

  .layout--embed & {
    max-width: em(646);
    margin: $gutter-32 auto 0 auto;

    .congrats-header {
      border-radius: em(4) em(4) 0 0;
    }
  }

  .ui-card {
    position: relative;
    width: em(580);
    margin: 0 auto;
    padding: em(32);
    margin-bottom: em(16);

    .layout--embed & {
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .with-track-event-wrapper {
    float: unset;
  }

  .congrats-content {
    .cow-button-back-to-site {
      margin-bottom: 0;
    }
  }
}

// Header Module
.congrats-header {
  margin: 0 auto;

  &__container {
    max-width: em(580);
    margin: 0 auto em(16);
    padding: 0 em(32);
  }
}

@mixin green-congrats-header-background {
  background: linear-gradient(
    180deg,
    rgba($cow-color-success, 1) 0%,
    rgba($cow-color-success, 1) em(24),
    rgba($cow-white, 0) em(96)
  );
  padding: em(24) 0 0;
}

@mixin green-congrats-footer-buttons {
  .group-row:last-child {
    width: em(580);
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: flex-start;

    #group_button_back_congrats {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .chevron-left {
        margin: 0 em(19);
      }

      .with-track-event-wrapper {
        margin-right: 0;
      }

      .andes-button--link {
        margin: 0;
        font-size: em(16);
      }
    }

    .andes-button--quiet {
      margin: 0 0 0 8px;
      font-size: em(16);
      min-width: em(142);
      width: auto;
    }
  }
}

//Modifiers
.congrats--approved {
  //group footer buttons
  @include green-congrats-footer-buttons;

  .congrats-header {
    @include green-congrats-header-background;

    &__container {
      margin: 0 auto;
    }
  }

  #group_congrats_content {
    min-height: em(200);
  }

  :not(.andes-message__buttons-container) > .andes-button--loud,
  :not(.andes-message__buttons-container) > .andes-button--link {
    display: block;
    margin: em(24, 18) auto;
    width: fit-content;
    max-width: em(260);
    text-align: center;
    font-size: em(16);
  }

  #message_component_with_activity_button {
    margin-top: $cow-spacing-28;
  }

  .andes-message__buttons-container > .andes-button--loud,
  .andes-message__buttons-container > .andes-button--link {
    height: $cow-spacing-32;
    margin-bottom: 0;
  }

  .group-generic {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .andes-button--loud {
      display: block;
      flex-grow: 1;
      margin-right: em(21, 18);
      width: em(178);
      text-align: center;
    }

    #voucher_button {
      margin: em(32) 0 0 em(65);
      font-size: em(16);
    }
  }

  #auto_return_row {
    width: em(580);
    margin: 0 auto;
    padding: 0;

    .autoreturn {
      display: flex;
      margin-left: em(37);

      .progress__small {
        margin-right: em(13);
      }
    }

    #group_button_back_congrats {
      .andes-button--link {
        margin: 0;
      }
    }
  }

  .ui-card {
    padding: em(24);

    .row-details {
      .title_2 {
        font-size: em(16);
      }

      .text {
        font-weight: 400;
        color: rgba($cow-black, 0.55);
      }
    }

    // Structure for QR Payment congrats
    .group-media-object + .group-media-object#group_qr_media {
      margin: em(24) 0;
    }
  }

  .group-split-congrats {
    .group-generic {
      width: em(580);
      margin: 0 auto;
      display: block;

      .andes-button {
        &--loud {
          display: block;
          flex: 0;
          margin: 0;
        }
      }
    }

    #group_card_ui {
      .ui-card {
        > .row-details {
          margin-left: em(64);
        }
      }
    }
  }

  .group-congrats-summary {
    .group-generic {
      margin: 0 0 0 em(24);
    }
  }

  #download_app_ui_card .ui-card {
    .row-instructions {
      margin-right: em(56);
      padding-top: em(24);

      .title-h2,
      .icon-mercado-pago-secondary svg {
        margin-top: em(4);
      }
    }

    .group-generic {
      width: auto;

      .qr-code {
        padding: 0;
      }

      .group-flex {
        width: 100%;
        margin-top: em(8);
        justify-content: space-between;
      }
    }
  }

  div.group-congrats-shipping > .group-media-object {
    .row-details + .andes-badge.andes-badge--pill.andes-badge--small {
      margin-left: 0;
    }
  }

  .group-congrats-shipping {
    #group_card_media {
      .group-congrats-shipping {
        position: relative;

        .andes-badge {
          position: absolute;
          left: -69px;
          top: 30px;
          min-width: 50px;
          margin: 0;

          p {
            display: block;
            text-align: center;
            font-size: 11px;
          }
        }
      }
    }
  }

  .congrats-content {
    #group_qr_media {
      svg {
        path {
          fill: $andes-text-color-primary;
        }
      }
    }
  }
}

.congrats--instructions {
  @include green-congrats-footer-buttons;

  .congrats-header {
    @include green-congrats-header-background;

    &__container {
      margin: 0 auto;
    }
  }

  .congrats-content {
    .ordered-list li {
      #number_wrapper {
        border: solid 0.1px $cow-black;
        border-radius: 50%;
        height: em(28);
        flex-grow: 0;
        flex-shrink: 0;
        width: em(28);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .group-button {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    .andes-button--loud,
    .andes-button--loud + .andes-button--link {
      min-width: em(190);
      text-align: center;
    }

    .andes-button--loud + .andes-button--loud {
      & + .andes-button--link {
        margin: em(24, 18) 0 0 0;
      }
    }
  }

  .row-instructions {
    .andes-tooltip__trigger {
      cursor: pointer;
    }

    button {
      cursor: pointer;
    }
  }
}

.congrats--recover {
  .scroller:has(form):has(#card_media_cvv) {
    height: 100%;
    padding-right: 0;
    overflow: hidden;
  }

  #group_redirect_form {
    position: absolute;
    bottom: em(32);
    left: em(200);
  }

  .group_form {
    display: inline-block;

    .group-media-object {
      margin-bottom: em(34);
    }

    .andes-button {
      margin-bottom: 0;
    }

    .andes-button--loud {
      display: block;
      clear: both;
    }

    .andes-button--quiet {
      margin-left: em(16);
    }

    .cow-secure-fields {
      width: em(200);
    }

    .input-cvv,
    .input-expiration-date,
    .cow-secure-fields.security-code,
    .cow-secure-fields.expiration-date {
      float: left;
    }

    .input-cvv {
      margin-bottom: em(32);
    }

    .input-expiration-date,
    .cow-secure-fields.expiration-date {
      margin-right: em(32);
    }
  }
}

.step_congrats_remedy_offer {
  .group-button {
    display: inline-flex;
  }

  .andes-button--loud {
    width: fit-content;
  }

  .andes-button + .andes-button {
    margin-left: em(20);
  }

  .group-generic {
    margin: 0 auto;
    width: em(640);
    margin-top: em(30);

    .andes-button + .andes-button {
      margin-left: em(0);
    }
  }
}

.step_congrats_bad_security_code,
.step_congrats_bad_date {
  .congrats--recover {
    position: relative;

    .group-back-url {
      margin: 0 auto;
      width: em(580);
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .chevron-left {
        margin: 0 em(6);
      }

      .andes-button {
        margin: 0;
        line-height: em(14);
        display: flex;
        align-items: center;
      }
    }
  }
}

[class*="step_congrats_express_recover_"] {
  .layout--modal .congrats--recover .scroller {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-right: 0;
  }

  .group-generic .group-generic-block {
    form#group_pay .ui-card-wrapper {
      #installments_group_generic .icon-wrapper {
        margin-right: em(23);
      }

      &#cvv_row .ui-card {
        padding-top: 0;
      }
    }
  }

  .congrats--recover {
    .installments_row {
      .options-list__label {
        padding: 0;
      }

      .group-media-object {
        margin: 0;
      }

      .options-list__item {
        min-height: min-content;
      }
    }

    #installments_group_generic {
      .select-installments {
        #installments_select_express {
          max-width: em(270);
        }
      }
    }
  }
}

[class*="step_congrats_express_recover_consumer_credits"] {
  .congrats--recover {
    #ui_card_group .ui-card {
      #group_pay {
        #installments_select_credits {
          max-width: em(320);
        }
      }

      .row-instructions {
        .group-button {
          .animated-group {
            button {
              width: max-content;
              min-width: auto;
            }
          }
        }
      }
    }
  }
  .terms-and-conditions {
    &.remedies_terms_and_condition {
      width: 580px;
      margin: auto;
    }
  }
}

[class*="step_congrats_express_recover_account_money"] {
  .congrats--recover {
    #ui_card_group .ui-card {
      #group_pay {
        .animated-group {
          button {
            min-width: auto;
            width: max-content;
          }
        }
      }
    }
  }
}

.step_congrats_bad_date {
  .ui-card .group_form + .group_form {
    display: flex;
    position: relative;

    .andes-button--quiet {
      left: em(10);
      margin-left: 0;
      position: relative;
      top: 0;
    }
  }
}

.site-mlb.step_congrats_bad_date {
  .ui-card .group_form + .group_form {
    .andes-button--quiet {
      left: em(68);
    }
  }
}

.site-mlb.step_congrats_bad_security_code {
  .ui-card .group_form + .group_form {
    .andes-button--quiet {
      left: em(64);
      top: -0.1em;
      position: relative;
    }
  }
}

.step_congrats_bad_security_code {
  .group_form .andes-button--quiet {
    margin-left: em(8);
  }
}

.congrats--rejected {
  .congrats-header {
    background: linear-gradient(
      180deg,
      rgba($cow-color-error, 1) 0%,
      rgba($cow-color-error, 1) em(52),
      rgba($cow-color-error, 1) em(53),
      rgba($cow-white, 0) em(124)
    );
    padding: em(52) 0 0;

    .congrats-titles {
      margin-top: 0;

      #group_card_ui_top {
        .ui-card {
          padding: em(25.5) em(24);

          .group-row {
            p.text {
              color: rgba($cow-black, 0.55);
              font-size: $cow-font-16;
              margin-bottom: em(0);
            }
          }
        }
      }
    }
  }

  .congrats-content {
    margin-top: em(16);

    #group_card_ui {
      .ui-card {
        padding: em(24);
      }
    }
  }
}

.site-mlb.step_congrats_call_for_auth_authorize {
  #group_redirect_form {
    position: absolute;
    bottom: em(30);
    left: em(260);
  }
}

.step_congrats_call_for_auth_authorize,
.step_congrats_bad_security_code {
  .congrats {
    &--recover {
      .scroller {
        height: 100%;
        padding: 0;
      }
    }
  }
}

.step_congrats_call_for_auth,
.step_congrats_call_for_auth_authorize {
  #layout-modal {
    .congrats--recover {
      .scroller {
        overflow: hidden;
      }
    }
  }
}
