@import "shared";

.captcha-terms {
  position: absolute;
  bottom: em(22);
  left: 2em;
  right: 2em;
  display: flex;
  justify-content: center;
}

// Special styles for redirect layout
.redirect-view .captcha-terms {
  position: relative;
  bottom: 0;
  left: 0;
  margin-top: 0;

  &.logged {
    margin-top: em(32);
    margin-bottom: 24px;
  }
}
