.sub_step_consumer_credits_tyc {
  .layout {
    &.layout--redirect {
      padding-top: 0;
    }

    #button_back_arrow + h1.c-title {
      margin-left: em(16, 24);
    }

    .credits-conditions {
      background: $cow-white;
      padding: 0;

      &__iframe {
        border: 0;
        display: initial;
      }

      .message__content {
        color: $cow-translucent-medium-gray;
        font-size: em(14);
        margin: em(24) 0;
        text-align: center;
      }
    }
  }
}
