@import "shared";

.scroller {
  max-width: em(510);

  .ui-card {
    padding: em(24) em(24) em(32) em(24);

    #personal-info-name-row {
      .andes-form-control.andes-form-control--textfield {
        padding-top: em(20);

        .andes-form-control__control {
          max-width: em(223);
        }
      }
    }

    #personal-info-identification-row {
      padding-top: em(10);

      .group-identification {
        .andes-dropdown.andes-dropdown--form {
          padding-top: em(15);
        }

        .andes-dropdown__trigger {
          max-width: em(140);
        }

        .andes-form-control__control {
          max-width: em(306);
        }
      }
    }

    #personal-info-contact-row {
      padding-top: em(10);

      .andes-form-control--textfield label[for="phone_number"] ~ .andes-form-control__control {
        width: em(140);
        margin-right: em(16);
      }

      .andes-form-control--textfield label[for="email"] ~ .andes-form-control__control {
        width: em(306);
      }
    }
  }

  .group-flex .andes-form-control--textfield label[for="personal_first_name"] ~ .andes-form-control__control {
    width: em(270);
    margin-right: em(2.2);
  }

  .group-flex .andes-form-control--textfield label[for="personal_last_name"] ~ .andes-form-control__control {
    width: em(270);
    margin-right: 0;
  }

  #select {
    margin-right: em(6);
    width: em(150);
  }

  #entity_type {
    width: 100%;

    #entity_type-trigger {
      margin-bottom: em(0);
    }
  }

  .group-identification .input-document {
    width: em(390);
  }

  .group-identification__children {
    display: flex;
    justify-content: space-between;
    margin-top: em(10);
  }

  #entity_with_tooltip {
    display: flex;
  }

  .icon-with-tooltip {
    position: absolute;
    margin: em(-2) em(0) em(0) em(100);
  }

  #entity_type-label {
    display: inline;
  }
}

.layout__col-content .andes-button--loud {
  margin-left: em(7);
}
