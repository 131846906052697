.layout--modal {
  .congrats {
    .scroller {
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      overflow-y: scroll;
      padding: 0;
    }

    &--recover {
      .ui-card {
        margin-bottom: em(50);
      }
    }
  }
}

.congrats {
  &--recover,
  &--rejected {
    .ui-card {
      margin-bottom: 0;
    }

    .group-back-url {
      display: flex;
      width: em(580);
      margin: 24px auto 0;
      align-items: center;
      justify-content: flex-start;

      .chevron-left {
        margin: 0 em(6);
      }

      .andes-button {
        margin: 0;
        line-height: em(14);
        display: flex;
        align-items: center;
      }
    }
  }
}
