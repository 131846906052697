.group-row-banner-container {
  display: flex;
  flex-direction: column;

  .group-row {
    margin-bottom: 0;
  }

  .row-banner {
    background: $cow-lighter-gray;
    border-radius: 0 0 em(4) em(4);
    padding: em(4) em(24);
    font-size: $cow-font-14;
    border-top: em(1) solid $cow-translucent-lighter-gray;
    text-align: center;
    color: $cow-dark-gray;
  }
}
