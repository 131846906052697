#group_content {
  .options-list.ui-card.combination_row {
    margin-bottom: 32px;
  }


  & > h1 {
    margin-bottom: 32px;
  }

  #title_combination {
    color: $andes-gray-550;
    font-weight: $cow-font-weight-regular;
    font-size: $cow-font-14;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #title_2_combination {
    font-size: $cow-font-18;
    color: $cow-translucent-dark-gray;
  }

  .options-list.ui-card.combination_row + #group_content.layout__col-content {
    padding-bottom: 0;
    padding-top: 0;
    #title_combination {
      margin-top: 0px;
    }
  }
}

// TODO: fix this override
.payment-method-card-list.andes-card {
  margin-top: 16px;
  margin-bottom: 16px;
  .andes-list {
    &__item {
      &-action {
        padding-left: 8px;
      }

      &-first-column {
        .option-list-check {
          .andes-radio {
            padding-left: 0;
            padding-right: 5px;
          }
        }
        .andes-thumbnail-container {
          .andes-thumbnail.andes-thumbnail--circle {
            &.icon_mercado_credito_yellow, &.icon_account_money_yellow {
              border: 0;
            }
          }
        }
      }
    }

    &__item {
      &:hover {
        background-color: $cow-translucent-lightest-gray;
      }

      &-primary {
        margin-bottom: 3px;
      }
    }
  }
}

#shield_footer {
  margin-top: 24px;
  width: 100%;
}

.payment-method-card-list {

  .icon_mercado_pago_black {
    img {
      width: 100%;
    }
  }
}
