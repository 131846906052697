.list-item .group-media-object {
  div:first-of-type {
    flex-grow: 1;
  }
}

.list-item {
  &.free .group-media-object > div > .text span {
    color: $andes-success-green;
  }
}
