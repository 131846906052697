@keyframes loading-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loader-wrapper {
  top: 0;
  left: 0;
  background: rgba($cow-white, 0.8);
  width: 100%;
  height: 100%;
  z-index: $cow-z-index-loader-wrapper;
  animation: loading-fade-in 500ms ease-out both;

  .ui-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .layout--modal & {
    border-radius: em(5);
  }
}

.cow-loader {
  background-color: rgba($cow-white, 0.8);
}
