.oops {
  text-align: center;
  width: 100%;

  .icon-wrapper {
    width: 100%;
  }

  &__message {
    margin: em(30) 0 em(16);
    color: $cow-gray-800;
    font-size: em(16);
  }
}
