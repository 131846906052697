@import "shared";

body.modal-view {
  background: transparent;

  .nav-new-cookie-disclaimer {
    width: 100%;
    min-width: 646px;
    max-width: em($layout-max-width);
    position: relative;
    top: -94px;
    bottom: 0;
    margin: em(16) auto;
    border-radius: 0 0 em(6) em(6);

    @media (width <= 768px) {
      top: -148px;
    }
  }
}

.andes-coach-marks__tooltip {
  .andes-coach-marks__tooltip__footer {
    justify-content: flex-start;
    button.andes-coach-marks__step__next-button {
      background-color: $andes-blue-600;
    }
  }
}
