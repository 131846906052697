@import "../congrats/styles.desktop";

.redirect-view .layout-main {
  max-width: 100%;
}

.andes-button {
  margin-right: em(30);
  margin-bottom: 0;
}

.group_form {
  > button:first-child {
    margin-right: em(8);
  }
}
