& {

  // TODO: MOMENTOUSLY SOLUTION FOR USE THE ANDES MoneyAmount COMPONENT WITH THE CURRENT DATA STRUCTURE FROM FLOWS
  .andes-money-amount__currency-symbol {
    display: none;
  }

  .paymentMethodInfo {
    margin-top: 32px;

    .split-amount-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: auto;
      margin-bottom: 16px;

      h2 {
        flex-shrink: 0;
      }

      button.andes-button--transparent {
        height: 16px;
        padding: 0;
        margin: 0;
        width: auto;

        span {
          height: inherit;
          float: right;
          width: auto;
        }

        &:hover {
          background: none;
        }
      }
    }

    &.second-payment {
      margin-top: 24px;
    }

    .andes-card.andes-card--flat {

      .andes-list__item:hover {
        background: unset;
        cursor: auto;
      }

      .payment-method-logo.icon_mercado_pago_black {
        & > img {
          width: 40px;
        }
      }

      .andes-card__footer {
        .description-combined-amount {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .amount-info {
            display: flex;
            flex-direction: column;
            align-items: end;

            .installments {
              display: flex;
              flex-direction: row;

              span {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }

  .guest-email {
    margin-top: 24px;

    & > div {
      margin-top: 16px;
    }
  }

  .shipping-card {
    margin-top: 32px;

    h2.andes-typography {
      margin-bottom: 16px;
    }

    .card-list {
      .card-option {
        .icon-wrapper {
          svg {
            color: $cow-black;
          }
        }
      }
    }
  }

  .footer-terms {
    margin-top: 24px;
    text-align: center;

    .cow-captcha-terms {
      margin-top: 24px;
      justify-content: center;

      a:link,
      a:visited,
      a:active,
      a:hover {
        text-decoration: none;
        color: $andes-accent-color;
      }
    }
  }

  .cow-button-back-to-site {
    margin-top: 24px;
  }
}
