@import "./shared";

.page-mask {
  .andes-snackbar {
    max-width: em(600);
  }
}

.layout--modal {
  .page-mask {
    overflow-y: scroll;
    position: relative;
    height: 100%;
    width: 100%;

    .andes-snackbar {
      position: absolute;
      bottom: em(0);
      border-radius: em(4) em(4) 0 0;
    }
  }
}

.layout-terms {
  text-align: center;
  display: block;
  margin: em(16) 0;

  span,
  a {
    display: inline-block;
    font-size: em(13);
    margin-left: em(6, 13);
    color: $cow-white;
    opacity: 0.65;
  }

  a {
    font-weight: 600;
  }
}
