.card-number-with-issuer__wrapper {
  .card-number-with-issuer__group {
    &.hidden {
      display: none;
    }
    input::placeholder {
      color: $cow-text-color-secondary;
    }
  }
}
