.more-cards {
  cursor: pointer;

  &.ui-card {
    padding: em(15) em(28) em(16) em(32);
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid $cow-gray-070;
  }

  &.visible {
    display: flex;
  }

  &.hidden {
    display: none;
  }

  &:hover {
    background-color: $cow-lighter-gray;
  }

  &__label {
    font-size: $cow-font-14;
    font-weight: $cow-font-weight-semibold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $cow-lighter-blue;
    flex-grow: 1;
  }

  .icon-wrapper {
    width: em(17.5);
    transform: rotate(90deg);
  }
}
